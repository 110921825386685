import { useTranslation } from "react-i18next"
import { IoClose } from "react-icons/io5"
import { Updater } from "use-immer"
import { AdminGiftCodeFilters } from ".."
import { SwitchButton } from "../../../../../components/Button"
import "./GiftCodeFilters.sass"

interface GiftCodeFiltersProps {
  filters: AdminGiftCodeFilters
  setFilters: Updater<AdminGiftCodeFilters>
  closeFilter: () => void
}

export const GiftCodeFilters = ({ filters, setFilters, closeFilter }: GiftCodeFiltersProps) => {
  const { t } = useTranslation()

  return (
    <div className="gift-code-filter" onClick={e => { closeFilter() }}>
      <div className="content" onClick={e => { e.stopPropagation() }}>
        <div className="header">
          <h2>{t("admin.dashboard.gift_code.filters.title")}</h2>
          <button type="button" className="h-8 w-8 p-1" onClick={e => { e.stopPropagation(); closeFilter() }}><IoClose className="h-full w-full" /></button>
        </div>

        <div className="option">
          <SwitchButton label={t("admin.dashboard.gift_code.filters.unusedOnly")} state={filters.unused_only} onClick={() => { setFilters(filters => { filters.unused_only = !filters.unused_only }) }} />
        </div>

      </div>
    </div>
  )
}