import { Dispatch, FormEvent, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { TextInput } from '../../components/Input'
import { LoadingDots } from '../../components/Loading'
import { useWarnings } from '../../contexts/WarningContext'
import api from '../../services/api'
import './ResetPasswordPage.sass'


interface NewPasswordFrameProps {
  handleSubmit: (e: FormEvent) => void
  newPassword: string
  setNewPassword: Dispatch<SetStateAction<string>>
  confirmPassword: string
  setConfirmPassword: Dispatch<SetStateAction<string>>
}


const NewPasswordFrame = ({ handleSubmit, newPassword, setNewPassword, confirmPassword, setConfirmPassword }: NewPasswordFrameProps) => {
  const { t } = useTranslation()

  return (
    <form onSubmit={handleSubmit}>
      <TextInput
        label={t('profile.cards.settings.passwordChange.newPassword')}
        value={newPassword}
        setValue={setNewPassword}
        placeholder={t('profile.cards.settings.passwordChange.newPassword')}
        type="password"
      />
      <TextInput
        label={t('profile.cards.settings.passwordChange.confirmPassword')}
        value={confirmPassword}
        setValue={setConfirmPassword}
        placeholder={t('profile.cards.settings.passwordChange.confirmPassword')}
        type="password"
      />

      <button> {t('login.resetPassword')} </button>
    </form>
  )
}


export const ResetPasswordPage = () => {
  const navigate = useNavigate()
  const { setWarning } = useWarnings()

  const [newPassword, setNewPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')

  const [isLoading, setIsLoading] = useState(false)
  const [hasChanged, setHasChanged] = useState(false)
  const [hasError, setHasError] = useState<boolean>(false)

  const { t } = useTranslation()

  const token = useParams().token

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    setIsLoading(true)

    if (newPassword !== confirmPassword) {
      setWarning({ warning: t('errors.PASSWORD_DOESNT_MATCH'), type: 'warning', duration: 5 })
      setIsLoading(false)
    } else {
      api.post('/api/user/reset_password', { token: token, new_password: newPassword })
        .then(
          response => { setHasChanged(true) },
          error => {
            console.info(error);
            if (error.status !== 500) {
              if (error.response.data.detail === "INVALID_TOKEN") {
                setWarning({ warning: t([`errors.${error.response.data.detail}`, 'errors.undefined']), type: 'error' })
              }
              setWarning({ warning: t([`errors.${error.response.data.detail}`, 'errors.undefined']), type: 'warning', duration: 5 })
            } else {
              setHasError(true)
              setWarning({ warning: t('errors.INTERNAL_SERVER_ERROR'), type: 'error', duration: 5 })

            }
          })
        .finally(() => { setIsLoading(false) })
    }
  }

  return (
    <div className="reset-password">
      <div className="background">
        <div style={{ backgroundImage: 'url(/background-details.svg)' }} />
      </div>
      <div className="content">
        <img src="/logo.png" alt="" />

        {
          hasError && (
            <div className="result">
              <>
                <p>{t('errors.INVALID_TOKEN')}.</p>
                <button type="button" onClick={() => { setHasError(false); navigate('/login?recover=true') }}>{t('login.recoverPassword.resendMail')}</button>
              </>
            </div>
          )
        }

        {
          hasChanged ?
            (<div className="result">
              <p>{t('profile.cards.settings.passwordChange.success')}</p>
              <NavLink to="/login">{t('login.backToLogin')}</NavLink>
            </div>)
            :
            !hasError &&
            <NewPasswordFrame
              handleSubmit={handleSubmit}
              newPassword={newPassword}
              setNewPassword={setNewPassword}
              confirmPassword={confirmPassword}
              setConfirmPassword={setConfirmPassword}
            />
        }

        {isLoading && (
          <div className="flex items-center justify-center absolute left-0 top-0 w-full h-full z-20 bg-gray-600 bg-opacity-70">
            <LoadingDots />
          </div>
        )}
      </div>
    </div >
  )
}