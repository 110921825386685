import { useAuth } from "../../../contexts/UserContext"
import { useTranslation } from "react-i18next"
import { Navigate } from "react-router-dom"
import "../Professional.sass"
import { useEffect, useState } from "react"
import { useImmer } from "use-immer"
import { APIFile } from "../Resend"
import api from "services/api"
import { IoClose, IoInformation } from "react-icons/io5"
import { File } from "components/Input"
import { useWindowDimensions } from "utils/WindowDimensions"

interface AnalysisInterface {
  documents: APIFile[]
}


function MobileAnalysis({ documents }: AnalysisInterface) {
  const { t } = useTranslation()
  const [isVisible, setIsVisible] = useState(true)

  return (
    <div className="verify-container mobile">
      <button onClick={e => { e.preventDefault(); e.stopPropagation(); setIsVisible(true) }} className="information" type="button">
        <IoInformation />
      </button>

      <div className="warning">
        <h1>
          {t('professional.analysis.title')}

        </h1>

        <p>
          {t('professional.analysis.subtitle')}
        </p>
      </div>


      {
        isVisible && (
          <div onClick={e => { e.preventDefault(); e.stopPropagation(); setIsVisible(false) }} className="z-10 w-screen h-screen flex justify-end absolute top-0 left-0 animate-smooth-in bg-gray-700 bg-opacity-80 cursor-pointer">
            <button onClick={e => { e.preventDefault(); e.stopPropagation(); setIsVisible(false) }} className="z-10 absolute flex items-center justify-center top-4 right-4 w-6 h-6 text-gray-700" type="button">
              <IoClose className="text-gray-700" />
            </button>
            <div onClick={e => { e.preventDefault(); e.stopPropagation() }} className="flex flex-col items-center h-full w-full max-w-lg animate-right-slide-in bg-white p-4 gap-2 cursor-default">
              <div className="title">
                <h1 className="text-lg font-bold text-gray-700">{t("professional.verify.documents")}</h1>
              </div>
              {
                documents.map(document => (
                  <File file={document} removeAPIFile={() => { }} blockRemoval />
                ))
              }


            </div>
          </div>
        )
      }
    </div>
  )
}

function WebAnalysis({ documents }: AnalysisInterface) {
  const { t } = useTranslation()
  return (
    <div className="verify-container">
      <div className="left-container">
        <div className="warning">
          <h1>
            {t('professional.analysis.title')}
          </h1>

          <p>
            {t('professional.analysis.subtitle')}
          </p>
        </div>
      </div>
      <div className="right-container">
        <h1 className="text-lg font-bold text-gray-700">{t("professional.verify.documents")}</h1>
        {
          documents.map(document => (
            <File file={document} removeAPIFile={() => { }} blockRemoval />
          ))
        }
      </div>
    </div>
  )
}


export const Analysis = () => {
  const { width } = useWindowDimensions()
  const { user } = useAuth()
  const [documents, setDocuments] = useImmer<APIFile[]>([])

  useEffect(() => {
    api.get<APIFile[]>("/api/user/professional/documents").then(
      response => {
        setDocuments([...response.data])
      },
      error => {

      }
    )
  }, [user, setDocuments])

  if (user.type === "parent" || (user.type === "professional" && user.professionalStatus !== "ANALYSIS")) {
    return (
      <Navigate to="/dashboard" />
    )
  }

  return width >= 1024 ? (
    <WebAnalysis documents={documents} />
  ) : (
    <MobileAnalysis documents={documents} />
  )
}   