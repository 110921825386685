import { PrimaryButton } from "../../../components/Button";
import { TextInput } from "../../../components/Input";
import { LoadingDots } from "../../../components/Loading";
import { useAuth } from "../../../contexts/UserContext";
import { useWarnings } from "../../../contexts/WarningContext";
import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import "./PasswordRecover.sass";


export const PasswordRecover = () => {
  const [email, setEmail] = useState("")
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false)
  const { RequestPasswordRecover } = useAuth()
  const { setWarning } = useWarnings()

  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleEmailSubmit = (event: FormEvent) => {
    event.preventDefault()
    setIsLoading(true)
    RequestPasswordRecover({ email: email }).then(error => {
      if (error !== null) {

        setWarning({
          type: "error",
          warning: t([`errors.${error[0]}`, 'errors.undefined']),
          duration: 15
        })
      }
      setIsLoading(false)
    }).finally(() => setIsEmailSent(true))
  }

  return (
    <div className="recover parent">
      <div className="flex h-20 w-20">
        <img src="/logo.png" alt="Logo" />
      </div>
      {
        isEmailSent ? (
          <div className="flex flex-col gap-4 h-3/5 w-4/5 items-center justify-center bg-white">
            <p className='text-center py-4'>{t('login.recoverPassword.emailIsSent.fLine')} <br />{t('login.recoverPassword.emailIsSent.sLine')} </p>
            <button onClick={() => navigate("/login")} type="button" className="w-full h-10 rounded-xl transition bg-teal-300 text-white ring-2 ring-teal-300">
              {t('login.backToLogin')}
            </button>

            <button type="button" onClick={() => { setIsEmailSent(false) }} className="w-full h-9 rounded-xl transition ring-2 ring-teal-300">
              {t('login.recoverPassword.resendMail')}
            </button>

            <p>{t('login.noAccount')} <NavLink to="/register"><b className="text-teal-300 hover:text-teal-400 transition">{t('login.register')}</b></NavLink></p>

          </div>
        ) : (
          <div className="flex flex-col gap-4 h-3/5 w-4/5 items-center justify-center bg-white">
            <form onSubmit={handleEmailSubmit}>
              <TextInput label={t('login.email')} value={email} setValue={setEmail} placeholder={t('login.placeholders.email')} type="email" />

              <div className="form-functions">
                <PrimaryButton label={t('login.recoverPassword.sendMail')} type='submit' />
                <button type="button" onClick={(e) => { e.preventDefault(); navigate("/login") }}>{t('login.backToLogin')}</button>
                <p>{t('login.noAccount')} <NavLink to="/register"><b className="text-teal-300 hover:text-teal-400 transition">{t('login.register')}</b></NavLink></p>

              </div>
            </form>
          </div>
        )
      }

      {
        isLoading && <div className="loading">
          <LoadingDots />
        </div>
      }
    </div>
  )


}