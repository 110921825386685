import { useTranslation } from "react-i18next"
import { useAuth } from "../../contexts/UserContext"
import { ParentInvite, ProfessionalInvite } from "../InviteCard"

export const ParentInvites = () => {
  const { invites } = useAuth()
  const { t } = useTranslation()

  return (
    <div className="flex flex-col w-96 h-full p-4 ">
      <p className="font-semibold text-lg">{t('menus.titles.parentInvites')}</p>
      <div className="flex flex-col w-full h-full overflow-y-auto">
        {
          invites.length > 0 ? (
            invites.map(invite => (
              <ParentInvite invite={invite} key={invite.id} />
            ))
          ) : (
            <div className="flex w-full h-full items-center justify-center">
              <p className="font-semibold"> {t('inviteModal.noInvites')} </p>
            </div>
          )
        }
      </div>
    </div>
  )
}

export const ProfessionalInvites = () => {
  const { invites } = useAuth()
  const { t } = useTranslation()

  return (
    <div className="flex flex-col w-96 h-full p-4 ">
      <p className="font-semibold text-lg">{t('menus.titles.invites')}</p>
      <div className="flex flex-col w-full h-full overflow-y-auto">
        {
          invites.length > 0 ? (
            invites.map(invite => (
              <ProfessionalInvite invite={invite} key={invite.id} />
            ))
          ) : (
            <div className="flex w-full h-full items-center justify-center">
              <p className="font-semibold"> {t('inviteModal.proNoInvites')}</p>
            </div>
          )
        }
      </div>
    </div>
  )
}