import { LoadingRing } from "../../../components/Loading"
import { GameUserProps, UserPropsServerResponse, UserQueryProps } from "../../../contexts/UserContext"
import { useWarnings } from "../../../contexts/WarningContext"
import { GameUserCardSmall, AdminUserCardSmall } from "../../../frames/UserCard"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { IoSearch } from "react-icons/io5"
import api from "../../../services/api"
import "./Dashboard.sass"

export const AdminDashboard = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [query, setQuery] = useState<string>("")
  const [userQueryResult, setUserQueryResult] = useState<UserQueryProps[]>([] as UserQueryProps[])
  const [gameUserQueryResult, setGameUserQueryResult] = useState<GameUserProps[]>([] as GameUserProps[])

  const { setWarning } = useWarnings()
  const { t } = useTranslation()

  useEffect(() => {
    if (query?.length < 3) return;
    setIsLoading(true)
    api.get(`/api/admin/search/user?query=${query}`).then(
      response => {
        const users: UserQueryProps[] = []

        response.data.forEach((userData: UserPropsServerResponse) => {
          if (userData["type"] === "parent") {
            const user = {
              firstName: userData["first_name"],
              lastName: userData["last_name"],
              email: userData["email"],
              id: userData["id"],
              type: userData["type"],
              isEmailVerified: userData["is_verified"],
              isPremium: userData["is_premium"],
              gameUserLicenses: userData["game_user_licenses"]
            } as UserQueryProps
            users.push(user)
          }

          if (userData["type"] === "professional") {
            const user = {
              firstName: userData["first_name"],
              lastName: userData["last_name"],
              email: userData["email"],
              id: userData["id"],
              type: userData["type"],
              isEmailVerified: userData["is_verified"],
              professionalStatus: userData["professional_status"]
            } as UserQueryProps
            users.push(user)
          }
        })
        setUserQueryResult(users)
      },
      error => {
        if (error.response.status === 429) return;
        setWarning({
          warning: t([`errors.${error.response.data.detail}`, "errors.undefined"]),
          type: "error"
        })
      },
    )
      .finally(() => setIsLoading(false))

    api.get(`/api/admin/search/game_user?query=${query}`).then(
      response => {
        setGameUserQueryResult(response.data)
      },
      error => {
        setWarning({
          warning: t([`errors.${error.response.data.detail}`, "errors.undefined"]),
          type: "error"
        })
      })
      .finally(() => setIsLoading(false))
  }, [query, setWarning, t])

  const UserFrame = () => {
    if (query.length < 3) {
      return (
        <p>{t('query.errors.minLength')}</p>
      )
    }

    if (userQueryResult.length > 0) {
      return (
        <>
          {userQueryResult.map(user => (<AdminUserCardSmall link={`/admin/dashboard/user/${user.id}`} user={user} key={user.email} />))}
        </>
      )
    }

    return (
      <p>{t('query.errors.noUser')}</p>
    )
  }

  const GameUserFrame = () => {
    if (query.length < 3) {
      return (
        <p>{t('query.errors.minLength')}</p>
      )
    }

    if (gameUserQueryResult.length > 0) {
      return (
        <>
          {gameUserQueryResult.map(user => (<GameUserCardSmall gameUser={user} key={user.name + user.parent.email} />))}
        </>
      )
    }

    return (
      <p>{t('query.errors.noUser')}</p>
    )
  }

  return (
    <div className="admin-dashboard">
      <form aria-label="form" className="col-span-2 bg-white flex items-center w-full h-12 px-4 rounded-lg shadow-lg" onSubmit={e => e.preventDefault()}>
        <input className="flex items-center border-none bg-white w-full h-full" type="text" placeholder={t('query.placeholder')} value={query} onChange={e => setQuery(e.target.value)} />
        <button type="submit" className="h-6 w-6 text-gray-700">
          {
            isLoading ? (
              <LoadingRing />
            ) : (
              <IoSearch className=" h-full w-full" />
            )
          }
        </button>
      </form>
      <div className="users">
        <h1>{t('admin.dashboard.users')}</h1>


        <div className="content">
          <UserFrame />
        </div>

      </div>

      <div className="users">
        <h1>{t('admin.dashboard.players')}</h1>


        <div className="content">
          <GameUserFrame />
        </div>

      </div>
    </div>
  )
}