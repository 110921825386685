import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useModal } from "../../contexts/ModalContext"
import { useAuth } from "../../contexts/UserContext"
import { useWarnings } from "../../contexts/WarningContext"
import api from "../../services/api"
import { PrimaryButton, SecondaryButton } from "../Button"
import { ActivateGameUsers } from "./ActivateGameUsers"
import "./GiftCodeInfo.sass"

export interface GiftCodeProps {
  id: string
  licenses: number
  validity: number
  code: string
}


function LicensesWarning({ giftCode }: { giftCode: GiftCodeProps }) {
  const { t } = useTranslation()
  const { UserCheckAuth, gameUsers } = useAuth()
  const { setModal, closeModal } = useModal()
  const { setWarning } = useWarnings()
  const [isLoading, setIsLoading] = useState(false)


  const activateGiftCode = () => {
    setIsLoading(true)
    api.put<null>(`/api/user/gift_code/${giftCode.id}`).then(
      response => {
        setWarning({ warning: t("profile.cards.settings.giftCode.codeRedemptionSuccess"), type: "success" })
      },
      error => {
        if (error.status === 404) {
          setWarning({ warning: t("profile.cards.settings.giftCode.codeRedemptionNotFound"), type: "error" })
        }
        setWarning({
          warning: t([`errors.${error.response.data.detail}`, "errors.undefined"]),
          type: "error"
        })
      }
    ).finally(() => {
      UserCheckAuth().finally(() => {
        setIsLoading(false)
        setModal({ element: <ActivateGameUsers maxGameUsers={giftCode.licenses} /> })
      })
    })
  }


  return (
    <div className="gift-code-info-container">
      <h3>{t("profile.cards.settings.giftCode.areYouSure")}</h3>
      <p className="flex px-6 w-full">{t("profile.cards.settings.giftCode.gameUserExplanation")}</p>
      <div className="gift-info">
        <p><b>{t("profile.cards.settings.giftCode.validity")}:</b> {giftCode.validity} {t("profile.cards.settings.giftCode.days")}</p>
        <p><b>{t("profile.cards.settings.giftCode.licenses")}:</b> {giftCode.licenses} {t("profile.cards.settings.giftCode.players")} <span>{t("profile.cards.settings.giftCode.playersAmount", { player: gameUsers.length })}</span></p>
      </div>
      <PrimaryButton onClick={activateGiftCode} label={t("profile.cards.settings.giftCode.activate")} isLoading={isLoading} />
      <SecondaryButton label={t("profile.cards.settings.giftCode.cancel")} onClick={closeModal} />
    </div>
  )
}


export const GiftCodeInfo = ({ ...giftCode }: GiftCodeProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { setWarning } = useWarnings()
  const { t } = useTranslation()
  const { closeModal, setModal } = useModal()
  const { UserCheckAuth, gameUsers } = useAuth()

  const areLicensesEnough = useMemo(() => giftCode.licenses >= gameUsers.length, [giftCode, gameUsers])

  const handleButton = () => {
    if (areLicensesEnough) {
      activateGiftCode()
    } else {
      setModal({ element: <LicensesWarning giftCode={giftCode} /> })
    }
  }

  const activateGiftCode = () => {
    setIsLoading(true)
    api.put<null>(`/api/user/gift_code/${giftCode.id}`).then(
      response => {
        setWarning({ warning: "Code activated", type: "success" })
      },
      error => {
        if (error.status === 404) {
          setWarning({ warning: "Code not found or unavailable", type: "error" })
        }
        setWarning({
          warning: t([`errors.${error.response.data.detail}`, "errors.undefined"]),
          type: "error"
        })
      }
    ).finally(() => {
      UserCheckAuth().finally(() => {
        setIsLoading(false)
        closeModal()
      })
    })
  }



  return (
    <div className="gift-code-info-container">
      <h3>{t("menus.titles.gift_code")}</h3>
      <div className="gift-info">
        <p><b>{t("profile.cards.settings.giftCode.validity")}:</b> {giftCode.validity} {t("profile.cards.settings.giftCode.days")}</p>
        <p><b>{t("profile.cards.settings.giftCode.licenses")}:</b> {giftCode.licenses} {t("profile.cards.settings.giftCode.players")} <span>{!areLicensesEnough ? t("profile.cards.settings.giftCode.gameUserWarning") : ""}</span></p>
      </div>
      <PrimaryButton onClick={handleButton} label={t("profile.cards.settings.giftCode.activate")} isLoading={isLoading} />
      <SecondaryButton label={t("profile.cards.settings.giftCode.cancel")} onClick={closeModal} />
    </div>
  )
}