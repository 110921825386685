import { DetailedHTMLProps, HTMLAttributes, ReactNode } from "react";
import { IoArrowBack, IoClose } from "react-icons/io5";

interface ModalProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode
  show: boolean
  onClose?: () => void;
  close?: () => void;
  goBack?: () => void;
  className?: "parent" | "professional"
}

export const Modal = ({ children, show, onClose, className = "parent", goBack, close, ...props }: ModalProps) => {
  return (
    <div onClick={onClose} className={`flex items-center justify-center fixed top-0 left-0 w-screen h-screen bg-gray-700 bg-opacity-60 ${className} ${show ? 'opacity-100 pointer-events-auto cursor-pointer' : 'pointer-events-none opacity-0'} z-50`} {...props}>
      <div onClick={e => e.stopPropagation()} className={`opacity-100 cursor-default min-w-96 h-auto py-4 bg-gray-lightest rounded-lg relative`}>
        {
          goBack !== undefined &&
          <button onClick={goBack} type="button" className="absolute left-2 top-2 h-6 w-6">
            <IoArrowBack className="w-full h-full text-gray-500" />
          </button>
        }
        {
          close !== undefined && (
            <button onClick={close} type="button" className="absolute right-2 top-2 h-6 w-6">
              <IoClose className="w-full h-full text-gray-500" />
            </button>
          )
        }
        {children}
      </div>
    </div>
  )
}