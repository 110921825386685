import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import { useAuth } from "../../contexts/UserContext";

export const UserDropdown = ({ ...props }) => {
  const [isOpen, setIsOpen] = useState(false)

  const { t } = useTranslation()
  const { gameUsers, selectedGameUser, setSelectedGameUser } = useAuth()

  return (
    <div className="flex py-7" {...props}>
      <p className="mr-2 flex items-center font-semibold">{t('userDropdown.user')}: </p>
      <div className="flex flex-col bg-white h-8 w-40 rounded shadow text-sm divide-y relative " >
        <button className="flex items-center justify-between py-0 px-2 h-8" onClick={() => setIsOpen(!isOpen)}>
          <p className="text-ellipsis overflow-hidden whitespace-nowrap">
            {selectedGameUser ? selectedGameUser.name : t('userDropdown.all')}
          </p>
          {!isOpen ? IoChevronDown({ className: "text-gray-700 w-6 h-6 flex shrink-0" }) : IoChevronUp({ className: "text-gray-700 w-6 h-6 flex shrink-0" })}
        </button>
        <div className="flex flex-col absolute top-8 w-40 max-h-40 overflow-y-auto z-10">
          <button className={`flex items-center bg-white justify-between py-0 px-2 h-8 hover:bg-gray-100 transition ${isOpen ? '' : 'hidden'}  shrink-0`} onClick={() => { setSelectedGameUser(undefined); setIsOpen(false) }}>
            <p>{t('userDropdown.all')}</p>
          </button>
          {
            gameUsers.map(user => {
              return (
                <button key={user.id} className={`flex items-center bg-white justify-between py-0 px-2 h-8 hover:bg-gray-100 transition ${isOpen ? '' : 'hidden'} shrink-0 `} onClick={() => { setSelectedGameUser({ ...user }); setIsOpen(false) }}>
                  <p className="text-ellipsis overflow-hidden whitespace-nowrap">{user.name}</p>
                </button>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}