import { File } from 'components/Input'
import { RemoveFile } from 'components/RemoveFile'
import { useModal } from 'contexts/ModalContext'
import { APIValidation, ValidationCard } from 'pages/Admin/Validation/ValidationFunctions'
import { APIFile } from 'pages/Professional/Resend'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoArrowBack } from 'react-icons/io5'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { LoadingDots, WhiteLoadingRing } from '../../../components/Loading'
import { GameUserProps, UserQueryProps, useAuth } from '../../../contexts/UserContext'
import { useWarnings } from '../../../contexts/WarningContext'
import { ParentInvite, ProfessionalInvite } from '../../../frames/InviteCard'
import { AdminUserProfileHeader, ProfileHeader, UserProfileHeader } from '../../../frames/ProfileHeader'
import { GameUserCardSmall } from '../../../frames/UserCard'
import api from '../../../services/api'
import { useWindowDimensions } from '../../../utils/WindowDimensions'
import './UserProfile.sass'


export interface ParentUserData {
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  isPremium: boolean,
  type: 'parent',
  gameUsers: GameUserProps[]
}
export interface ProfessionalUserData {
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  type: 'professional',
  professionalStatus: 'UNVERIFIED' | 'ANALYSIS' | 'RESEND' | 'DENIED' | 'ACCEPTED',
  gameUsers: GameUserProps[]
}

export type RequestedUserData = ParentUserData | ProfessionalUserData

const ReturnButton = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { width } = useWindowDimensions()

  let path = ''
  if (width < 768) {
    const match = location.pathname.match('/dashboard/profile/settings/')
    if (match) {
      path = '/dashboard/profile/settings'
    } else {
      path = '/dashboard/profile/'
    }
  } else {
    path = '/dashboard/profile/'
  }

  return (
    <button type="button" onClick={e => { e.stopPropagation(); navigate(path) }}>
      <IoArrowBack />
    </button>
  )
}

export const UserProfile = () => {
  const { t } = useTranslation()
  const { user, invites, gameUsers } = useAuth()
  const location = useLocation()

  const [hasOutlet, setHasOutlet] = useState(false)

  useEffect(() => {
    const match = location.pathname.match('/dashboard/profile/settings')
    if (match) {
      setHasOutlet(true)
    } else {
      setHasOutlet(false)
    }
  }, [location])

  return (
    <div id="profile" className={`${hasOutlet && 'max-h-full overflow-hidden'}`}>

      {
        hasOutlet && (
          <div className="outlet">
            <div className="header">
              <ReturnButton />
            </div>
            <Outlet />
          </div>
        )
      }
      {
        !hasOutlet && (
          <>
            <ProfileHeader />
            <div className="profile-cards">
              <div className="card invite-card">
                <div className="header">
                  <h2>{t('inviteModal.invites')}</h2>

                </div>
                <div className="card-session">
                  {
                    invites.map(invite => (
                      user.type === "parent" ?
                        (<ParentInvite invite={invite} key={invite.id} />)
                        :
                        (<ProfessionalInvite invite={invite} key={invite.id} />)
                    ))
                  }

                  {
                    invites.length === 0 && (
                      <div className="flex items-center justify-center">
                        {user.type === "parent" ? <p>{t('inviteModal.noInvites')}</p> : <p>{t('inviteModal.proNoInvites')}</p>}
                      </div>
                    )
                  }
                </div>
              </div>

              <div className="card">
                <div className="head">
                  <h2>{t('profile.cards.gameUser.users')}</h2>
                </div>
                <div className="card-session">
                  {
                    gameUsers.map(gameUser => (<GameUserCardSmall gameUser={gameUser} key={gameUser.name} />))
                  }
                  {
                    gameUsers.length === 0 && (
                      <div className="flex items-center justify-center">
                        <p>{t('profile.cards.gameUser.noUsers')}</p>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>

          </>
        )}
    </div>
  )
}


export const Profile = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { setWarning } = useWarnings()
  const { t } = useTranslation()

  const [user, setUser] = useState<RequestedUserData>({} as RequestedUserData)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    api.get(`/api/user/${params.id}`).then(
      response => {
        setUser({
          id: response.data['id'],
          firstName: response.data['first_name'],
          lastName: response.data['last_name'],
          email: response.data['email'],
          type: response.data['type'],
          gameUsers: response.data['game_users']
        } as RequestedUserData)
      },
      error => {
        navigate('/dashboard')
        setWarning({ warning: t('errors.USER_NOT_FOUND'), type: "warning" })
      }
    ).finally(() => {
      setIsLoading(false)
    })
  }, [params, navigate, setWarning, t])


  if (isLoading) {
    return <WhiteLoadingRing />
  }

  return (
    <div id="profile">
      <UserProfileHeader user={user} />
      <div className="profile-cards">
        <div className="card">
          <div className="head">
            <h2>{t('profile.cards.gameUser.users')}</h2>
          </div>
          <div className="card-session">
            {
              user.gameUsers.map(gameUser => (<GameUserCardSmall gameUser={gameUser} key={gameUser.name} />))
            }
            {
              user.gameUsers.length === 0 && (
                <div className="flex items-center justify-center">
                  <p>{t('profile.cards.gameUser.noUsers')}</p>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export const AdminProfile = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { setWarning } = useWarnings()
  const { setModal } = useModal()
  const { t } = useTranslation()

  const [user, setUser] = useState<UserQueryProps>({} as UserQueryProps)
  const [gameUsers, setGameUsers] = useState<GameUserProps[]>([] as GameUserProps[])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [documents, setDocuments] = useState<APIFile[]>([])
  const [validations, setValidations] = useState<APIValidation[]>([])


  const errorOnGetUser = useCallback(() => {
    navigate('/dashboard')
    setWarning({ warning: t('errors.USER_NOT_FOUND'), type: "warning" })
  }, [setWarning, navigate, t])


  const getDocuments = useCallback(async () => {
    const { data } = await api.get<APIFile[]>(`/api/admin/user/${params.id}/documents`)
    setDocuments([...data])
  }, [params])

  const getUser = useCallback(function (forceUpdate?: boolean) {
    if (user.id === undefined || forceUpdate) {
      setIsLoading(true)
      api.get(`/api/admin/user/${params.id}`).then(
        response => {
          setUser({
            id: response.data['id'],
            firstName: response.data['first_name'],
            lastName: response.data['last_name'],
            email: response.data['email'],
            type: response.data['type'],
            isEmailVerified: response.data['is_verified'],
            isPremium: response.data['is_premium'],
            professionalStatus: response.data['professional_status'],
            isPublic: response.data['is_public']
          } as UserQueryProps)
          if (response.data["type"] === "professional") {
            getDocuments()
            api.get<APIValidation[]>(`/api/admin/user/${params.id}/validation`).then(
              response => {
                setValidations([...response.data])
              },
              error => {

              }
            )
          }
        },
        error => {
          errorOnGetUser()
        }
      ).finally(() => {
        setIsLoading(false)
      })
    }
  }, [errorOnGetUser, params.id, user, getDocuments])

  useEffect(() => {
    getUser()
  }, [getUser])

  useEffect(() => {
    if (!user.id) return;
    api.get(`/api/admin/game_users/${user.id}`).then(
      response => {
        setGameUsers(response.data)
      },
      error => {
        setWarning({
          warning: t([`errors.${error.response.data.detail}`, "errors.undefined"]),
          type: "error"
        })
      })
      .finally(() => setIsLoading(false))
  }, [user.id, setWarning, t])


  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-full h-full bg-gray-700 opacity-60" title={t('loading')}>
        <div className="w-10 h-10">
          <LoadingDots />
        </div>

      </div>
    )
  }

  return (
    <div id="profile">
      <AdminUserProfileHeader user={{ ...user, gameUsers: [] }} callback={() => getUser(true)} />
      {
        user.type === "professional" && (
          <div className="profile-cards">
            <div className="card">
              <div className="head">
                <h2>{t("professional.verify.documents")}</h2>
              </div>
              <div className="card-session">
                {
                  documents.length <= 0 && (
                    <p className="text-gray-400 flex items-center justify-center w-full">{t("professional.validation.noDocuments")}</p>
                  )
                }
                {
                  documents.map(document => (
                    <File
                      file={document}
                      key={document.id}
                      removeAPIFile={() => {
                        setModal({ element: <RemoveFile file={{ ...document, link: `/api/admin/document/${document.id}` }} />, onClose: async () => { await getDocuments(); } })
                      }}
                    />
                  ))
                }
              </div>
            </div>
            <div className="card">
              <div className="head">
                <h2>{t("professional.validation.history")}</h2>
              </div>
              <div className="card-session">
                {
                  validations.length <= 0 && (
                    <p className="text-gray-400 flex items-center justify-center w-full">{t("professional.validation.noValidation")}</p>
                  )
                }
                {
                  validations.map((validation, index) => (
                    <ValidationCard
                      key={`${validation.status}-${index}`}
                      validation={validation}
                    />
                  ))
                }
              </div>
            </div>

          </div>
        )
      }
      <div className="profile-cards">
        <div className="card">
          <div className="head">
            <h2>{t('profile.cards.gameUser.users')}</h2>
          </div>
          <div className="card-session">
            {
              gameUsers.map(gameUser => (<GameUserCardSmall gameUser={gameUser} key={gameUser.name} />))
            }
            {
              gameUsers.length === 0 && (
                <div className="flex items-center justify-center">
                  <p>{t('profile.cards.gameUser.noUsers')}</p>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}