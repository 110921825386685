import { Dispatch, FormEvent, ReactElement, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, NavLink, useNavigate, useSearchParams } from 'react-router-dom'
import { PrimaryButton } from '../../components/Button'
import { PasswordInput, TextInput } from '../../components/Input'
import { LoadingDots } from '../../components/Loading'
import { useAuth } from '../../contexts/UserContext'
import { useWarnings } from '../../contexts/WarningContext'
import './LoginFrame.sass'


interface PasswordLoginProps {
  handleSubmit: (event: FormEvent) => void;
  login: string
  setLogin: Dispatch<SetStateAction<string>>
  password: string
  setPassword: Dispatch<SetStateAction<string>>

  loginErrors?: string[]
  passwordErrors?: string[]
  serverError?: string[]

  setCanLogin: Dispatch<SetStateAction<boolean>>

  children?: ReactElement
}


const FormFunctions = ({ canLogin, setCanLogin }: { canLogin: boolean; setCanLogin: Dispatch<SetStateAction<boolean>> }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return canLogin ? (
    <div className="form-functions">
      <PrimaryButton label={t('login.login')} type='submit' />
      <button type="button" onClick={(e) => { e.preventDefault(); navigate("/register") }}>{t('login.register')}</button>
      <p><NavLink to="" onClick={(e) => { e.preventDefault(); navigate("/login/recover") }}><b className="text-teal-300 hover:text-teal-400 transition">{t('login.forgotPassword')}</b></NavLink></p>
    </div>
  ) : (<></>)
}


const PasswordLogin = ({ handleSubmit, login, setLogin, password, setPassword, loginErrors, passwordErrors, serverError, setCanLogin, children }: PasswordLoginProps) => {
  const { t } = useTranslation()
  const [isAllowed, setIsAllowed] = useState(true)

  useEffect(() => {
    if (serverError)
      serverError.indexOf('INVALID_LOGIN_METHOD') >= 0 ?
        setIsAllowed(false) : setIsAllowed(true)
  }, [serverError])

  return isAllowed ? (
    <form onSubmit={handleSubmit}>
      <TextInput label={t('login.email')} value={login} setValue={setLogin} placeholder={t('login.placeholders.email')} errors={loginErrors} type="email" autoFocus />
      <PasswordInput label={t('login.password')} value={password} setValue={setPassword} placeholder={t('login.placeholders.password')} errors={passwordErrors} />

      <FormFunctions canLogin={isAllowed} setCanLogin={setIsAllowed} />


    </form>
  ) : (
    <div className="flex flex-col items-center justify-center w-4/5 h-full gap-4">
      <p className='text-center py-4'>{t('login.blockedAccount')}</p>
      <button onClick={() => setCanLogin(false)} type="button" className="w-full h-10 rounded-xl transition bg-teal-300 text-white ring-2 ring-teal-300">
        {t('login.resetPassword')}
      </button>

      <button onClick={e => { e.preventDefault(); setIsAllowed(true) }} type="button" className="w-full h-9 rounded-xl transition ring-2 ring-teal-300">
        {t('login.switchAccount')}
      </button>

      <p>{t('login.noAccount')} <NavLink to="/register"><b className="text-teal-300 hover:text-teal-400 transition">{t('login.register')}</b></NavLink></p>

    </div>
  )
}


export const LoginFrame = ({ ...props }) => {
  const [searchParams] = useSearchParams()
  const recover = searchParams.get('recover')

  const { RequestLogin } = useAuth()
  const { setWarning, closeWarning } = useWarnings()
  const { t } = useTranslation()

  const [canLogin, setCanLogin] = useState<boolean>(recover === 'true' ? false : true)

  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')

  const [loginErrors, setLoginErrors] = useState<string[]>([])
  const [passwordErrors, setPasswordErrors] = useState<string[]>([])
  const [serverPasswordError, setServerPasswordError] = useState<string[]>([])

  const [isLoading, setIsLoading] = useState(false)

  const handlePasswordSubmit = (event: FormEvent) => {
    event.preventDefault()
    setIsLoading(true)
    RequestLogin({ login, password }).then(error => {
      if (error !== null) {
        setServerPasswordError(error)
        setWarning({
          type: 'error',
          warning: t([`errors.${error[0]}`, 'errors.undefined']),
          duration: 15
        })
      } else {
        closeWarning()
      }

      setIsLoading(false)
    })
  }



  useEffect(() => {
    const mail_format = /^[^\s@]+@[^\s@]+(\.[^\s@]+)+$/;
    const errors: string[] = []
    if (!login.match(mail_format)) {
      errors.push('Invalid Email')
    }

    if (serverPasswordError.length > 0) {
      errors.push(...serverPasswordError)
    }
    setLoginErrors(errors)
  }, [login, serverPasswordError])

  useEffect(() => {
    if (password.length < 6) {
      setPasswordErrors(['Short Password'])
      return
    };

    serverPasswordError.length > 0 && setLoginErrors([...serverPasswordError])


    setPasswordErrors([])
  }, [password, serverPasswordError])


  return (

    <div className="login parent">
      <div className="flex h-20 w-20">
        <img src="/logo.png" alt="Logo" />
      </div>
      {
        canLogin && (
          <PasswordLogin
            handleSubmit={handlePasswordSubmit}
            login={login}
            setLogin={setLogin}
            password={password}
            setPassword={setPassword}
            loginErrors={loginErrors}
            passwordErrors={passwordErrors}
            serverError={serverPasswordError}
            setCanLogin={setCanLogin}
          >
            <FormFunctions
              canLogin={canLogin}
              setCanLogin={setCanLogin}

            />
          </PasswordLogin>
        )
      }

      {
        !canLogin && (
          <Navigate to="/login/recover" />
        )
      }

      {
        isLoading &&
        <div className="loading">
          <LoadingDots />
        </div>
      }
    </div>
  )
}