import { ModalProvider } from "contexts/ModalContext"
import { LoginFrame } from "frames/LoginFrame"
import { AdminSidebar } from "frames/Sidebar"
import { AdminDashboard } from "pages/Admin/Dashboard"
import { AdminGiftCode } from "pages/Admin/Dashboard/AdminGiftCode"
import { AdminPendingProfessional } from "pages/Admin/Dashboard/AdminPendingProfessional"
import { AdminLogin } from "pages/Admin/Login"
import { Validation } from "pages/Admin/Validation"
import { DeleteGameUserProfile } from "pages/Dashboard/GameUserProfile/GameProfileSettings/DeleteGameUserProfile"
import { GameProfileSettings } from "pages/Dashboard/GameUserProfile/GameProfileSettings"
import { GameSettings, GameSettingsMenu } from "pages/Dashboard/GameUserProfile/GameSettings"
import { GameActivity } from "pages/Dashboard/GameUserProfile/GameSettings/GameActivity"
import { GameArea } from "pages/Dashboard/GameUserProfile/GameSettings/GameArea"
import { GameClothes } from "pages/Dashboard/GameUserProfile/GameSettings/GameClothes"
import { GameTimeSettings } from "pages/Dashboard/GameUserProfile/GameSettings/GameTimeSettings"
import { GiftCode } from "pages/Dashboard/Profile/Settings/GiftCode"
import { VerifyAccountWarning } from "pages/Dashboard/VerifyAccountWarning"
import { PasswordRecover } from "pages/LoginPage/PasswordRecover"
import { Accepted } from "pages/Professional/Accepted"
import { Analysis } from "pages/Professional/Analysis"
import { Denied } from "pages/Professional/Denied"
import { Resend } from "pages/Professional/Resend"
import { Verify } from "pages/Professional/Verify"
import { VerifyGiftCode } from "pages/VerifyGiftCode"
import { ReactElement } from "react"
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom"
import { useAuth } from "./contexts/UserContext"
import { Sidebar } from "./frames/Sidebar"
import { Dashboard } from "./pages/Dashboard"
import { AdminGameUserProfile, GameUserProfile } from "./pages/Dashboard/GameUserProfile"
import { ProfessionalQuery } from "./pages/Dashboard/ProfessionalQuery"
import { AdminProfile, Profile, UserProfile } from "./pages/Dashboard/Profile"
import { GameUserSettings, Settings } from "./pages/Dashboard/Profile/Settings"
import { ChangePassword } from "./pages/Dashboard/Profile/Settings/ChangePassword"
import { LanguageSettings } from "./pages/Dashboard/Profile/Settings/LanguageSettings"
import { PersonalSettings } from "./pages/Dashboard/Profile/Settings/PersonalSettings"
import { RegisterGameUser, WarningRegisterGameUser } from "./pages/Dashboard/RegisterGameUser"
import { Users } from "./pages/Dashboard/Users"
import { LoginPage } from "./pages/LoginPage"
import { RegisterPage } from "./pages/RegisterPage"
import { ResetPasswordPage } from "./pages/ResetPassword"
import { VerifyAccount } from "./pages/VerifyAccount"
import { LinkedProfessionals } from "pages/Dashboard/GameUserProfile/GameProfileSettings/LinkedProfessionals"
import { FullLoadingDots } from "components/Loading"
import { DeleteAccount } from "pages/Dashboard/Profile/Settings/DeleteAccount"
import { UnlinkGameUserProfile } from "pages/Dashboard/GameUserProfile/GameProfileSettings/UnlinkGameUserProfile"


export const Router = () => {
  return (
    <BrowserRouter>
      <ModalProvider>
        <Routes>
          <Route
            path="dashboard/"
            element={
              <ProtectedRoute>
                <Sidebar />
              </ProtectedRoute>
            }
          >
            <Route path="" element={<Dashboard />} />
            <Route path="profile/" element={<UserProfile />}>
              <Route path="settings/" element={<Settings />}>
                <Route path="personal" element={<PersonalSettings />} />
                <Route path="change_password" element={<ChangePassword />} />
                <Route path="language" element={<LanguageSettings />} />
                <Route path="delete_account" element={<DeleteAccount />} />
                <Route path="gift_code" element={<GiftCode />} />
                <Route path="*" element={<Navigate to="/dashboard/profile/settings/" />} />
              </Route>
            </Route>

            {/* Professional Validation route group */}
            <Route path="professional/">
              <Route path="verify" element={<Verify />} />
              <Route path="analysis" element={<Analysis />} />
              <Route path="resend" element={<Resend />} />
              <Route path="denied" element={<Denied />} />
              <Route path="accepted" element={<Accepted />} />
            </Route>

            <Route path="user/:id" element={<Profile />} />
            <Route path="game_user" element={<Users />} />
            <Route path="game_user/:index" element={<GameUserProfile />} />
            <Route path="game_user/:index/settings/" element={<GameUserSettings />} >
              <Route path="profile/" element={<GameProfileSettings />} />
              <Route path="linked_professional/" element={<LinkedProfessionals />} />
              <Route path="delete_profile/" element={<DeleteGameUserProfile />} />
              <Route path="unlink_profile/" element={<UnlinkGameUserProfile />} />
              <Route path="game_settings/" element={<GameSettings />}>
                <Route path="" element={<GameSettingsMenu />} />
                <Route path="game_areas/" element={<GameArea />} />
                <Route path="game_activities/" element={<GameActivity />} />
                <Route path="game_clothes/" element={<GameClothes />} />
                <Route path="game_time/" element={<GameTimeSettings />} />
              </Route>
              <Route path="game_time" element={<GameTimeSettings />} />
            </Route>
            <Route path="game_user/register" element={<RegisterGameUser />} />
            <Route path="professionals" element={<ProfessionalQuery />} />
            <Route path="*" element={<LogRoute name="[dashboard Catch-all]"><Navigate to="/dashboard/" /></LogRoute>} />
          </Route>

          <Route path="admin/dashboard/" element={
            <AdminProtectedRoute>
              <AdminSidebar />
            </AdminProtectedRoute>
          }>
            <Route path="" element={<AdminDashboard />} />
            <Route path="gift_code/" element={<AdminGiftCode />} />
            <Route path="user/:id" element={<AdminProfile />} />
            <Route path="game_user/:id" element={<AdminGameUserProfile />} />
            <Route path="validation/" element={<AdminPendingProfessional />} />
            <Route path="validation/:id" element={<Validation />} />
            <Route path="*" element={<LogRoute name="[admin/dashboard Catch-all]"><Navigate to="/admin/dashboard/" /></LogRoute>} />

          </Route>

          <Route
            path="admin/login/"
          >
            <Route path="" element={<AdminLogin />} />
          </Route>

          <Route path='login/' element={<LoginPage />}>
            <Route path="" element={<LoginFrame />} />
            <Route path="recover" element={<PasswordRecover />} />
          </Route>
          <Route path='reset_password/:token' element={<ResetPasswordPage />} />
          <Route path='register' element={<RegisterPage />} />

          <Route path='verify/:token' element={<VerifyAccount />} />
          <Route path='activate/:token' element={
            <ProtectedRoute>
              <VerifyGiftCode />
            </ProtectedRoute>
          } />

          <Route path="*" element={
            <LogRoute name="[root Catch-all]">
              <Navigate to="/dashboard/" />
            </LogRoute>
          } />

        </Routes>
      </ModalProvider>
    </BrowserRouter>
  )
}

const LogRoute = ({ children, name }: { children: ReactElement, name?: string }) => {
  const location = useLocation()
  console.log("LOCATION:", name, location.pathname)
  return children
}

const ProtectedRoute = ({ children }: { children: ReactElement }) => {
  const auth = useAuth()
  const location = useLocation()

  // Function to assure the professional will still be able to modify its profile while waiting for approval.
  function checkIsRouteBlocked(path: string) {
    const allowedRoutes = [
      '/dashboard/profile',
      '/dashboard/professional'
    ]

    for (let route of allowedRoutes) {
      if (path.match(route)) {
        return false
      }
    }

    return true
  }

  const skipGameUserCreation = localStorage.getItem("skip-game-user-creation") || false
  const loggedIn = !(!auth.user.firstName)

  if (location.pathname === "/dashboard/profile/settings/delete_account") {
    return children
  }

  if (!loggedIn) {
    return <Navigate to='/login' state={{ from: location }} />
  }

  if (auth.user.type === "admin") {
    return <Navigate to='/admin/dashboard/' state={{ from: location }} />
  }

  if (auth.user.type !== "professional") {
    if (auth.isGameUsersLoading) {
      return <FullLoadingDots />
    }

    if (!skipGameUserCreation && auth.gameUsers.length <= 0) {
      return <WarningRegisterGameUser />
    }

    return children
  }

  if (!auth.user.isEmailVerified) {
    return <VerifyAccountWarning />
  }


  if (auth.user.professionalStatus === "UNVERIFIED" && checkIsRouteBlocked(location.pathname)) {
    return <Navigate to='/dashboard/professional/verify' state={{ from: location }} />
  }

  if (auth.user.professionalStatus === "ANALYSIS" && checkIsRouteBlocked(location.pathname)) {
    return <Navigate to='/dashboard/professional/analysis' state={{ from: location }} />
  }

  if (auth.user.professionalStatus === "RESEND" && checkIsRouteBlocked(location.pathname)) {
    return <Navigate to='/dashboard/professional/resend' state={{ from: location }} />
  }

  if (auth.user.professionalStatus === "DENIED" && checkIsRouteBlocked(location.pathname)) {
    return <Navigate to='/dashboard/professional/denied' state={{ from: location }} />
  }

  if (auth.user.professionalStatus === "ACCEPTED" && checkIsRouteBlocked(location.pathname) && !localStorage.getItem("professional-skip-welcome")) {
    return <Navigate to='/dashboard/professional/accepted' state={{ from: location }} />
  }

  return children
}

const AdminProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth()
  const location = useLocation()

  const loggedIn = !(!auth.user.firstName)

  if (!loggedIn) {
    return <Navigate to='/admin/login' state={{ from: location }} />
  }

  if (auth.user.type !== "admin") {
    return <Navigate to='/dashboard' state={{ from: location }} />
  }

  return children
}