import { Updater } from "use-immer"
import { ClothesInterface } from ".."
import { SwitchButton } from "../../../../../../components/Button"
import { useTranslation } from "react-i18next"

import "./Clothes.sass"

export const Clothes = ({ clothes, setClothes }: { clothes: ClothesInterface, setClothes: Updater<ClothesInterface[]> }) => {
  const { t } = useTranslation()

  return (
    <div className="clothes">
      <div className="image">
        <img src={`/clothes/${clothes.id + 1}.png`} alt="" />
      </div>
      <div className="info">
        <p>{t(`clothes.${clothes.id}`, { ns: 'clothes' })}</p>
        <SwitchButton
          state={clothes.enabled}
          onClick={e => {
            e.stopPropagation()
            setClothes(stateClothes => {
              for (let i = 0; i < stateClothes.length; i++) {
                if (stateClothes[i].id === clothes.id) {
                  stateClothes[i].enabled = !(stateClothes[i].enabled)
                }
              }
            })
          }}

        />
      </div>
    </div>
  )
}