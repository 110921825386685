import { DetailedHTMLProps, HTMLAttributes, MouseEvent, ReactNode, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoPerson } from "react-icons/io5"
import { Link, useNavigate } from 'react-router-dom'
import { SwitchButton, ThreeStepSwitch } from '../../components/Button'
import { useModal } from '../../contexts/ModalContext'
import { GameUserProps, UserQueryProps, useAuth } from '../../contexts/UserContext'
import { useWarnings } from '../../contexts/WarningContext'
import api from '../../services/api'
import { ProfessionalUserFunctions } from './ProfessionalUserCard'

import './UserCard.sass'

interface GameUserCardProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  gameUser: GameUserProps
}

interface ProfessionalUserCardProps {
  user: {
    id: string,
    name: string,
    email: string
  },
  after?: ReactNode
}

interface ProfessionalUserSmallCardProps {
  user: {
    responsible_id: string,
    name: string,
    email: string
  }
  link?: string,
  profile_picture?: string
  onClick?: (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => void
}

interface UserSmallCardProps {
  user: UserQueryProps,
  link: string
}

export const GameUserCard = ({ gameUser, onClick, ...props }: GameUserCardProps) => {
  const { setSelectedGameUser, user, getGameUsers } = useAuth()
  const [isActive, setIsActive] = useState<boolean>(gameUser.is_active)

  const { setWarning } = useWarnings()
  const { t } = useTranslation()

  const changeUserStatus = useCallback(({ isActive }: { isActive: boolean }) => {
    api.put('/api/user/game_user/activate', {
      is_active: isActive,
      game_user_id: gameUser.id
    }).then(
      response => {
        isActive ? (
          setWarning({ warning: t('profile.cards.gameUser.successfullyActivated'), type: "success" })
        ) : (
          setWarning({ warning: t('profile.cards.gameUser.successfullyDeactivated'), type: "success" })
        )
        setIsActive(isActive)
        getGameUsers()
      },
      error => {
        setWarning({ warning: t([`errors.${error.response.data.detail}`, 'errors.undefined']), type: "error" })
      }
    )
  }, [getGameUsers, gameUser, setWarning, t])


  return (
    <div className={["user-card", !isActive && "disabled"].join(" ")} onClick={(e) => { setSelectedGameUser({ ...gameUser }); onClick !== undefined && onClick(e) }} {...props}>
      <div>
        <div className="profile-pic">
          <IoPerson className="icon-person" />
          <img id="game-user-profile-picture" src={`/api/user/game_user/${gameUser.id}/profile_picture`} alt="" className="profile-icon" />
        </div>
        <div>
          <p className="text-xl flex self-center font-bold text-gray-700">{gameUser.name}</p>
          <div onClick={e => { setSelectedGameUser({ ...gameUser }); }} className="flex flex-col">
            {
              user.type === 'parent' ? (
                <p className="text-sm pb-1 justify-self-end"><b className="text-gray-400 font-semibold">{t('users.responsible')}: </b> {gameUser.professional ? <Link to={`/dashboard/user/${gameUser.professional.responsible_id}`} onClick={e => e.stopPropagation()}> {gameUser.professional.name}</Link> : (<Link onClick={(e) => { e.stopPropagation(); setSelectedGameUser({ ...gameUser }) }} className="text-teal-300 hover:underline" to="/dashboard/professionals">{t('users.invite')}</Link>)}</p>
              ) : (
                <p className="text-sm pb-1 justify-self-end"><b className="text-gray-400 font-semibold">{t('users.responsible')}: </b> <Link to={`/dashboard/user/${gameUser.parent.responsible_id}`} onClick={e => e.stopPropagation()}>{gameUser.parent.name}</Link> </p>
              )
            }
          </div>
          {
            user.type !== "professional" && (
              <ThreeStepSwitch
                state={isActive}
                label={[t("profile.cards.gameUser.deactivate"), null, t("profile.cards.gameUser.activate")]}
                buttonFunctions={[() => { changeUserStatus({ isActive: false }) }, null, () => { changeUserStatus({ isActive: true }) }]}
              />
            )
          }
        </div>
      </div>
    </div>
  )
}

export const GameUserCardSmall = ({ gameUser, ...props }: { gameUser: GameUserProps }) => {
  const { setSelectedGameUser, user, gameUsers } = useAuth()
  const { t } = useTranslation()
  const navigate = useNavigate()

  let gameUserIndex = 0
  gameUsers.forEach((currentGameUser, index) => {
    if (currentGameUser.name === gameUser.name) {
      if (currentGameUser.parent.name === gameUser.parent.name) {
        gameUserIndex = index
      }
    }
  })

  if (user.type === "admin") {
    return (
      <div className="small-user-card" {...props} onClick={() => navigate(`/admin/dashboard/game_user/${gameUser.id}`)}>
        <div>
          <div className="img">
            <IoPerson className="icon-person" />
          </div>
        </div>
        <div>
          <p>{gameUser.name}</p>
          <p><b> {t('profile.cards.gameUser.professional')}: </b>{gameUser.professional ? gameUser.professional.name : t('profile.cards.gameUser.noProfessionalLinked')} </p>
          <p><b>{t('profile.cards.gameUser.parent')}: </b> {gameUser.parent.name}</p>
        </div>
        <div>
          <Link to={`/dashboard/game_user/${gameUserIndex}`}>{t('profile.cards.gameUser.profile')}</Link>
        </div>

      </div>
    )
  }

  return (
    <div className="small-user-card" {...props}>
      <div>
        <div className="img">
          <IoPerson className="icon-person" />
          <img id="game-user-profile-picture" src={`/api/user/game_user/${gameUser.id}/profile_picture`} alt="" className="profile-icon" />

        </div>
      </div>
      <div>
        <p>{gameUser.name}</p>
        {user.type === "parent" && <p><b> {t('profile.cards.gameUser.professional')}: </b>{gameUser.professional ? gameUser.professional.name : (<Link onClick={(e) => { e.stopPropagation(); setSelectedGameUser({ ...gameUser }); }} to='/dashboard/professionals'>{t('profile.cards.gameUser.invite')}</Link>)} </p>}
        {user.type === "professional" && <p><b>{t('profile.cards.gameUser.parent')}: </b> {gameUser.parent.name}</p>}
      </div>
      <div>
        <Link to={`/dashboard/game_user/${gameUserIndex}`}>{t('profile.cards.gameUser.profile')}</Link>
      </div>
    </div>
  )
}


export const SelectableGameUserCardSmall = ({ gameUser, isSelected, onClick, ...props }: { gameUser: GameUserProps, isSelected: boolean, onClick: () => void }) => {

  return (
    <div className={`small-user-card ${!isSelected ? "opacity-60" : "opacity-100"}`} {...props} onClick={() => { onClick() }}>
      <div>
        <div className="img">
          <IoPerson className="icon-person" />
          <img id="game-user-profile-picture" src={`/api/user/game_user/${gameUser.id}/profile_picture`} alt="" className="profile-icon" />

        </div>
      </div>
      <div>
        <p>{gameUser.name}</p>
      </div>
      <div>
        <SwitchButton state={isSelected} onClick={e => { e.stopPropagation(); onClick() }} />
      </div>
    </div>
  )
}

export const ProfessionalUserCard = ({ user, after, ...props }: ProfessionalUserCardProps) => {
  const { setModal } = useModal()

  return (
    <div className="user-card flex items-end justify-center w-64 h-64 bg-teal-300 shadow-lg rounded-lg cursor-pointer shrink-0" onClick={() => { setModal({ element: <ProfessionalUserFunctions professional={user} /> }) }} {...props}>
      <div className="flex flex-col w-full h-4/5 bottom-0 rounded-t-3xl rounded-b-lg bg-white relative justify-end">
        <div className="profile-pic flex items-center justify-center absolute w-20 h-20 rounded-full border-4 bg-white border-teal-300">
          <IoPerson className="text-teal-300 h-3/5 w-3/5" />
          <img id="game-user-profile-picture" src={`/api/user/${user.id}/profile_picture`} alt="" className="profile-icon" />

        </div>
        <div className="flex flex-col h-4/5 w-full px-4 justify-evenly">
          <p className="text-xl flex self-center font-bold text-gray-700">{user.name}</p>
          <div className="flex flex-col">
            <p className="text-sm pb-1 justify-self-end w-full overflow-hidden whitespace-nowrap text-ellipsis "><b className="text-gray-400 font-semibold">Email: </b> {user.email} </p>
          </div>
        </div>
      </div>
      {after}
    </div>
  )
}


export const UserCardSmall = ({ user, link = "/dashboard/user/", profile_picture, onClick, ...props }: ProfessionalUserSmallCardProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <div onClick={(e) => { onClick !== undefined ? onClick(e) : navigate(`${link}${user.responsible_id}`) }} className="small-user-card">
      <div>
        <div className="img">
          <IoPerson className="icon-person" />
          <img id="game-user-profile-picture" src={`/api/user/${user.responsible_id}/profile_picture`} alt="" className="profile-icon" />

        </div>
      </div>
      <div>
        <p>{user.name}</p>
        <p><b>{t('users.email')}: </b>{user.email}</p>
      </div>
      <div>
      </div>
    </div>
  )
}

export const AdminUserCardSmall = ({ user, link, ...props }: UserSmallCardProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className="small-user-card" title="Go to profile" onClick={() => navigate(link)}>
      <div>
        <div className="img">
          <IoPerson className="icon-person" />
          <img id="game-user-profile-picture" src={`/api/admin/user/${user.id}/profile_picture`} alt="" className="profile-icon" />

        </div>
      </div>
      <div>
        <p title={`${user.firstName} ${user.lastName} - ${t(`login.${user.type}`)}`}>{`${user.firstName} ${user.lastName}`} <span>{t(`login.${user.type}`)}</span></p>
        <p title={user.email}><b>{t('users.email')}: </b>{user.email}</p>
        {user.type === "professional" && (<p><b>{t('users.status')}: </b>{t(`profile.header.functions.changeUserStatus.${user.professionalStatus}`)}</p>)}
        {user.type === "parent" && (<p><b>{t('users.status')}: </b>{user.isPremium ? t("profile.cards.settings.personalSettings.subscription.premium") : t("profile.cards.settings.personalSettings.subscription.free")}</p>)}
      </div>
    </div>
  )
}
