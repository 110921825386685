import { PrimaryButton, SecondaryButton } from "../../../components/Button"
import { NumberInput, TextInput } from "../../../components/Input"
import { useModal } from "../../../contexts/ModalContext"
import { useWarnings } from "../../../contexts/WarningContext"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import api from "../../../services/api"
import "./CreateGiftCode.sass"

export const CreateGiftCode = () => {
  const { t } = useTranslation()
  const { closeModal } = useModal()
  const { setWarning } = useWarnings()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [tags, setTags] = useState<string>("")
  const [amount, setAmount] = useState<number>(1)
  const [validityDays, setValidityDays] = useState<number>(0)
  const [gameUserLicenses, setGameUserLicenses] = useState<number>(0)

  const createGiftCodes = () => {
    setIsLoading(true)
    api.post("/api/admin/gift_code", {
      "amount": amount,
      "tags": [...tags.split(";")],
      "validity_days": validityDays,
      "game_user_licenses": gameUserLicenses
    }).then(
      response => {
        setWarning({ warning: t("admin.dashboard.gift_code.created"), type: "success" })
      },
      error => {
        setWarning({ warning: t("admin.dashboard.gift_code.error"), type: "error" })
      }
    ).finally(() => {
      setIsLoading(false)
      closeModal()
    })
  }

  return (
    <div className="create-gift-code" data-testid="create-gift-code">
      <h2>{t('admin.dashboard.gift_code.createGiftCodes')}</h2>

      <NumberInput label={t("admin.dashboard.gift_code.validityDays")} value={validityDays} setValue={setValidityDays} After={<p className="text-after">{t("admin.dashboard.gift_code.validityDaysAfter")}</p>} />
      <NumberInput label={t("admin.dashboard.gift_code.licenses")} value={gameUserLicenses} setValue={setGameUserLicenses} After={<p className="text-after">{t("admin.dashboard.gift_code.licensesAfter")}</p>} />
      <TextInput label={t("admin.dashboard.gift_code.tags")} value={tags} setValue={setTags} after={<p className="text-after">{t("admin.dashboard.gift_code.tagsAfter")}</p>} />
      <NumberInput label={t("admin.dashboard.gift_code.amount")} value={amount} setValue={setAmount} After={<p className="text-after">{t("admin.dashboard.gift_code.amountAfter")}</p>} />

      <PrimaryButton isLoading={isLoading} onClick={createGiftCodes} label={t("admin.dashboard.gift_code.createGiftCodes")} />
      <SecondaryButton onClick={closeModal} label={t("admin.dashboard.gift_code.discard")} />
    </div>
  )
}