import { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { WhiteLoadingRing } from "../Loading";
import "./Button.sass";

interface ButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  label?: string
  isLoading?: boolean
  disabled?: boolean
}

interface SwitchProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  state: boolean
  label?: string
}

interface ThreeStepSwitchProps extends DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  state: boolean | null
  buttonFunctions: ((() => void) | null)[]
  children?: string,
  label?: ReactNode[]
}

interface SaveButtonProps {
  hasChanged: boolean
  resetData: () => void
  submitChanges: () => void
  isLoading: boolean
}

export const PrimaryButton = ({ label, type = 'button', isLoading, children, ...props }: ButtonProps) => {
  return (
    <button
      type={type}
      className="button primary"
      {...props}
    >
      {!!isLoading ? (
        <div className="h-8 w-8">
          <WhiteLoadingRing />
        </div>
      ) : (
        <>
          {children}
          {label}
        </>
      )
      }
    </button>
  )
}

export const SecondaryButton = ({ label, type = 'button', children, ...props }: ButtonProps) => {
  return (
    <button
      type={type}
      className="button secondary"
      {...props}
    >
      {children}
      {label}
    </button>
  )
}

export const SwitchButton = ({ state, children, onClick, label, ...props }: SwitchProps) => {
  return (
    <>
      {!!label && <p className="text-gray-700 font-semibold"> {label} </p>}
      <button className={["switch-button", state && 'active', "shrink-0"].filter(value => !!value).join(" ")} onClick={onClick} type="button" {...props}>
        <div className="switch">
          <input type="checkbox" checked={state} onChange={e => e.stopPropagation()} onClick={e => e.stopPropagation()} />
          <span className="slider" />
        </div>
        {children}
      </button>
    </>
  )
}

export const ThreeStepSwitch = ({ state, children, buttonFunctions, label = [null, "Custom", null], ...props }: ThreeStepSwitchProps) => {
  return (
    <div className={["three-switch", state === true && "active", state === null && "null", state === false && "blocked"].join(" ")} {...props}>
      <button className={`${buttonFunctions[0] === null && "disabled"} ${state === false ? "active" : ""}`} type="button" onClick={(e) => { e.stopPropagation(); buttonFunctions[0] && buttonFunctions[0]() }}>{label[0]}</button>
      <button className={`${buttonFunctions[1] === null && "disabled"} ${state === null ? "active" : ""}`} type="button" onClick={(e) => { e.stopPropagation(); buttonFunctions[1] && buttonFunctions[1]() }}> {label[1]} </button>
      <button className={`${buttonFunctions[2] === null && "disabled"} ${state === true ? "active" : ""}`} type="button" onClick={(e) => { e.stopPropagation(); buttonFunctions[2] && buttonFunctions[2]() }}>{label[2]}</button>
      <span role="marquee" className={[state === true && "active", state === null && "null", state === false && "blocked"].join(" ")} />
    </div>
  )
}

export const SaveButton = ({ hasChanged, resetData, submitChanges, isLoading = false }: SaveButtonProps) => {
  const { t } = useTranslation()

  return (
    <div className={`buttons opacity-0 ${hasChanged ? 'animate-slide-in opacity-100' : 'animate-slide-out'}`}>
      <button type="button" onClick={resetData}>
        {t('profile.cards.settings.personalSettings.changes.discard')}
      </button>
      <button type="button" onClick={submitChanges}>
        {isLoading ? (
          <div className="h-10 w-10">
            <WhiteLoadingRing />
          </div>
        ) : t('profile.cards.settings.personalSettings.changes.save')}
      </button>
    </div>
  )
}


export const DeleteButton = ({ label, type = 'button', isLoading, disabled, children, ...props }: ButtonProps) => {
  return (
    <button
      type={type}
      className={`button delete ${disabled ? "disable" : ""}`}
      {...props}
    >
      {!!isLoading ? (
        <div className="h-8 w-8">
          <WhiteLoadingRing />
        </div>
      ) : (
        <>
          {children}
          {label}
        </>
      )
      }
    </button>
  )
}