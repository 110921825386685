import { ValidationFunctions } from "pages/Admin/Validation/ValidationFunctions"
import { MutableRefObject, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { IoBan, IoCamera, IoCheckmark, IoMail, IoPerson, IoRefresh, IoSettings } from "react-icons/io5"
import { useNavigate } from "react-router-dom"
import { ChangeGameUserProfilePicture, ChangeProfilePicture } from "../../components/ChangeProfilePicture"
import { LoadingRing } from "../../components/Loading"
import { useModal } from "../../contexts/ModalContext"
import { GameUserProps, useAuth } from "../../contexts/UserContext"
import { useWarnings } from "../../contexts/WarningContext"
import { ParentUserData, ProfessionalUserData, RequestedUserData } from "../../pages/Dashboard/Profile"
import api from "../../services/api"
import './ProfileHeader.sass'


export const ProfileHeader = () => {
  const { GetUser } = useAuth()
  const { setModal } = useModal()
  const navigate = useNavigate()

  const user = GetUser()

  return (
    <div className="profile-header" style={{ backgroundImage: 'url("/background-details.svg")' }}>
      <div className="content" >
        <div className="profile-pic">
          <IoPerson className='icon' />
          <img src="/api/user/profile_picture" alt="" className="profile-icon" />
          <button type="button" onClick={e => setModal({ element: <ChangeProfilePicture />, userType: user.type })} className="change-picture">
            <IoCamera />
          </button>
        </div>
        <div className="profile-info">
          <p className="text-lg"><b>{user.firstName} {user.lastName}</b></p>
          <p className="text-gray-500">{user.email}</p>
        </div>
        <div className="profile-functions">
          <button onClick={() => navigate("/dashboard/profile/settings")}>
            <IoSettings />

          </button>
        </div>
      </div>
    </div>
  )
}

export const GameUserProfileHeader = ({ gameUser }: { gameUser: GameUserProps }) => {
  const navigate = useNavigate()
  const { setModal } = useModal()
  const { GetUser } = useAuth()
  const user = GetUser()

  const profilePictureRef = useRef<HTMLImageElement>() as MutableRefObject<HTMLImageElement>

  return (
    <div className="profile-header" style={{ backgroundImage: 'url("/background-details.svg")' }}>
      <div className="content" >
        <div className="profile-pic">
          <IoPerson className='icon w-4/5 h-4/5' />
          <img ref={profilePictureRef} id="game-user-profile-picture" src={`/api/user/game_user/${gameUser.id}/profile_picture`} alt="" className="profile-icon" />
          {
            user.type === "parent" && (
              <button type="button" onClick={e => setModal({
                element: <ChangeGameUserProfilePicture id={gameUser.id} />, userType: user.type, onClose: () => {
                  fetch(`/api/user/game_user/${gameUser.id}/profile_picture`, { cache: 'reload', mode: 'no-cors' }).then(() => profilePictureRef.current.src = `/api/user/game_user/${gameUser.id}/profile_picture?refresh=${new Date().getTime()}`)
                }
              })} className="change-picture">
                <IoCamera />
              </button>
            )
          }
        </div>
        <div className="profile-info">
          <p className="text-lg"><b>{gameUser.name}</b></p>
          <p className="text-gray-500">555 xp</p>
        </div>
        <div className="profile-functions">
          <button onClick={() => navigate("settings")}>
            <IoSettings />
          </button>
        </div>
      </div>
    </div>
  )
}


export const UserProfileHeader = ({ user }: { user: RequestedUserData }) => {
  return (
    <div className="profile-header" style={{ backgroundImage: 'url("/background-details.svg")' }}>
      <div className="content" >
        <div className="profile-pic">
          <IoPerson className='icon' />
          <img id="game-user-profile-picture" src={`/api/user/${user.id}/profile_picture`} alt="" className="profile-icon" />
        </div>
        <div className="profile-info">
          <p className="text-lg"><b>{user.firstName} {user.lastName}</b></p>
          <p className="text-gray-500">{user.email}</p>
        </div>
      </div>
    </div>
  )
}

export const AdminUserProfileHeader = ({ user, callback = () => { } }: { user: RequestedUserData, callback?: () => void }) => {
  if (user.type === "parent") {
    return (
      <AdminParentProfileHeader user={user} callback={callback} />
    )
  } else {
    return (
      <AdminProfessionalProfileHeader user={user} callback={callback} />
    )
  }
}

export const AdminParentProfileHeader = ({ user, callback = () => { } }: { user: ParentUserData, callback?: () => void }) => {
  const { t } = useTranslation()
  const { setWarning } = useWarnings()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleSendInvite = () => {
    setIsLoading(true)
    api.post("/api/admin/user/gift_code/", {
      "user_id": user.id,
      "validity_days": 7,
      "game_user_licenses": 1
    }).then(
      response => {
        setWarning({ warning: t('profile.header.functions.sendInvite.success'), type: "success" })
      },
      error => {
        setWarning({ warning: t([`errors.${error.response.data.detail}`, 'errors.undefined']), type: "error" })

      }
    ).finally(() => {
      setIsLoading(false)
      callback()
    })
  }

  return (
    <div className="profile-header" style={{ backgroundImage: 'url("/background-details.svg")' }}>
      <div className="content" >
        <div className="profile-pic">
          <IoPerson className='icon' />
        </div>
        <div className="profile-info">
          <p className="text-lg"><b>{user.firstName} {user.lastName}</b></p>
          <p className="text-gray-500">{user.email}</p>
        </div>
        <div className="profile-functions">
          {
            isLoading ? (
              <div className="flex w-8 h-8">
                <LoadingRing />
              </div>
            ) : (
              user.type === "parent" && (
                user.isPremium ? (
                  <button onClick={handleSendInvite} title={t('profile.header.functions.sendInvite.title')} disabled><IoMail /></button>

                ) : (
                  <button onClick={handleSendInvite} title={t('profile.header.functions.sendInvite.title')}><IoMail /></button>
                )
              )
            )
          }

        </div>
      </div>
    </div>
  )
}



export const AdminProfessionalProfileHeader = ({ user, callback = () => { } }: { user: ProfessionalUserData, callback?: () => void }) => {
  const { t } = useTranslation()
  const { setModal, closeModal } = useModal()

  return (
    <div className="profile-header" style={{ backgroundImage: 'url("/background-details.svg")' }}>
      <div className="content" >
        <div className="profile-pic">
          <IoPerson className='icon' />
        </div>
        <div className="profile-info">
          <p className="text-lg"><b>{user.firstName} {user.lastName}</b><i>{t(`profile.header.functions.changeUserStatus.${user.professionalStatus}`)}</i></p>
          <p className="text-gray-500">{user.email}</p>
        </div>
        <div className="profile-functions">
          <button onClick={e => { e.preventDefault(); setModal({ element: <ValidationFunctions user={user} initialStatus="DENIED" callback={() => { closeModal(); callback() }} /> }) }} type="button" className={`function deny ${user.professionalStatus === "DENIED" && "active"}`} >{<IoBan />}</button>
          <button onClick={e => { e.preventDefault(); setModal({ element: <ValidationFunctions user={user} initialStatus="RESEND" callback={() => { closeModal(); callback() }} /> }) }} type="button" className={`function resend ${user.professionalStatus === "RESEND" && "active"}`} >{<IoRefresh />}</button>
          <button onClick={e => { e.preventDefault(); setModal({ element: <ValidationFunctions user={user} initialStatus="ACCEPTED" callback={() => { closeModal(); callback() }} /> }) }} type="button" className={`function accept ${user.professionalStatus === "ACCEPTED" && "active"}`} >{<IoCheckmark />}</button>
        </div>
      </div>
    </div>
  )
}

