
import { FormEvent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { IoSearch } from "react-icons/io5"
import { Navigate } from "react-router"
import { LoadingRing } from "../../../components/Loading"
import { useAuth } from "../../../contexts/UserContext"
import { ProfessionalUserCard } from "../../../frames/UserCard"
import api from "../../../services/api"

interface ProfessionalUsers {
  id: string
  name: string
  email: string
}

export const ProfessionalQuery = () => {
  const { user } = useAuth()
  const [query, setQuery] = useState('')
  const [professionalsResult, setProfessionalsResult] = useState<ProfessionalUsers[]>([])
  const [serverError, setServerError] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)


  const { t } = useTranslation()

  useEffect(() => {
    const search = query.length >= 3 ? query : ""

    if (search === "" && professionalsResult.length > 0) {
      setIsLoading(false)
      return
    };
    setIsLoading(true)

    let _setProfessional = (professionalUsers: ProfessionalUsers[]) => {
      setProfessionalsResult(professionalUsers)
    }

    let _setServerError = (error: string[]) => {
      setServerError(error)
    }

    const timeout = setTimeout(() => {
      api.get(`/api/user/search/professional?query=${search}`).then(
        response => {
          _setProfessional(response.data)
        },
        error => {
          _setServerError(error.response.data.detail)
        },
      )
        .finally(() => setIsLoading(false))
    }, 500)

    return () => {
      clearTimeout(timeout);
      _setProfessional = () => { };
      _setServerError = () => { };
    }

  }, [query, professionalsResult.length])


  if (user.type === "professional") {
    return (
      <Navigate to="/dashboard/" />
    )
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
  }

  return (
    <div className="flex flex-col w-full h-full">
      <div className="w-full h-12 relative" onSubmit={handleSubmit}>
        <input className="col-span-2 items-center border-none bg-white w-full h-12 px-4 rounded-lg shadow-lg" type="text" placeholder={t('query.placeholder')} value={query} onChange={e => { setQuery(e.target.value) }} autoFocus />
        <span className="flex items-center text-gray-700 absolute right-2 top-0 bottom-0">
          <IoSearch className="h-6 w-6" />
        </span>
      </div>

      <div className="flex p-4 gap-4 md:p-10 h-full w-full justify-center md:justify-start flex-wrap">
        {
          isLoading ? (
            <div className="flex w-full h-full items-center justify-center">
              <div className="h-16 w-16">
                <LoadingRing />
              </div>
            </div>
          ) : (

            professionalsResult?.length > 0 ? (
              professionalsResult.map(professional => (
                <ProfessionalUserCard user={professional} key={professional.id} />
              ))
            ) : (
              <div className="flex w-full h-full items-center justify-center">
                <p className="font-semibold" >{t('query.errors.noUser')}</p>
              </div>
            )
          )
        }
        {
          serverError?.length > 0 && (
            serverError.map(error => (
              <p key={error} className="flex font-semibold text-red-800">{error}</p>
            ))
          )
        }
      </div>

    </div>
  )
}