import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { UserProvider } from './contexts/UserContext';
import { WarningProvider } from './contexts/WarningContext';
import './i18n';

import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import reportWebVitals from './reportWebVitals';

import './styles/index.css';

Sentry.init({
  dsn: "https://e68a62dfe5a24f148cb52b2b9ca65e42@o4504330311827456.ingest.sentry.io/4504330316021760",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});


const container = document.getElementById('root')
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <WarningProvider>
      <UserProvider>
        <App />
      </UserProvider>
    </WarningProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
