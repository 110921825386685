import { useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Dropdown } from '../../components/Dropdown'
import { GameUserData_item, GameUserData_item_array } from '../../contexts/UserContext'
import { GameSessionChartData, LineChartData } from '../../utils/ChartFunctions'
import { BarChart } from './BarChart'
import { LineChart } from './LineChart'


interface ChartData {
  data: GameUserData_item_array | GameUserData_item[]
  title: string
}


export const LineBarChart = ({ data, title }: ChartData) => {
  const [variant, setVariant] = useState<"line" | "bar">("line")
  const [isOptionOpen, setIsOptionOpen] = useState(false)
  const { t } = useTranslation()
  const gameData = Array.isArray(data) ? GameSessionChartData({ data, t }) : LineChartData({ data, t })


  const chartData = useMemo(() => ({
    data: gameData,
    title: title,
    t: t,
    yAxisLabel: t(`dashboard.charts.units.${gameData.unitOfMeasurement}`),
    options: function () {
      setIsOptionOpen(true)
    }
  }), [t, title, gameData])

  const chartVariants = useMemo(() => ({
    "line": <LineChart {...chartData} />,
    "bar": <BarChart {...chartData} />
  }), [chartData])

  return (
    <div className="flex flex-col w-full h-full items-center justify-center gap-2 relative rounded-xl overflow-hidden transition-all" data-testid="line-bar-chart">
      {
        isOptionOpen && (
          <div className={"w-full h-full bg-gray-700 absolute z-10 bg-opacity-60 cursor-pointer"} onClick={e => { e.stopPropagation(); setIsOptionOpen(false) }}>
            <div className="animate-left-slide-in w-3/5 h-full shadow-xl bg-white overflow-y-auto overflow-x-hidden p-2 cursor-default" onClick={e => { e.stopPropagation() }}>
              <h2 className="text-lg font-bold">{t('dashboard.charts.options')}</h2>
              <div className="w-full flex flex-col gap-1 p-2">
                <h3 className="font-semibold" >{t('dashboard.charts.chartType')}</h3>
                <Dropdown<"line" | "bar"> options={[{ label: t("dashboard.charts.line"), value: "line" }, { label: t("dashboard.charts.bar"), value: "bar" }]} setOption={(value: "line" | "bar" | undefined) => { value && setVariant(value) }} selectedOption={variant} />
              </div>
            </div>
          </div>
        )
      }

      {chartVariants[variant]}
    </div>

  )
}