import { allUsersData } from "../../utils/ChartFunctions"
import { UserDropdown } from "../../components/UserDropdown"
import { useAuth } from "../../contexts/UserContext"
import { DashboardFrame } from '../../frames/DashboardFrame'
import { NoUser } from "../../frames/NoUser"

export const Dashboard = () => {
  const { selectedGameUser, gameUsers } = useAuth()

  return (
    <>
      {
        <UserDropdown />
      }
      {
        (gameUsers.length === 0 ? (
          <NoUser />
        ) : (
          <DashboardFrame userData={selectedGameUser ? selectedGameUser.data : allUsersData({ data: gameUsers.map(gameUser => gameUser.data) }).GameData} />
        ))
      }
    </>
  )
}