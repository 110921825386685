import { Area } from 'react-easy-crop'

export const getBase64 = (file: File): Promise<string> => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      if (reader.result !== null) {
        if (reader.result instanceof ArrayBuffer) {
          resolve(Buffer.from(reader.result).toString("base64"))
        } else {
          resolve(reader.result)
        }
      }
    }

  })
}

const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous')
    image.src = url
  })

export const getCroppedImg = async (imageSrc: string, crop: Area): Promise<Blob> => {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (ctx == null)
    throw Error("No ctx avaible")

  canvas.width = 500
  canvas.height = 500

  ctx.drawImage(
    image,
    crop.x,
    crop.y,
    crop.width,
    crop.height,
    0,
    0,
    canvas.width,
    canvas.height
  )

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob === null) {
        throw Error("Blob not avaiable")
      }

      resolve(blob)
    }, 'image/jpeg')
  })
}