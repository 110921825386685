import './Loading.sass'

export const LoadingDots = ({ ...props }) => {
  return (
    <>
      <div data-testid="lds-ellipsis" className="lds-ellipsis" {...props}><div></div><div></div><div></div><div></div></div>
    </>
  )
}

export const FullLoadingDots = ({ ...props }) => {
  return (
    <div {...props} className="w-screen h-screen fixed top-0 left-0  bg-gray-700 bg-opacity-70 flex items-center justify-center">
      <div data-testid="lds-ellipsis" className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
  )
}

export const LoadingRing = ({ ...props }) => {
  return (
    <>
      <div data-testid="lds-dual-ring" className="lds-dual-ring w-full h-full white" {...props}></div>
    </>
  )
}


export const WhiteLoadingRing = ({ ...props }) => {
  return (
    <>
      <div data-testid="lds-dual-ring" className="lds-dual-ring-white w-full h-full white" {...props}></div>
    </>
  )
}