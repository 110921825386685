import { RadioInput } from '../../../../../components/Input'
import { useTranslation } from 'react-i18next'

import "./LanguageSettings.sass"

export const LanguageSettings = () => {
  const { t, i18n } = useTranslation()

  return (
    <div className="settings-platform">
      <form onSubmit={e => e.preventDefault()} className="card-session">
        <h3>{t('profile.cards.settings.platformSettings.language.language')}</h3>
        <RadioInput
          label={t('profile.cards.settings.platformSettings.language.english')}
          checked={i18n.language === 'en'}
          onChange={() => { i18n.changeLanguage('en') }}
        />

        <RadioInput
          label={t('profile.cards.settings.platformSettings.language.portuguese')}
          checked={i18n.language === 'pt-br'}
          onChange={() => { i18n.changeLanguage('pt-br') }}
        />
      </form>
    </div>
  )
}