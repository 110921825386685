import { Background } from "../../../components/Background"
import { PrimaryButton } from "../../../components/Button"
import { PasswordInput, TextInput } from "../../../components/Input"
import { LoadingDots } from "../../../components/Loading"
import { useAuth } from "../../../contexts/UserContext"
import { useWarnings } from "../../../contexts/WarningContext"
import { Dispatch, FormEvent, SetStateAction, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import api from "../../../services/api"
import "./Login.sass"

interface PasswordLoginProps {
  handleSubmit: (event: FormEvent) => void;
  login: string
  setLogin: Dispatch<SetStateAction<string>>
  password: string
  setPassword: Dispatch<SetStateAction<string>>
  serverError?: string[]
}


const PasswordLogin = ({ handleSubmit, login, setLogin, password, setPassword, serverError }: PasswordLoginProps) => {
  const { t } = useTranslation()

  return (
    <form onSubmit={handleSubmit}>
      <TextInput label={t('login.email')} value={login} setValue={setLogin} placeholder={t('login.placeholders.email')} errors={serverError} type="email" />
      <PasswordInput label={t('login.password')} value={password} setValue={setPassword} placeholder={t('login.placeholders.password')} errors={serverError} />

      <div className="form-functions">
        <PrimaryButton label={t('login.login')} type='submit' />
      </div>
    </form>
  )
}


export const AdminLogin = () => {
  const [navigating, setNavigating] = useState(false)
  const [lastUsername, setLastUsername] = useState<undefined | null | string>(null)

  const [login, setLogin] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const auth = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  const { setWarning } = useWarnings()
  const { t } = useTranslation()

  const state = location.state as { from: Location }
  const from = state ? state.from.pathname + state.from.search : '/admin/dashboard/'

  useEffect(() => {
    if (navigating) {
      // console.log("Auto login check cancelled: Already navigating")
      return
    }
    if (auth.user.firstName === lastUsername) {
      // console.log("Auto login check cancelled: Same username")
      return
    }
    setLastUsername(auth.user.firstName)

    if (auth.user.firstName) {
      // console.log('User was already set locally')
      // console.log('Auto nagivating to: ', from)
      setNavigating(true)
      navigate(from);
    }
    else {
      // console.log('User is not set locally. Checking with the server')
      setNavigating(true)
      auth.AdminCheckAuth()
        .then((response) => {
          if (response) {
            // console.log(response)
            // console.log('User is already authorized to the server')
            // console.log('Auto nagivating to: ', from)
            navigate(from)
          }
          else {
            // console.log('User is not authorized to the server')
            setNavigating(false)
          }
        })
    }
  }, [auth, navigate, from, navigating, lastUsername])

  const handleLogin = (event: FormEvent<Element>) => {
    event.preventDefault()
    setIsLoading(true)

    api.post('/api/admin/login', { "email": login, "password": password })
      .then(response => {
        auth.AdminCheckAuth()
      }, error => {
        setWarning({
          warning: t([`errors.${error.response.data.detail}`, "errors.undefined"]),
          type: "error"
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <div className="admin-container parent">
      <Background />

      <div className="content">
        <div className="flex h-20 w-20">
          <img src="/logo.png" alt="Logo" />
        </div>
        <PasswordLogin
          handleSubmit={handleLogin}
          login={login}
          setLogin={setLogin}
          password={password}
          setPassword={setPassword}
        />
      </div>

      {
        isLoading && (
          <div className="absolute z-30 flex w-full h-full items-center justify-center bg-gray-700 opacity-60">
            <LoadingDots />
          </div>
        )
      }
    </div >
  )
}