import { PrimaryButton } from "../../../components/Button"
import { Modal } from "../../../components/Modal"
import { useWarnings } from "../../../contexts/WarningContext"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { IoWarning } from "react-icons/io5"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../../../contexts/UserContext"
import { ParentRegisterGameUserFrame, ProfessionalRegisterGameUserFrame } from "../../../frames/RegisterGameUser"

export const RegisterGameUser = () => {
  const { user } = useAuth()

  return (
    <div className="flex items-center justify-center p-4 max-w-screen h-full">
      {user.type === 'professional' ? (
        <ProfessionalRegisterGameUserFrame />
      ) : (
        <ParentRegisterGameUserFrame />
      )}
    </div>
  )
}


export const WarningRegisterGameUser = () => {
  const navigate = useNavigate()
  const { setWarning } = useWarnings()
  const { t } = useTranslation()
  const [isEnabled, setIsEnabled] = useState(false)

  const dismiss = () => {
    localStorage.setItem("skip-game-user-creation", "true")
  }

  return (
    <div className="parent flex items-center justify-center p-4 max-w-screen h-full bg-gray-lightest">
      <ParentRegisterGameUserFrame>
        <button className="hover:underline text-sm text-gray-500" onClick={() => { setIsEnabled(true) }}>{t("gameUserRegistration.skip")}</button>
      </ParentRegisterGameUserFrame>

      <Modal show={isEnabled} onClose={() => { setIsEnabled(false) }}>
        <div className="flex flex-col w-full h-full p-10 items-center gap-5 text-center">
          <IoWarning className="w-20 h-20 text-orange-500" />
          <h1 className="text-3xl font-bold">{t('gameUserRegistration.modal.title')}</h1>
          <p className="text-lg">{t('gameUserRegistration.modal.register')}</p>

          <PrimaryButton onClick={() => { setIsEnabled(false) }}>{t('gameUserRegistration.register')}</PrimaryButton>
          <button className="hover:underline" onClick={() => { dismiss(); setWarning({ warning: t('gameUserRegistration.warning'), type: "warning" }); navigate('/dashboard'); }}>{t('gameUserRegistration.modal.skipAnyway')}</button>
        </div>
      </Modal>
    </div>

  )
}