import { PrimaryButton } from "../../../components/Button"
import { InviteProfessional } from "../../../components/InviteProfessional"
import { useModal } from "../../../contexts/ModalContext"
import { useAuth } from "../../../contexts/UserContext"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { IoCheckmark, IoMail, IoPerson, IoRemoveCircle } from "react-icons/io5"
import { Link } from "react-router-dom"
import api from "../../../services/api"

import "./ProfessionalUserCard.sass"


export interface ProfessionalUserFunctionProps {
  professional: {
    id: string
    email: string
    name: string
  }
  sent?: boolean
}

export interface LinkedProfessionalUserFunctionProps {
  professional: {
    id: string
    email: string
    name: string
  }
  gameUserID: string
}


export const ProfessionalUserFunctions = ({ professional, sent }: ProfessionalUserFunctionProps) => {
  const { t } = useTranslation()
  const { setModal } = useModal()
  const [confirm, setConfirm] = useState<boolean>(false)

  return (
    <div onClick={e => e.stopPropagation()} className="functions-container">

      <div className="profile">
        <div className="profile-pic">
          <IoPerson className='icon' />
          <img src={`/api/user/${professional.id}/profile_picture`} alt="" className="profile-icon" />
        </div>

        <p className="name">
          {professional.name}
        </p>
        <p className="email">
          {professional.email}
        </p>

      </div>

      <h1>{t("userActionModal.selectAnAction")}</h1>

      {/* Stats navigation button */}
      <Link to={`/dashboard/user/${professional.id}`} className="option">
        <p>{t('userActionModal.profile')}</p>
        <IoPerson className="icon" />
      </Link>

      {/* Stats navigation button */}

      {
        !sent ? (
          <button onClick={() => { setModal({ element: <InviteProfessional professional={professional} />, goBack: () => { setModal({ element: <ProfessionalUserFunctions professional={professional} /> }) } }) }} className="option">
            <p>{t('userActionModal.inviteProfessional')}</p>
            <IoMail className="icon" />
          </button>

        ) : confirm ? (
          <span className="option">
            <p>{t('userActionModal.areYouSure')}</p>
            <span className="w-full flex gap-4">
              <button type="button" onClick={() => { setConfirm(false) }} className="h-12 w-12 ">
                {t('userActionModal.cancel')}
              </button>
              <PrimaryButton type="button" onClick={() => { setModal({ element: <InviteProfessional professional={professional} />, goBack: () => { setModal({ element: <ProfessionalUserFunctions professional={professional} /> }) } }) }}>
                {t('userActionModal.send')}
              </PrimaryButton>

            </span>
          </span>
        ) : (
          <button onClick={() => { setConfirm(true) }} className="option sent">
            <p>{t('userActionModal.alreadySent')}</p>
            <IoCheckmark className="icon" />
          </button>
        )
      }
    </div>
  )
}



export const LinkedProfessionalUserFunctions = ({ professional, gameUserID }: LinkedProfessionalUserFunctionProps) => {
  const { t } = useTranslation()
  const { getGameUsers } = useAuth()
  const { closeModal } = useModal()
  const [confirm, setConfirm] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const unlinkUser = () => {
    setIsLoading(true)

    api.delete(`/api/user/unlink_professional/${gameUserID}`).then(
      response => {
        closeModal()
      },
      error => {

      }
    ).finally(() => {
      getGameUsers().finally(() => setIsLoading(false))
    })
  }

  return (
    <div onClick={e => e.stopPropagation()} className="functions-container">

      <div className="profile">
        <div className="profile-pic">
          <IoPerson className='icon' />
          <img src={`/api/user/${professional.id}/profile_picture`} alt="" className="profile-icon" />
        </div>

        <p className="name">
          {professional.name}
        </p>
        <p className="email">
          {professional.email}
        </p>

      </div>

      <h1>{t("userActionModal.selectAnAction")}</h1>

      {/* Stats navigation button */}
      <Link to={`/dashboard/user/${professional.id}`} className="option">
        <p>{t('userActionModal.profile')}</p>
        <IoPerson className="icon" />
      </Link>

      {/* Stats navigation button */}

      {confirm ? (
        <span className="option">
          <p>{t('userActionModal.unlinkAreYouSure')}</p>
          <span className="w-full flex gap-4">
            <button type="button" onClick={() => { setConfirm(false) }} className="h-12 w-12 ">
              {t('userActionModal.cancel')}
            </button>
            <PrimaryButton type="button" isLoading={isLoading} onClick={unlinkUser}>
              {t('userActionModal.unlink')}
            </PrimaryButton>

          </span>
        </span>
      ) : (
        <button onClick={() => { setConfirm(true) }} className="option">
          <p>{t('userActionModal.unlinkUser')}</p>
          <IoRemoveCircle className="icon" />
        </button>
      )
      }
    </div>
  )
}
