export const Divider = ({ ...props }) => {
  return (
    <div className="flex w-full flex-shrink-0 bg-gradient-to-r from-gray-gradientTransparent via-gray-gradient to-gray-gradientTransparent" style={{ height: '1px' }} {...props} >
    </div>
  )
}
export const VerticalDivider = ({ ...props }) => {
  return (
    <div className="flex h-full bg-gradient-to-r from-gray-gradientTransparent via-gray-gradient to-gray-gradientTransparent" style={{ width: '1px' }} {...props} >
    </div>
  )
}