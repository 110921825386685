import { useTranslation } from "react-i18next"
import "./RecentActivity.sass"

export interface RecentActivityProps {
  activity: {
    title: string
    subtitle?: string
    amount: number
    time: Date
    type: "income" | "outcome"
  }
}

export const RecentActivity = ({ activity, ...props }: RecentActivityProps) => {
  const { i18n } = useTranslation()

  return (
    <div className={`activity-card ${activity.type}`} {...props}>
      <h3>{activity.title}</h3>
      <p>{activity.time.getDate()}/{(activity.time.getMonth() + 1).toLocaleString(i18n.language, { minimumIntegerDigits: 2 })}/{activity.time.getFullYear()} {activity.time.getHours().toLocaleString(i18n.language, { minimumIntegerDigits: 2 })}:{activity.time.getMinutes().toLocaleString(i18n.language, { minimumIntegerDigits: 2 })}</p>
      <p>&#36;{activity.amount}</p>
    </div>
  )
}