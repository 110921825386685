import { DeleteButton, PrimaryButton } from "components/Button"
import { TextInput } from "components/Input"
import { useModal } from "contexts/ModalContext"
import { useAuth } from "contexts/UserContext"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import api from "services/api"
import "./DeleteAccount.sass"
import { useLocation, useNavigate } from "react-router-dom"

export function DeleteAccount() {
  const location = useLocation()
  const navigate = useNavigate()
  const { setModal } = useModal()
  const { t } = useTranslation()
  const { user } = useAuth()
  const [email, setEmail] = useState<string>("")
  const loggedIn = !(!user.firstName)

  useEffect(() => {
    console.log(loggedIn)
  }, [loggedIn])


  if (!loggedIn) {
    return (
      <div className="delete-account">
        <h3>{t("menus.titles.delete_account")}</h3>

        <p>{t('profile.cards.settings.deleteAccount.information')}</p>
        <p>{t('profile.cards.settings.deleteAccount.notLoggedIn')}</p>
        <PrimaryButton
          type="button"
          label={t("profile.cards.settings.deleteAccount.goToLogin")}
          onClick={e => { e.preventDefault(); e.stopPropagation(); navigate("/login", { state: { from: location } }) }}
        />
      </div>
    )
  }


  return (
    <div className="delete-account">
      <h3>{t("menus.titles.delete_account")}</h3>
      <p>{t('profile.cards.settings.deleteAccount.information')}</p>
      <TextInput
        label={t('profile.cards.settings.deleteAccount.confirmAction')}
        value={email}
        setValue={setEmail}
        placeholder={user.email}
        onPaste={e => { e.preventDefault(); setEmail(email) }}
        after={
          <p>{t('profile.cards.settings.deleteAccount.after')} <b>{user.email}</b> </p>
        }
      />
      <DeleteButton
        disabled={user.email !== email.trim()}
        label={t('profile.cards.settings.deleteAccount.delete')}
        onClick={() => { user.email === email.trim() && setModal({ element: <ConfirmModal /> }) }}
      />
    </div>
  )
}

function ConfirmModal() {
  const { Logout } = useAuth()
  const { t } = useTranslation()
  const { closeModal } = useModal()

  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  function handleSubmit() {
    setIsLoading(true)
    api.delete("/api/user").then(response => {
      if (response.status === 200) {
        Logout()
        closeModal()
      }
    }).finally(() => {
      setIsLoading(false)
    })
  }

  useEffect(() => {
    setTimeout(() => { setIsDisabled(false) }, 2000)
  })

  return (
    <div className="delete-account w-screen max-w-lg h-48 p-4 justify-between">
      <h3>{t("profile.cards.settings.deleteAccount.areYouSure")}</h3>
      <p>{t("profile.cards.settings.deleteAccount.irreversible")}</p>

      <DeleteButton
        disabled={isDisabled}
        label={t('profile.cards.settings.deleteAccount.delete')}
        onClick={handleSubmit}
        isLoading={isLoading}
      />
    </div>
  )
}
