import { useAuth } from "../../../../contexts/UserContext";
import { useTranslation } from "react-i18next";
import { IconType } from "react-icons";
import { IoAlarm, IoGameController, IoHome, IoShirt } from "react-icons/io5";
import { Link, Navigate, Outlet, To, useLocation, useParams } from "react-router-dom";
import "./GameSettings.sass";

interface GameSettingsProps { }

export const GameSettings = ({ ...props }: GameSettingsProps) => {
  const location = useLocation()
  const { t } = useTranslation()

  const state = location.state as { from: Location }
  const from = state ? state.from.pathname : '/dashboard/'

  const gameUserIndex = useParams().index
  const { gameUsers } = useAuth()


  if (gameUserIndex === undefined) {
    return <Navigate to={from} />
  }

  if (parseInt(gameUserIndex) > gameUsers.length) {
    return <Navigate to={from} />
  }

  return (
    <div className="game-settings">
      <h1>{t('gameUserProfile.settings.gameSettings.title')}</h1>
      <Outlet />

    </div>
  )
}

export const GameSettingsMenu = () => {
  const { t } = useTranslation()
  const { innerWidth } = window

  if (innerWidth >= 1024) {
    return <Navigate to="game_areas" />
  }

  return (
    <div className="game-settings-menu">
      <GameSettingsMenuItem
        title={t("gameUserProfile.settings.gameSettings.gameAreas")}
        Icon={IoHome}
        to="game_areas"
      />
      <GameSettingsMenuItem
        title={t("gameUserProfile.settings.gameSettings.gameActivities")}
        Icon={IoGameController}
        to="game_activities"
      />
      <GameSettingsMenuItem
        title={t("gameUserProfile.settings.gameClothes.title")}
        Icon={IoShirt}
        to="game_clothes"
      />
      <GameSettingsMenuItem
        title={t("gameUserProfile.settings.gameTime.title")}
        Icon={IoAlarm}
        to="game_time"
      />
    </div>
  )
}

export const GameSettingsMenuItem = ({ title, Icon, to }: { title: string, Icon: IconType, to: To }) => {
  return (
    <Link role="link" to={to} className="menu-item">
      <Icon className="icon" />
      <h2>{title}</h2>
    </Link>
  )
}