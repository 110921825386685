import { Dispatch, ReactNode, SetStateAction, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";

export interface DropdownProps<OptionType> {
  label?: string
  options: {
    label: string,
    value: OptionType,
    background?: string,
    icon?: ReactNode
  }[]
  selectedOption?: OptionType
  setOption: Dispatch<SetStateAction<OptionType | undefined>> | ((value: OptionType | undefined) => void)
  disabled?: boolean
}

export function Dropdown<Type>({ label = undefined, options, setOption, selectedOption, ...props }: DropdownProps<Type>) {
  const [isOpen, setIsOpen] = useState(false)
  const currentOption: { label: string, value: Type } | undefined = useMemo(() => (
    selectedOption ? options.find((current) => (current.value === selectedOption)) : undefined
  ), [selectedOption, options])
  const { t } = useTranslation()

  return (
    <div className="flex py-7" {...props}>
      {label ? <p className="mr-2 flex items-center font-semibold">{label}: </p> : <></>}
      <div className="flex flex-col bg-white h-8 w-40 rounded shadow text-sm divide-y relative " >
        <button className="flex items-center justify-between py-0 px-2 h-8" onClick={() => setIsOpen(!isOpen)}>
          <p className="text-ellipsis overflow-hidden whitespace-nowrap">
            {currentOption ? currentOption.label : t("dropdown.notSelected")}
          </p>
          {!isOpen ? IoChevronDown({ className: "text-gray-700 w-6 h-6 flex shrink-0" }) : IoChevronUp({ className: "text-gray-700 w-6 h-6 flex shrink-0" })}
        </button>
        <div className="flex flex-col absolute top-8 w-40 max-h-40 overflow-y-auto z-10">
          <button className={`flex items-center bg-white justify-between py-0 px-2 h-8 hover:bg-gray-100 transition ${isOpen ? '' : 'hidden'}  shrink-0`} onClick={() => { setOption(undefined); setIsOpen(false) }}>
            <p>{t("dropdown.select")}</p>
          </button>
          {
            options.map((option, index) => {
              return (
                <button key={option.label + index} className={`flex items-center bg-white justify-between py-0 px-2 h-8 hover:bg-gray-100 transition ${isOpen ? '' : 'hidden'} shrink-0 `} onClick={() => { setOption(option.value); setIsOpen(false) }}>
                  <p className="text-ellipsis overflow-hidden whitespace-nowrap">{option.label}</p>
                </button>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}


export function DropdownInput<Type>({ label = undefined, options, setOption, selectedOption, disabled, ...props }: DropdownProps<Type>) {
  const [isOpen, setIsOpen] = useState(false)

  const currentOption: { label: string, value: Type, icon?: ReactNode, background?: string } | undefined = useMemo(() => (
    selectedOption !== undefined ? options.find((current) => (current.value === selectedOption)) : undefined
  ), [selectedOption, options])

  const { t } = useTranslation()

  return (
    <div className="rounded-xl w-full" {...props}>
      {label ? <p className="mr-2 flex items-center font-semibold">{label}: </p> : <></>}
      <div className="flex flex-col bg-white h-14 w-full rounded-xl text-sm divide-y relative border-solid border-2 border-gray-200" >
        <button className={`flex items-center justify-between py-0 px-2 h-full w-full ${!disabled ? "cursor-pointer" : "cursor-not-allowed"}`} onClick={e => { e.preventDefault(); e.stopPropagation(); !disabled && setIsOpen(!isOpen) }} type="button">
          {
            (currentOption && ("icon" in currentOption)) && (
              <div className="w-14 h-14 p-4 flex items-center justify-center">
                {currentOption.icon}
              </div>
            )
          }
          <p className="text-ellipsis overflow-hidden whitespace-nowrap h-14 w-full flex items-center justify-start">
            {currentOption !== undefined ? currentOption.label : t("dropdown.notSelected")}
          </p>
          {!disabled && (!isOpen ? IoChevronDown({ className: "text-gray-700 w-6 h-6 flex shrink-0" }) : IoChevronUp({ className: "text-gray-700 w-6 h-6 flex shrink-0" }))}
        </button>
        <div className={`flex flex-col absolute top-14 w-full max-h-72 overflow-y-auto z-10 rounded-xl shadow ${isOpen ? '' : 'hidden'}`}>
          <button className={`flex items-center bg-white justify-between py-0 px-2 h-14 hover:bg-gray-100 transition   shrink-0`} onClick={e => { e.preventDefault(); e.stopPropagation(); setOption(undefined); setIsOpen(false) }}>
            <p>{t("dropdown.select")}</p>
          </button>
          {
            options.map((option, index) => {
              return (
                <button key={option.label + index} className={`flex items-center ${"background" in option ? option.background : "bg-white"}  py-0 px-2 h-14 w-full hover:brightness-90 filter transition ${isOpen ? '' : 'hidden'} shrink-0 `} type="button" onClick={e => { e.preventDefault(); e.stopPropagation(); setOption(option.value); setIsOpen(false) }}>
                  {
                    ("icon" in option) && (
                      <div className="w-14 h-14 p-4 flex items-center justify-center">
                        {option.icon}
                      </div>
                    )
                  }
                  <p className="text-ellipsis overflow-hidden whitespace-nowrap">{option.label}</p>
                </button>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}


export const ColorDropdown = ({ label = undefined, options, setOption, selectedOption, ...props }: DropdownProps<string>) => {
  const [isOpen, setIsOpen] = useState(false)

  const { t } = useTranslation()

  return (
    <div className="flex py-7" {...props}>
      {label ? <p className="mr-2 flex items-center font-semibold">{label}: </p> : <></>}
      <div className="flex flex-col bg-white h-8 w-40 rounded shadow text-sm divide-y relative " >
        <button className="flex items-center justify-between py-0 px-2 h-8" type="button" onClick={(e) => { e.preventDefault(); e.stopPropagation(); setIsOpen(!isOpen) }}>
          <p className="text-ellipsis overflow-hidden whitespace-nowrap w-full h-full flex items-center" style={{ backgroundColor: selectedOption }}>
            {selectedOption ? (<></>) : (<>{t("dropdown.notSelected")}</>)}
          </p>
          {!isOpen ? IoChevronDown({ className: "text-gray-700 w-6 h-6 flex shrink-0" }) : IoChevronUp({ className: "text-gray-700 w-6 h-6 flex shrink-0" })}
        </button>
        <div className="flex flex-col absolute top-8 w-40 max-h-40 overflow-y-auto z-10">
          <button className={`flex items-center bg-white justify-between py-0 px-2 h-8 hover:bg-gray-100 transition ${isOpen ? '' : 'hidden'}  shrink-0`} type="button" onClick={(e) => { e.preventDefault(); e.stopPropagation(); setOption(undefined); setIsOpen(false) }}>
            <p>{t('dropdown.select')}</p>
          </button>
          {
            options.map(option => {
              return (
                <button key={option.label} className={`flex items-center bg-white justify-between py-0 px-2 h-8 hover:bg-gray-100 transition ${isOpen ? '' : 'hidden'} shrink-0 `} type="button" onClick={(e) => { e.preventDefault(); e.stopPropagation(); setOption(option.value); setIsOpen(false) }}>
                  <span className="w-full h-full" style={{ backgroundColor: option.value }} />
                </button>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}