import { DeleteButton } from '../../../../../components/Button'
import { FullLoadingDots } from '../../../../../components/Loading'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import api from '../../../../../services/api'
import { TextInput } from '../../../../../components/Input'
import { useAuth } from '../../../../../contexts/UserContext'
import './UnlinkGameUserProfile.sass'
import { useModal } from 'contexts/ModalContext'

export const UnlinkGameUserProfile = () => {
  const { setModal } = useModal()
  const { gameUsers, isGameUsersLoading } = useAuth()
  const { t } = useTranslation()
  const gameUserIndex = useParams().index
  const [name, setName] = useState<string>("")

  if (gameUserIndex === undefined) {
    return <Navigate to="/dashboard/game_user/" />
  }

  if (isGameUsersLoading) {
    return <FullLoadingDots />
  }

  if (parseInt(gameUserIndex) >= gameUsers.length) {
    return <Navigate to="/dashboard/game_user/" />
  }



  return (
    <div className="personal-settings">
      <div className="data">
        <h3>{t('gameUserProfile.settings.profileSettings.unlinkGameUser.title')}</h3>
        <TextInput
          label={t('gameUserProfile.settings.profileSettings.unlinkGameUser.confirmAction')}
          value={name}
          setValue={setName}
          onPaste={e => { e.preventDefault(); setName(name) }}
          placeholder={gameUsers[parseInt(gameUserIndex)].name}
          after={
            <p>{t('gameUserProfile.settings.profileSettings.unlinkGameUser.after')} <b>{gameUsers[parseInt(gameUserIndex)].name}</b> </p>
          }
        />
        <DeleteButton
          disabled={gameUsers[parseInt(gameUserIndex)].name !== name.trim()}
          label={t('gameUserProfile.settings.profileSettings.unlinkGameUser.unlink')}
          onClick={() => gameUsers[parseInt(gameUserIndex)].name === name.trim() && setModal({ element: <ConfirmModal gameUserIndex={parseInt(gameUserIndex)} /> })}
        />
      </div>
    </div>
  )
}


function ConfirmModal({ gameUserIndex }: { gameUserIndex: number }) {
  const { gameUsers, getGameUsers } = useAuth()
  const { closeModal } = useModal()
  const navigate = useNavigate()

  const { t } = useTranslation()

  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleSubmit = useCallback(() => {
    if (isDisabled) return;
    setIsLoading(true)

    api.delete(`/api/user/unlink_professional/${gameUsers[gameUserIndex].id}`).then(
      response => {
        getGameUsers().then(() => navigate("/dashboard/game_user"))
      },
      errors => { }
    ).finally(() => {
      closeModal()
      setIsLoading(false)
    })
  }, [gameUsers, closeModal, gameUserIndex, getGameUsers, navigate, isDisabled])

  useEffect(() => {
    setTimeout(() => { setIsDisabled(false) }, 2000)
  })

  return (
    <div className="delete-account w-screen max-w-lg h-48 p-4 justify-between">
      <h3>{t("profile.cards.settings.deleteAccount.areYouSure")}</h3>
      <p>{t('gameUserProfile.settings.profileSettings.unlinkGameUser.areYouSure')}</p>

      <DeleteButton
        disabled={isDisabled}
        label={t('gameUserProfile.settings.profileSettings.unlinkGameUser.confirmAction')}
        onClick={handleSubmit}
        isLoading={isLoading}
      />
    </div>
  )
}
