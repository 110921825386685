import { WhiteLoadingRing } from "../../../../components/Loading"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useWarnings } from "../../../../contexts/WarningContext"
import { AdminUserCardSmall } from "../../../../frames/UserCard"
import api from "../../../../services/api"
import "./AdminPendingProfessional.sass"

interface PendingProfessionals {
  id: string
  email: string
  first_name: string
  last_name: string
  is_verified: boolean
  is_public: boolean
  type: "professional"
  professional_status: "UNVERIFIED" | "ANALYSIS" | "RESEND" | "DENIED" | "ACCEPTED"
}

function checkPendingProfessional() {
  return api.get<PendingProfessionals[]>("/api/admin/pending_validations").then(
    response => {
      return response.data
    },
    error => {
      throw new Error("error in retrieving")
    }
  )
}

export const AdminPendingProfessional = () => {
  const { t } = useTranslation()
  const { setWarning } = useWarnings()
  const [pendingProfessionals, setPendingProfessionals] = useState<PendingProfessionals[]>()

  useEffect(() => {
    if (pendingProfessionals !== undefined) return;
    checkPendingProfessional().then(response => {
      setPendingProfessionals([...response])
    }).catch(error => {
      setWarning({ warning: t("professional.validation.failedRetrieving"), type: "error" })
    })
  }, [pendingProfessionals, setWarning, t])



  return (
    <div className="pending-professional">
      <div className="title">
        <h1>{t("menus.titles.validation")}</h1>
      </div>
      <div className="content">
        {
          pendingProfessionals !== undefined ? (
            pendingProfessionals.length > 0 ? (
              pendingProfessionals.map(professional => {
                return (
                  <AdminUserCardSmall
                    user={{
                      id: professional.id,
                      firstName: professional.first_name,
                      lastName: professional.last_name,
                      isEmailVerified: professional.is_verified,
                      type: "professional",
                      email: professional.email,
                      professionalStatus: professional.professional_status,
                      isPublic: professional.is_public
                    }}
                    link={`/admin/dashboard/validation/${professional.id}`}
                    key={professional.id}
                  />
                )
              })

            ) : (
              <p>{t("professional.validation.noPendingProfessionals")}</p>
            )
          ) : (
            <WhiteLoadingRing />
          )
        }
      </div>
    </div>
  )
}