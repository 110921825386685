import { useAuth } from "../../../contexts/UserContext"
import { useTranslation } from "react-i18next"
import { Navigate } from "react-router-dom"
import "../Professional.sass"
import { APIValidation, ValidationInfo } from "pages/Admin/Validation/ValidationFunctions"
import { useImmer } from "use-immer"
import { useEffect, useState } from "react"
import api from "services/api"
import { useWindowDimensions } from "utils/WindowDimensions"
import { IoClose, IoInformation } from "react-icons/io5"

interface DeniedInterface {
  validations: APIValidation[]
}


function MobileDenied({ validations }: DeniedInterface) {
  const { t } = useTranslation()
  const [isVisible, setIsVisible] = useState(true)

  return (
    <div className="verify-container mobile">
      <button onClick={e => { e.preventDefault(); e.stopPropagation(); setIsVisible(true) }} className="information" type="button">
        <IoInformation />
      </button>

      <div className="warning">
        <h1>
          {t('professional.denied.title')}
        </h1>

        <p>
          {t('professional.denied.subtitle')}
        </p>
      </div>


      {
        isVisible && (
          <div onClick={e => { e.preventDefault(); e.stopPropagation(); setIsVisible(false) }} className="z-10 w-screen h-screen flex justify-end absolute top-0 left-0 animate-smooth-in bg-gray-700 bg-opacity-80 cursor-pointer">
            <button onClick={e => { e.preventDefault(); e.stopPropagation(); setIsVisible(false) }} className="z-10 absolute flex items-center justify-center top-4 right-4 w-6 h-6 text-gray-700" type="button">
              <IoClose className="text-gray-700" />
            </button>
            <div onClick={e => { e.preventDefault(); e.stopPropagation() }} className="flex justify-center h-full w-full max-w-lg animate-right-slide-in bg-white p-4 cursor-default">
              {validations.length > 0 && <ValidationInfo validation={validations[0]} />}
            </div>
            <div className="content">
              {/* We must add a list with needed documents */}
            </div>
          </div>
        )
      }
    </div>
  )
}

function WebDenied({ validations }: DeniedInterface) {
  const { t } = useTranslation()
  return (
    <div className="verify-container">
      <div className="left-container">
        <div className="warning">
          <h1>
            {t('professional.denied.title')}
          </h1>

          <p>
            {t('professional.denied.subtitle')}
          </p>
        </div>
      </div>
      <div className="right-container">
        {validations.length > 0 && <ValidationInfo validation={validations[0]} />}
      </div>
    </div>
  )
}

export const Denied = () => {
  const { width } = useWindowDimensions()
  const { user } = useAuth()
  const [validations, setValidations] = useImmer<APIValidation[]>([])

  useEffect(() => {
    api.get<APIValidation[]>("/api/user/professional/validation").then(
      response => {
        setValidations([...response.data])
      },
      error => {

      }
    )

  }, [user, setValidations])

  if (user.type === "parent" || (user.type === "professional" && user.professionalStatus !== "DENIED")) {
    return (
      <Navigate to="/dashboard" />
    )
  }


  return width >= 1024 ? (
    <WebDenied
      validations={validations}
    />
  ) : (
    <MobileDenied
      validations={validations}
    />
  )
}