import ReactEChartsCore from 'echarts-for-react/lib/core';
import { useMemo } from "react";
import { TFunction } from 'react-i18next';
import { echarts } from ".";


interface BarChartData {
  title: string
  data: {
    labels: string[],
    series: { name: string, values: number[] }[]
  }
  xAxisLabel?: string
  yAxisLabel?: string
  t: TFunction
  options?: () => void
}

export const BarChart = ({ title, data, xAxisLabel, yAxisLabel, t, options }: BarChartData) => {

  const legend: string[] = useMemo(() => [], [])
  const series = data.series.map((activity) => {
    legend.push(activity.name)
    return {
      type: "bar",
      data: [...activity.values],
      name: activity.name
    }
  })

  const toolboxFeatures = useMemo(() => ({
    dataZoom: {
      yAxisIndex: 'none'
    },
    restore: {},
    saveAsImage: {},
    myFeature: options ? {
      show: true,
      title: 'Options',
      icon: 'path://"M3.968,12.061C1.775,12.061,0,13.835,0,16.027c0,2.192,1.773,3.967,3.968,3.967c2.189,0,3.966-1.772,3.966-3.967 C7.934,13.835,6.157,12.061,3.968,12.061z M16.233,12.061c-2.188,0-3.968,1.773-3.968,3.965c0,2.192,1.778,3.967,3.968,3.967 s3.97-1.772,3.97-3.967C20.201,13.835,18.423,12.061,16.233,12.061z M28.09,12.061c-2.192,0-3.969,1.774-3.969,3.967 c0,2.19,1.774,3.965,3.969,3.965c2.188,0,3.965-1.772,3.965-3.965S30.278,12.061,28.09,12.061z"',
      onclick: options
    } : {}
  }), [options])

  const option = useMemo(() => ({
    title: {
      text: title,
    },
    tooltip: {
      trigger: 'item',
      position: function (pt: [number, number]) {
        return [pt[0], '10%'];
      }
    },
    legend: {
      top: "28px",
      margin: "50px",
      data: legend,
      type: 'scroll'

    },
    toolbox: {
      feature: toolboxFeatures,
    },
    xAxis: {
      type: 'category',
      data: data.labels,
      name: xAxisLabel
    },
    yAxis: {
      type: 'value',
      name: yAxisLabel,
      nameLocation: "center",
      nameGap: 30

    },
    dataZoom: [
      {
        type: 'slider',
        span: 1,
        endValue: data.labels.length - 1

      },
    ],
    series: series
  }), [data, legend, series, title, xAxisLabel, yAxisLabel, toolboxFeatures])


  if (data.series.length <= 0) {
    return (
      <div className="w-full h-full flex items-center justify-center p-2 bg-white rounded-xl shadow-xl">
        {t("dashboard.errors.chartNoData")}
      </div>
    )
  }

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={option}
      notMerge={true}
      lazyUpdate={true}
      theme={"toyt"}
      className="w-full h-full p-2 bg-white rounded-xl shadow-xl"
    />
  )
}