
import { PrimaryButton, SecondaryButton } from "components/Button";
import { useModal } from "contexts/ModalContext";
import { useAuth } from "contexts/UserContext";
import { useWarnings } from "contexts/WarningContext";
import { SelectableGameUserCardSmall } from "frames/UserCard";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../GiftCodeInfo.sass";
import api from "services/api";


export function ActivateGameUsers({ maxGameUsers }: { maxGameUsers: number }) {
  const { t } = useTranslation()
  const { gameUsers } = useAuth()
  const { setWarning } = useWarnings()
  const { closeModal } = useModal()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedPlayers, setSelectedPlayers] = useState<number[]>([...gameUsers.map((gameUser, index) => gameUser.is_active ? index : -1).filter(index => index !== -1)])


  const handleActivation = () => {
    if (selectedPlayers.length <= 0) {
      setWarning({ warning: t("profile.cards.settings.giftCode.selectPlayers.selectOne"), type: "warning" })
      return
    }
    if (selectedPlayers.length > maxGameUsers) {
      setWarning({ warning: t("profile.cards.settings.giftCode.selectPlayers.maxPlayersExceeded"), type: "warning" })
      return
    }
    setIsLoading(true)
    Promise.all(selectedPlayers.map(async (gameUserIndex) => {
      return await api.put('/api/user/game_user/activate', {
        is_active: true,
        game_user_id: gameUsers[gameUserIndex].id
      })
    })).then(() => {
      setWarning({ warning: t("profile.cards.settings.giftCode.selectPlayers.successfullyActivated"), type: "success" })
    }).finally(() => {
      setWarning({ warning: t("profile.cards.settings.giftCode.selectPlayers.error"), type: "success" })
      closeModal()
      setIsLoading(false)
    })
  }

  useEffect(() => {
    console.log(selectedPlayers)
  }, [selectedPlayers])

  return (
    <div className="gift-code-info-container">
      <h3>{t("profile.cards.settings.giftCode.selectPlayers.title")}</h3>
      <p className="flex px-6 w-full">{t("profile.cards.settings.giftCode.gameUserExplanation")}</p>
      <div className="w-full max-h-80 overflow-y-auto px-6">
        {
          gameUsers.map((gameUser, index) => (
            <SelectableGameUserCardSmall gameUser={gameUser} isSelected={(selectedPlayers.includes(index))} onClick={() => { (selectedPlayers.length < maxGameUsers || selectedPlayers.includes(index)) ? (setSelectedPlayers(selectedPlayers => selectedPlayers.includes(index) ? selectedPlayers.filter(gameUserIndex => gameUserIndex !== index) : [...selectedPlayers, index])) : (setWarning({ warning: t("profile.cards.settings.giftCode.selectPlayers.maxPlayersWarning"), type: "warning" })) }} />
          ))
        }
      </div>
      <PrimaryButton onClick={handleActivation} label={selectedPlayers.length > 0 ? t("profile.cards.settings.giftCode.selectPlayers.confirmSelection") : t("profile.cards.settings.giftCode.selectPlayers.selectOne")} isLoading={isLoading} disabled={selectedPlayers.length <= 0} />
      <button className="hover:underline text-gray-700" onClick={() => { setWarning({ warning: <>{t('profile.cards.settings.giftCode.selectPlayers.laterWarning')} <SecondaryButton onClick={() => { closeModal() }}>{t('profile.cards.settings.giftCode.selectPlayers.gameUserPage')}</SecondaryButton></>, type: "warning" }); closeModal() }}>{t('profile.cards.settings.giftCode.selectPlayers.activateLater')}</button>
    </div>
  )
}