import { TFunction } from 'react-i18next';
import { GameUserData, GameUserData_item, GameUserData_item_array } from '../contexts/UserContext';


interface GameSessionChartDataProps {
  data: GameUserData_item[]
  t: TFunction<"translation", undefined>
}
interface ChartDataProps {
  data: GameUserData_item_array
  t: TFunction<"translation", undefined>
}

interface DatasetsData {
  labels: string[],
  series: {
    name: string,
    values: number[]
  }[],
  totalValue: number,
  amount: number
  unitOfMeasurement: "seconds" | "minutes" | "hours" | "days"
}

const timestampOneDay = 3600 * 1000 * 24
const unitOfMeasurement = {
  "seconds": 1,
  "minutes": 60,
  "hours": 60 * 60,
  "days": 60 * 60 * 24
}



export const AverageGameSessionDuration = ({ data }: { data: GameUserData_item[] }) => {
  if (data.length <= 0) return 0;

  const totalDuration = data.reduce((prev, current) => (
    current.value + prev
  ), 0)

  return totalDuration / data.length
}


export const LineChartData = ({ data, t }: ChartDataProps): DatasetsData => {
  let highestValue = 0
  let denominator: "days" | "hours" | "minutes" | "seconds" = "seconds"

  const activityDateData: {
    [key: number]: { // activity id
      [key: string]: number // day = value for that activity in that day
    }
  } = {}
  const series: { values: number[], name: string }[] = []
  let totalValue = 0;
  let amount = 0;

  let oldestDate: Date = new Date(8.64e15)
  let latestDate: Date = new Date(0)

  const totalDays: string[] = []
  const activityIDs: number[] = Object.keys(data).map(key => Number(key))

  if (activityIDs.length <= 0) return {
    labels: [],
    series: [],
    totalValue: totalValue,
    amount: amount,
    unitOfMeasurement: "seconds"
  }


  activityIDs.forEach(activityID => {
    if (!(activityID in activityDateData)) {
      activityDateData[activityID] = {}
    }

    data[activityID].forEach(activitySessionData => {
      const date = new Date(activitySessionData.day)
      totalValue += activitySessionData.value
      amount++
      if (date > latestDate) {
        latestDate = date
      }
      if (date < oldestDate) {
        oldestDate = date
      }
    })
  })

  for (let date = oldestDate; date <= latestDate; date = new Date(date.getTime() + timestampOneDay)) {
    totalDays.push(t(['intlDateTime', date.toLocaleDateString()],
      {
        val: date,
        formatParams: {
          val: { year: 'numeric', month: 'numeric', day: 'numeric' },
        }
      }).replaceAll("&#x2F;", "/"))
  }

  // update value for each date
  activityIDs.forEach(id => {
    const activityDays: string[] = []
    data[id].forEach(activity => { // Iterate through all registers of this activity
      const date = new Date(activity.day)
      // Format register date to local pattern to be used as object key
      const day = t(['intlDateTime', date.toLocaleDateString()],
        {
          val: date,
          formatParams: {
            val: { year: 'numeric', month: 'numeric', day: 'numeric' },
          }
        }).replaceAll("&#x2F;", "/")

      // Checks if it was already summed to day total on that activity
      if (!(day in activityDateData[id])) {
        activityDateData[id][day] = 0
        activityDays.push(day)
      }

      activityDateData[id][day] += activity.value
      highestValue = highestValue ? activityDateData[id][day] : highestValue
    })
    denominator = highestValue > 60 * 60 * 24 ? "days" : highestValue > 60 * 60 ? "hours" : highestValue > 60 ? "minutes" : "seconds"
    const values: number[] = new Array(totalDays.length).fill(0)
    activityDays.forEach(day => {
      const valueIndex = totalDays.indexOf(day)
      values[valueIndex] = parseFloat((activityDateData[id][day] / unitOfMeasurement[denominator]).toFixed(2))
    })



    const activity: {
      values: number[],
      name: string
    } = {
      name: t(`activity.${id}`),
      values: values
    }
    series.push(activity)
  })

  return {
    series: series,
    labels: totalDays,
    totalValue: totalValue,
    amount: amount,
    unitOfMeasurement: denominator
  }
}


export const GameSessionChartData = ({ data, t }: GameSessionChartDataProps) => {
  let totalValue = 0
  const totalValueObj = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  }
  const averageValueObj = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  }
  const dayValues: { [key: string]: number } = {}
  let oldestDate: Date = new Date(8.64e15)
  let latestDate: Date = new Date(0)
  const dateKeys: string[] = []


  if (!data) {
    return ({
      series: [],
      labels: [],
      totalValue: totalValue,
      totalValueObj: totalValueObj,
      averageValue: 0,
      averageValueObj: averageValueObj,
      unitOfMeasurement: "seconds"

    })
  }

  data.forEach((session_info) => {
    const date = new Date(session_info.day)
    if (date > latestDate) {
      latestDate = date
    }
    if (date < oldestDate) {
      oldestDate = date
    }

    const formattedDate = t(['intlDateTime', date.toLocaleDateString()],
      {
        val: date,
        formatParams: {
          val: { year: 'numeric', month: 'numeric', day: 'numeric' },
        }
      }).replaceAll("&#x2F;", "/")

    if (!(formattedDate in dayValues)) {
      dayValues[formattedDate] = 0
    }
    dayValues[formattedDate] += session_info.value
  })

  for (let date = oldestDate; date <= latestDate; date = new Date(date.getTime() + timestampOneDay)) {
    dateKeys.push(t(['intlDateTime', date.toLocaleDateString()],
      {
        val: date,
        formatParams: {
          val: { year: 'numeric', month: 'numeric', day: 'numeric' },
        }
      }).replaceAll("&#x2F;", "/"))
  }

  const datasetsData = dateKeys.map(key => {
    if (!(key in dayValues)) return 0;

    totalValue += dayValues[key]
    return dayValues[key]
  })

  const highestValue = Math.max(...datasetsData)
  const denominator = highestValue > 60 * 60 * 24 ? "days" : highestValue > 60 * 60 ? "hours" : highestValue > 60 ? "minutes" : "seconds"

  const datasets: DatasetsData["series"] = [{
    name: t('dashboard.charts.gameTime.title'),
    values: datasetsData.map(value => {
      return parseFloat((value / unitOfMeasurement[denominator]).toFixed(2))
    })
  }]

  const averageValue = AverageGameSessionDuration({ data })

  totalValueObj['days'] = totalValue >= (60 * 60 * 24) ? Math.floor(totalValue / (60 * 60 * 24)) : 0
  totalValueObj['hours'] = totalValue >= (60 * 60) ? Math.floor((totalValue - totalValueObj['days'] * (60 * 60 * 24)) / (60 * 60)) : 0
  totalValueObj['minutes'] = totalValue >= 60 ? Math.floor((totalValue - (totalValueObj['days'] * 60 * 60 * 24) - (totalValueObj['hours'] * 60 * 60)) / 60) : 0
  totalValueObj['seconds'] = Math.floor((totalValue - ((totalValueObj['days'] * 60 * 60 * 24)) - (totalValueObj['hours'] * 60 * 60) - (totalValueObj['minutes'] * 60)))

  averageValueObj['days'] = averageValue >= (60 * 60 * 24) ? Math.floor(averageValue / (60 * 60 * 24)) : 0
  averageValueObj['hours'] = averageValue >= (60 * 60) ? Math.floor((averageValue - averageValueObj['days'] * (60 * 60 * 24)) / (60 * 60)) : 0
  averageValueObj['minutes'] = averageValue >= 60 ? Math.floor((averageValue - (averageValueObj['days'] * 60 * 60 * 24) - (averageValueObj['hours'] * 60 * 60)) / 60) : 0
  averageValueObj['seconds'] = Math.floor((averageValue - averageValueObj['days'] * (60 * 60 * 24) - averageValueObj['hours'] * 60 * 60 - averageValueObj['minutes'] * 60))


  return ({
    series: datasets,
    labels: dateKeys,
    totalValue: totalValue,
    totalValueObj: totalValueObj,
    averageValue: averageValue,
    averageValueObj: averageValueObj,
    unitOfMeasurement: denominator
  })
}

export const allUsersData = ({ data }: { data: GameUserData[] }) => {
  const GameData: Omit<GameUserData, "max_game_time"> = {
    "game_session_duration": [],
    "activity_session_duration": {},
    "user_precision": {},
    "user_response": {},
    "user_errors": {},
    "transactions": [],
    "spent_time": 0
  }

  data.forEach(data => {
    GameData['game_session_duration'] = GameData['game_session_duration'].concat(data['game_session_duration'])

    const activitySessionKeys = Object.keys(data['activity_session_duration'])
    const userPrecisionKeys = Object.keys(data['user_precision'])
    const userResponseKeys = Object.keys(data['user_response'])
    const userErrorKeys = Object.keys(data['user_errors'])

    activitySessionKeys.forEach(key => {
      if (!(key in GameData['activity_session_duration'])) {
        GameData['activity_session_duration'][key] = []
      }

      GameData['activity_session_duration'][key].push(...data['activity_session_duration'][key])
      GameData['activity_session_duration'][key].sort((a, b) => {
        return sortingFunction(a, b)
      })

    })

    userPrecisionKeys.forEach(key => {
      if (!(key in GameData['user_precision'])) {
        GameData['user_precision'][key] = []
      }

      GameData['user_precision'][key].push(...data['user_precision'][key])
      GameData['user_precision'][key].sort((a, b) => {
        return sortingFunction(a, b)
      })

    })

    userResponseKeys.forEach(key => {
      if (!(key in GameData['user_response'])) {
        GameData['user_response'][key] = []
      }

      GameData['user_response'][key].push(...data['user_response'][key])
      GameData['user_response'][key].sort((a, b) => {
        return sortingFunction(a, b)
      })

    })

    userErrorKeys.forEach(key => {
      if (!(key in GameData['user_errors'])) {
        GameData['user_errors'][key] = []
      }

      GameData['user_errors'][key].push(...data['user_errors'][key])
      GameData['user_errors'][key].sort((a, b) => {
        return sortingFunction(a, b)
      })
    })


  })

  GameData.game_session_duration.sort((a, b) => {
    return sortingFunction(a, b)
  })

  return { GameData }
}

function sortingFunction(a: GameUserData_item, b: GameUserData_item): number {
  const aDate = (new Date(a.day)).getTime()
  const bDate = (new Date(b.day)).getTime()

  if (aDate > bDate) return 1;
  if (aDate < bDate) return -1;
  return 0;
}