import { useTranslation } from "react-i18next"
import { FiArrowRight } from "react-icons/fi"
import { useNavigate } from "react-router"
import { useAuth } from "../../contexts/UserContext"

export const NoUser = () => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <div className="flex w-full h-full items-center justify-center">
      <div className="flex flex-col items-center justify-evenly text-center bg-white rounded-lg shadow-lg h-48 w-80 p-4">
        <h2 className="font-semibold">{t('proNoUser.title')}</h2>
        {
          user.type === 'parent' ? (
            <button onClick={() => navigate('/dashboard/game_user/register')} className="flex rounded-full items-center justify-evenly  p-4 m-2 bg-gray-800 text-white hover:bg-gray-900 transition" >
              <p>{t('proNoUser.createUser')}</p>
              <FiArrowRight />
            </button>
          ) : (
            <>
              <p>{t('proNoUser.checkInvites')}</p>
            </>
          )
        }

      </div>
    </div>
  )
}