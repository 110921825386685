import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react"
import { Modal } from '../components/Modal'

interface SetModalData {
  element: ReactNode
  userType?: "parent" | "professional"
  goBack?: () => void
  onClose?: () => void
}

export interface ModalContextData {
  setModal: ({ element, userType }: SetModalData) => void
  closeModal: () => void,
  setOnModalClose: Dispatch<SetStateAction<undefined | (() => void)>>
}

interface ModalProviderData {
  children: ReactNode
}

export const ModalContext = createContext({} as ModalContextData)

export const ModalProvider = ({ children, ...args }: ModalProviderData) => {
  const [element, setElement] = useState<ReactNode>()
  const [userType, setUserType] = useState<"parent" | "professional">("parent")
  const [show, setShow] = useState<boolean>(false)
  const [onModalClose, setOnModalClose] = useState<undefined | (() => void)>()
  const [backModal, setBackModal] = useState<undefined | (() => void)>()


  const setModal = ({ element, userType = "parent", goBack, onClose }: SetModalData) => {
    setBackModal(() => (goBack))
    setElement(element)
    setShow(true)
    setUserType(userType)
    onClose !== undefined && setOnModalClose(() => onClose)
  }

  const closeModal = () => {
    setElement(<></>)
    setShow(false)
    onModalClose !== undefined && onModalClose()
    setOnModalClose(undefined)
  }

  return (
    <ModalContext.Provider value={{ setModal, closeModal, setOnModalClose }} >
      {children}
      <Modal className={userType} show={show} onClose={closeModal} close={closeModal} goBack={backModal}>
        {element}
      </Modal>
    </ModalContext.Provider>
  )
}


export const useModal = () => {
  return useContext(ModalContext)
}