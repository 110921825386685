import { SecondaryButton } from '../../../components/Button'
import { InviteProfessional } from '../../../components/GameUser/Profile/InviteProfessional'
import { useModal } from '../../../contexts/ModalContext'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import api from '../../../services/api'
import { LoadingDots } from '../../../components/Loading'
import { GameUserData, GameUserProps, useAuth } from '../../../contexts/UserContext'
import { DashboardFrame } from '../../../frames/DashboardFrame'
import { GameUserProfileHeader } from '../../../frames/ProfileHeader'
import { RecentActivity, RecentActivityProps } from '../../../frames/RecentActivity'
import { UserCardSmall } from '../../../frames/UserCard'
import './GameUserProfile.sass'

interface getActivitiesUser {
  gameData: {
    gameUserData: GameUserData,
    gameUserID: string
  }
}

interface getActivitiesAdmin {
  gameData: {
    gameUserData: GameUserData
  }
}

type getActivitiesProps = getActivitiesUser | getActivitiesAdmin

const getActivities = ({ gameData }: getActivitiesProps) => {
  let data: GameUserData = gameData.gameUserData
  const activities: RecentActivityProps['activity'][] = []

  if (!data) return activities;

  data.transactions.forEach(transaction => {
    switch (transaction.type) {
      case 'income':
        switch (transaction.income?.type) {
          case 'activity':
            const activity: RecentActivityProps['activity'] = {
              title: transaction.description,
              subtitle: transaction.income.activity,
              amount: transaction.amount,
              time: new Date(transaction.time),
              type: "income" as "income"
            }
            activities.push(activity)
            break;
        }
        break;
      case 'outcome':
        switch (transaction.outcome?.type) {
          case 'game_store':
            const activity: RecentActivityProps['activity'] = {
              title: transaction.description,
              subtitle: transaction.outcome.game_store_obj,
              amount: transaction.amount,
              time: new Date(transaction.time),
              type: "outcome" as "outcome"
            }
            activities.push(activity)
            break;
        }
        break;
    }
  })

  return activities
}

export const GameUserProfile = () => {
  const location = useLocation()

  const state = location.state as { from: Location }
  const from = state ? state.from.pathname : '/dashboard/'

  const gameUserIndex = useParams().index
  const { gameUsers, isGameUsersLoading, GetUser } = useAuth()
  const user = GetUser()
  // const [optionState, setOptionState] = useState(false)
  const [activities, setActivities] = useState<RecentActivityProps['activity'][]>([])

  const { t } = useTranslation()
  const { setModal } = useModal()

  useEffect(() => {
    if (!isGameUsersLoading && (gameUsers.length > 0) && gameUserIndex) {
      const response = getActivities({ gameData: { gameUserData: gameUsers[parseInt(gameUserIndex, 10)].data } })
      setActivities([...response])
    }
  }, [gameUsers, isGameUsersLoading, gameUserIndex])

  if (gameUserIndex === undefined) {
    // console.log('redirecting')
    return <Navigate to={from} />
  }

  const gameUser = gameUsers[parseInt(gameUserIndex, 10)]

  if (isGameUsersLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center" >
        <LoadingDots />
      </div>
    )
  }

  if (!gameUser && !isGameUsersLoading) {
    // console.log('redirecting')
    return <Navigate to={from} />
  }

  return (
    <div id="profile">
      {!gameUser && (
        <div className="w-full h-full flex items-center justify-center" >
          <LoadingDots />
        </div>
      )}
      <GameUserProfileHeader gameUser={gameUser} />
      <div className="profile-cards">
        <div className="card activity-cards">
          <h2>{t('gameUserProfile.recentActivities')}</h2>
          <div className="card-session">
            {
              activities.map((activity, index) => (
                <RecentActivity activity={activity} key={`${activity.title} ${index}`} />
              ))
            }
            {
              activities.length === 0 && (
                <p>{t("gameUserProfile.noActivity")}</p>
              )
            }
          </div>
        </div>

        <div className="card">
          <div className="header">
            <h2>{user.type === "parent" ? t('gameUserProfile.professionals') : t('gameUserProfile.parent')}</h2>
            {(user.type === "parent" && !gameUser?.professional) && (<SecondaryButton onClick={() => { setModal({ element: <InviteProfessional gameUserIndex={parseInt(gameUserIndex)} /> }) }} label={t('gameUserProfile.inviteProfessional')} />)}

          </div>
          <div className="card-session">
            {
              user.type === "parent" ?
                (gameUser?.professional ? <UserCardSmall user={gameUser.professional} /> : (<p>{t('gameUserProfile.noLinkedProfessional')}</p>))
                :
                (<UserCardSmall user={gameUser.parent} />)
            }
          </div>
        </div>
      </div>
      <div className="dashboard">
        <DashboardFrame userData={gameUsers[parseInt(gameUserIndex)].data} />
      </div>
    </div>
  )
}


export const AdminGameUserProfile = () => {
  const location = useLocation()

  const navigate = useNavigate()
  const params = useParams()
  const state = location.state as { from: Location }
  const from = state ? state.from.pathname : '/dashboard/'

  // const [optionState, setOptionState] = useState(false)
  const [activities, setActivities] = useState<RecentActivityProps['activity'][]>([])
  const [gameUser, setGameUser] = useState<GameUserProps>({} as GameUserProps)
  const [gameUserData, setGameUserData] = useState<GameUserData>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { t } = useTranslation()

  useEffect(() => {
    if (!params.id) return;
    api.get(`/api/admin/game_user/${params.id}`).then(
      response => {
        const gameUser = response.data as GameUserProps
        setGameUser(gameUser)
      },
      error => {
        navigate("/admin/dashboard")
      }
    ).finally(() => setIsLoading(false))
  }, [params, navigate])


  useEffect(() => {
    if (!gameUser.id) return;
    setIsLoading(true)
    api.get(`/api/admin/game/${gameUser.id}`).then(
      response => {
        const gameUserData = response.data as GameUserData
        setGameUserData(gameUserData)
      },
      error => {
        navigate("/admin/dashboard")
      }
    ).finally(() => setIsLoading(false))
  }, [gameUser, navigate])


  useEffect(() => {
    if (!gameUserData?.transactions) return;
    const response = getActivities({ gameData: { gameUserData: gameUserData } })
    setActivities([...response])
  }, [gameUserData])


  if (!gameUser && !isLoading) {
    // console.log('redirecting')
    return <Navigate to={from} />
  }

  return (
    <div id="profile">
      {!gameUser && (
        <div className="w-full h-full flex items-center justify-center" >
          <LoadingDots />
        </div>
      )}
      <GameUserProfileHeader gameUser={gameUser} />
      <div className="profile-cards">
        <div className="card settings-card">
          <h2>{t('gameUserProfile.gameSettings')}</h2>
          <div className="card-session">
            {/* <div className="option">
              <SwitchButton state={optionState} setState={setOptionState}>
                Enable this game scenario
              </SwitchButton>
            </div>

            <div className="option">
              <SwitchButton state={optionState} setState={setOptionState}>
                Enable this game scenario
              </SwitchButton>
            </div> */}
          </div>
        </div>

        <div className="card activity-cards">
          <h2>{t('gameUserProfile.recentActivities')}</h2>
          <div className="card-session">
            {
              activities.map((activity, index) => (
                <RecentActivity activity={activity} key={`${activity.title} ${index}`} />
              ))
            }
            {
              activities.length === 0 && (
                <p>This game user has no activity recorded.</p>
              )
            }
          </div>
        </div>

        <div className="card">
          <h2>{t('gameUserProfile.users')}</h2>
          <div className="card-session">
            {
              gameUser?.parent && <UserCardSmall user={gameUser.parent} link="/admin/dashboard/user/" />
            }
            {
              gameUser?.professional && <UserCardSmall user={gameUser.professional} link="/admin/dashboard/user/" />
            }
          </div>
        </div>
      </div>
      <div className="dashboard">
        {gameUserData && <DashboardFrame userData={gameUserData} />}
      </div>
    </div>
  )
}