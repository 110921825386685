import { DropdownInput } from 'components/Dropdown'
import { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextInput } from '../../../../../components/Input'
import { LoadingRing, WhiteLoadingRing } from '../../../../../components/Loading'
import { useAuth } from '../../../../../contexts/UserContext'
import { useWarnings } from '../../../../../contexts/WarningContext'
import api from '../../../../../services/api'
import './PersonalSettings.sass'

export const PersonalSettings = () => {
  const { GetUser, UserCheckAuth } = useAuth()
  const { setWarning } = useWarnings()

  const user = GetUser()

  const { t } = useTranslation()

  const [firstName, setFirstName] = useState<string>(user.firstName)
  const [lastName, setLastName] = useState<string>(user.lastName)
  const [email, setEmail] = useState<string>(user.email)
  const [isPremium, setIsPremium] = useState<boolean>()
  const [isPublic, setIsPublic] = useState<boolean>()

  const [canSave, setCanSave] = useState<boolean | undefined>(undefined)
  const [isEmailLoading, setIsEmailLoading] = useState(false)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    setIsEmailLoading(true)
    api.put('/api/user', {
      first_name: firstName,
      last_name: lastName,
      email: email,
      is_public: isPublic

    }).then(
      response => {
        setWarning({
          type: 'success',
          warning: t('profile.cards.settings.personalSettings.success')
        })
        UserCheckAuth()
      },
      error => {
        setWarning({
          type: 'error',
          warning: t('profile.cards.settings.personalSettings.error'),
          duration: 15
        })
      }
    )
      .finally(() => {
        setIsEmailLoading(false)
        setCanSave(false)
      })
  }


  const resetData = () => {
    setFirstName(user.firstName)
    setLastName(user.lastName)
    setEmail(user.email)
    if (user.type === "professional") {
      setIsPublic(user.isPublic)
    }
  }

  useEffect(() => {
    if (firstName !== user.firstName ||
      lastName !== user.lastName ||
      email !== user.email ||
      (user.type === "professional" && isPublic !== user.isPublic)) {
      setCanSave(true)
    } else {
      setCanSave(false)
    }
  }, [firstName, lastName, email, isPublic, user])

  useEffect(() => {
    if (user.type === "parent") {
      setIsPremium(user.isPremium)

    }
    if (user.type === "professional") {
      setIsPublic(user.isPublic)
    }
  }, [user])

  const IsEmail = () => {
    const [isLoading, setIsLoading] = useState(false)

    const sendVerificationEmail = () => {
      setIsLoading(true)
      api.post('/api/user/verify').then(
        response => {
          setWarning({
            type: "success",
            warning: t('profile.cards.settings.personalSettings.sendVerificationEmail.success')
          })
        },
        error => {
          setWarning({
            type: "error",
            warning: t([`errors.${error.response.data.detail}`, 'errors.undefined']),
            duration: 15
          })
        }
      )
        .finally(() => setIsLoading(false))
    }

    return user.isEmailVerified ? (
      <p className="text-sm"> {t('profile.cards.settings.personalSettings.isEmailVerified.true')} </p>
    ) : (
      <div className="flex gap-2">
        <p className="text-sm">
          {t('profile.cards.settings.personalSettings.isEmailVerified.false')}
        </p>
        <button
          type="button"
          onClick={sendVerificationEmail}
          className={`text-sm ${user.type === 'parent' ? 'text-teal-300' : 'text-purple-600'} filter hover:brightness-90 transition`}
        >
          {t('profile.cards.settings.personalSettings.sendVerificationEmail.send')}
        </button>
        {isLoading && <div className="flex w-5 h-5"><LoadingRing /></div>}
      </div>
    )
  }


  return (
    <div className="personal-settings">
      <form onSubmit={handleSubmit} className="data">
        <h3>{t('profile.cards.settings.personalSettings.personalSettings')}</h3>
        <TextInput
          label={t('profile.cards.settings.personalSettings.firstName')}
          value={firstName}
          setValue={setFirstName}
        />

        <TextInput
          label={t('profile.cards.settings.personalSettings.lastName')}
          value={lastName}
          setValue={setLastName}
        />

        <TextInput
          label={t('profile.cards.settings.personalSettings.email')}
          value={email}
          setValue={setEmail}
          after={<IsEmail />}
        />
        {
          user.type === "parent" && (
            <TextInput
              label={t('profile.cards.settings.personalSettings.subscription.title')}
              value={isPremium ? t('profile.cards.settings.personalSettings.subscription.premium') : t('profile.cards.settings.personalSettings.subscription.free')}
              setValue={() => { }}
              disabled
            />
          )
        }
        {
          (user.type === "professional" && isPublic !== undefined) && (
            <>
              <DropdownInput<boolean>
                label={t('profile.cards.settings.personalSettings.profileVisibility.title')}
                options={[{ label: t("profile.cards.settings.personalSettings.profileVisibility.public"), value: true }, { label: t("profile.cards.settings.personalSettings.profileVisibility.private"), value: false }]}
                setOption={(value: boolean | undefined) => { value !== undefined && setIsPublic(value) }}
                selectedOption={isPublic}
              />
              <p className="text-sm">{t("profile.cards.settings.personalSettings.profileVisibility.after")}</p>
            </>

          )
        }

        <div className={`buttons opacity-0 ${canSave ? 'animate-slide-in opacity-100' : 'animate-slide-out'}`}>
          <button type="button" onClick={resetData}>
            {t('profile.cards.settings.personalSettings.changes.discard')}
          </button>
          <button type="submit">
            {isEmailLoading ? (
              <div className="h-10 w-10">
                <WhiteLoadingRing />
              </div>
            ) : t('profile.cards.settings.personalSettings.changes.save')}
          </button>
        </div>
      </form>
    </div>
  )
}