import React, { Suspense } from 'react';
import { FullLoadingDots } from './components/Loading';
import { Router } from './router';

function App() {
  return (
    <Suspense fallback={<FullLoadingDots />}>
      <Router />
    </Suspense>
  );
}

export default App;
