import { useEffect, useMemo, useState } from "react"
import { CSVLink } from "react-csv"
import { useTranslation } from "react-i18next"
import { IoClipboardOutline, IoDownloadOutline, IoFilter, IoGift } from "react-icons/io5"
import { useImmer } from "use-immer"
import { CreateGiftCode } from "../../../../components/Admin/CreateGiftCode"
import { GiftCodeProps } from "../../../../components/GiftCodeInfo"
import { LoadingRing } from "../../../../components/Loading"
import { useModal } from "../../../../contexts/ModalContext"
import { useWarnings } from "../../../../contexts/WarningContext"
import api from "../../../../services/api"
import "./AdminGiftCode.sass"
import { GiftCodeFilters } from "./GiftCodeFilters"

interface AdminGiftCodeProps extends GiftCodeProps {
  tags: string[]
  used: boolean
}

export interface AdminGiftCodeFilters {
  unused_only: boolean
}

export const AdminGiftCode = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(true)
  const [results, setResults] = useState<AdminGiftCodeProps[]>([])
  const [query, setQuery] = useState<string>("")
  const [filters, setFilters] = useImmer<AdminGiftCodeFilters>({ unused_only: true })
  const { t } = useTranslation()
  const { setModal } = useModal()
  const { setWarning } = useWarnings()

  useEffect(() => {
    setIsLoading(true)
    api.get<AdminGiftCodeProps[]>(`/api/admin/gift_code?unused_only=${filters.unused_only}&tags=${query}`).then(
      response => {
        setResults([...response.data])
      },
      error => {

      }
    ).finally(() => {
      setIsLoading(false)
    })
  }, [query, filters])

  const headers = useMemo(() => [
    { label: "Code", key: "code" },
    { label: "Game user licenses", key: "licenses" },
    { label: "Validity days", key: "validity" },
    { label: "Tags", key: "tags" }
  ], [])

  const csvFile = useMemo(() => ({
    filename: `gift-codes${query.length > 0 ? `-${query}` : ""}.csv`, // file name is only "gift-codes.csv" or "gift-codes-query" when user has typed something
    headers,
    data: results.map(gift_code => ({ ...gift_code, tags: gift_code.tags.join(";") }))
  }), [headers, query, results])


  return (
    <div className="admin-gift-code">
      <div className="flex items-center justify-between mb-2">
        <h1>
          {t("admin.dashboard.gift_code.gift_code")}
        </h1>

        <button onClick={() => { setModal({ element: <CreateGiftCode /> }) }} className="flex items-center justify-evenly w-52 p-4 bg-white rounded-xl text-gray-700 font-semibold shadow-lg transition hover:shadow-xl">
          <IoGift />
          {t('admin.dashboard.gift_code.add')}
        </button>

      </div>
      <div className="page-functions">
        <form onSubmit={e => e.preventDefault()}>
          <input className="flex items-center border-none bg-white w-full h-full" type="text" placeholder={t('query.placeholder')} value={query} onChange={e => setQuery(e.target.value)} />
          <button onClick={e => { e.stopPropagation(); setIsFilterVisible(true) }} type="button" className="h-6 w-6 text-gray-700">
            {
              isLoading ? (
                <LoadingRing />
              ) : (
                <IoFilter className="h-full w-full" />
              )
            }
          </button>

        </form>

        {
          results.length > 0 && (
            <CSVLink {...csvFile} className="function"><IoDownloadOutline className="h-full w-full" /></CSVLink>
          )
        }
      </div>
      <div className="code-container">
        {
          results.map(result => (
            <div key={result.id} className={`gift-code ${result.used && "used"}`}>
              <p className="code"> {result.code} <button onClick={() => { navigator.clipboard.writeText(result.code); setWarning({ warning: t("admin.dashboard.gift_code.copied"), type: "success" }) }}><IoClipboardOutline /></button></p>
              <div className="code-info">
                <p> <b>{t("admin.dashboard.gift_code.validity")}: </b>{result.validity} {t("admin.dashboard.gift_code.days")}</p>
                <p> <b>{t("admin.dashboard.gift_code.licenses")}: </b>{result.licenses}</p>
              </div>
              <div className="tags">
                {
                  result.used && (
                    <p style={{ backgroundColor: "rgba(234, 88, 12, 1)" }} key="used">{t("admin.dashboard.gift_code.used")}</p>
                  )
                }
                {
                  result.tags.map(tag => (
                    <p key={tag}>{tag}</p>
                  ))
                }
              </div>
            </div>
          ))
        }

        {
          results.length <= 0 ?? (
            <div className="w-full h-full">
              {t("admin.dashboard.gift_code.no_gift_code")}
            </div>
          )
        }

      </div>
      {isFilterVisible && <GiftCodeFilters filters={filters} setFilters={setFilters} closeFilter={() => { setIsFilterVisible(false) }} />}
    </div>
  )
}