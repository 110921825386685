import { PrimaryButton } from "../../../components/Button"
import { useAuth } from "../../../contexts/UserContext"
import { useTranslation } from "react-i18next"
import { Navigate, useNavigate } from "react-router-dom"
import "../Professional.sass"

export const Accepted = () => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const navigate = useNavigate()

  if (user.type === "parent" || (user.type === "professional" && user.professionalStatus !== "ACCEPTED")) {
    return (
      <Navigate to="/dashboard" />
    )
  }

  return (
    <div className="verify-container">
      <div className="warning">
        <h1>
          {t('professional.accepted.title')}
        </h1>

        <p>
          {t('professional.accepted.subtitle')}
        </p>
        <PrimaryButton
          label={t("professional.accepted.start")}
          onClick={() => { localStorage.setItem("professional-skip-welcome", "skip"); navigate("/dashboard") }}
        />
      </div>
    </div>
  )
}