import FileSaver from 'file-saver'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { IoClose, IoDocument, IoEye, IoEyeOff } from 'react-icons/io5'
import { APIFile } from '../../pages/Professional/Resend'
import api from '../../services/api'
import { LoadingRing } from '../Loading'
import "./Input.sass"


interface TextInputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string,
  value: string,
  placeholder?: string,
  setValue: (value: string) => void,
  required?: boolean,
  type?: string,
  errors?: string[]
  after?: JSX.Element
}
interface NumberInputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string,
  value: number,
  placeholder?: string,
  setValue: (value: number) => void,
  required?: boolean,
  type?: string,
  errors?: string[]
  After?: JSX.Element
}
interface PasswordInputProps extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "type"> {
  label?: string,
  value: string,
  placeholder?: string,
  setValue: (value: string) => void,
  isPasswordVisible?: boolean,
  setIsPasswordVisible?: Dispatch<SetStateAction<boolean>>
  required?: boolean,
  errors?: string[],
  After?: JSX.Element
}
interface AreaInputProps extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  label?: string,
  value: string,
  placeholder?: string,
  setValue: (value: string) => void,
  isPassword?: boolean
  required?: boolean
  type?: string
  errors?: string[],
  After?: JSX.Element
}

interface RadioInputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label: string,
  checked: boolean,
  onChange: () => void
}


interface LocalFileProps {
  file: File
  removeFile: (index: number) => void
  index: number
  blockRemoval?: boolean
}


interface APIFileProps {
  file: APIFile
  removeAPIFile: (file: APIFile) => void
  blockRemoval?: boolean
}


type FileProps = LocalFileProps | APIFileProps


interface FileInputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label: string
  files: File[],
  apiFiles?: APIFile[],
  removeFile: (index: number) => void,
  removeAPIFile?: (file: APIFile) => void
}


export const TextInput = ({ label = 'Label', value, setValue, placeholder, errors, after, ...props }: TextInputProps) => {
  const [mockValue, setMockValue] = useState('')
  return (
    <div className="flex flex-col items-start my-4 w-full">
      <label htmlFor={label} className="text-gray-700 font-semibold">{label}</label>
      <input
        id={label}
        name={label}
        className={`border-solid border-2 rounded-xl w-full p-2 h-14 border-gray-200 ${(value.length <= 0 || errors === undefined) ? '' : errors.length > 0 ? 'border-red-600' : 'border-green-600'}`}
        value={value !== undefined ? value : mockValue}
        placeholder={placeholder}
        onClick={e => e.stopPropagation()}
        onChange={event => setValue ? setValue(event.target.value) : setMockValue(event.target.value)}

        {...props}
      />
      {after}

    </div>
  )
}


export const NumberInput = ({ label = 'Label', value, setValue, placeholder, errors, After, ...props }: NumberInputProps) => {
  const [mockValue, setMockValue] = useState<number>(0)
  return (
    <div className="flex flex-col items-start my-4 w-full">
      <label htmlFor={label} className="text-gray-700 font-semibold">{label}</label>
      <input
        id={label}
        name={label}
        className={`border-solid border-2 rounded-xl w-full p-2 h-14 border-gray-200 ${(errors === undefined) ? '' : errors.length > 0 ? 'border-red-600' : 'border-green-600'}`}
        value={value !== undefined ? value : mockValue}
        placeholder={placeholder}
        onClick={e => e.stopPropagation()}
        onChange={event => setValue ? setValue(parseInt(event.target.value)) : setMockValue(parseInt(event.target.value))}
        type="number"
        {...props}
      />
      {After}

    </div>
  )
}

export const TextArea = ({ label = 'Label', value, setValue, placeholder, maxLength, After, ...props }: AreaInputProps) => {
  const [mockValue, setMockValue] = useState('')
  return (
    <div className="flex flex-col items-start my-2 w-full">
      <label htmlFor={label} className="text-gray-700 font-semibold">{label}</label>
      <textarea
        id={label}
        name={label}
        className="border-solid border-2 rounded-xl w-full p-1 min-h-[7rem]"
        value={value !== undefined ? value : mockValue}
        placeholder={placeholder}
        onClick={e => e.stopPropagation()}
        onChange={event => setValue ? setValue(event.target.value) : setMockValue(event.target.value)}
        maxLength={maxLength}
        {...props}
      />
      {maxLength && <p className='text-sm text-right w-full text-gray-500'>{value ? value.length : 0}/{maxLength}</p>}
      {After}
    </div>
  )
}


export const PasswordInput = ({ label = 'Label', value, setValue, placeholder, errors, isPasswordVisible, setIsPasswordVisible, After, ...props }: PasswordInputProps) => {
  const [singleIsVisible, setSingleIsVisible] = useState(false)
  const [isVisible, setIsVisible] = [isPasswordVisible ?? singleIsVisible, setIsPasswordVisible ?? setSingleIsVisible]

  return (
    <div className="password-input flex flex-col items-start my-4 w-full relative">
      <label htmlFor={label} className="text-gray-700 font-semibold">{label}</label>
      <input
        id={label}
        name={label}
        className={`border-solid border-2 rounded-xl w-full p-2 h-14 border-gray-200 ${(value.length <= 0 || errors === undefined) ? '' : errors.length > 0 ? 'border-red-600' : 'border-green-600'}`}
        value={value !== undefined ? value : ''}
        placeholder={placeholder}
        onClick={e => e.stopPropagation()}
        onChange={event => setValue ? setValue(event.target.value) : () => { }}
        type={isVisible ? 'text' : 'password'}
        {...props}
      />
      <button type="button" onClick={() => setIsVisible(!isVisible)} className="eye">
        {isVisible ? <IoEye /> : <IoEyeOff />}
      </button>
      {After}
    </div>
  )
}


export const RadioInput = ({ label, checked, onChange, ...props }: RadioInputProps) => {
  return (
    <div className="input-block">
      <input type="radio" id={label} checked={checked} onClick={e => e.stopPropagation()} onChange={onChange} {...props} />
      <label htmlFor={label}>{label}</label>
    </div>
  )
}

export const File = ({ blockRemoval = false, ...file }: FileProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  // check if is instance of LocalFileProps
  if ("index" in file) {
    return (
      <a className="file-slim cursor-pointer" href="." onClick={e => e.preventDefault()} data-testid="file">
        {
          isLoading ? (
            <div className="h-8 w-8">
              <LoadingRing />
            </div>
          ) : (
            <>
              <IoDocument className="icon" />
              <p>{file.file.name}</p>
            </>
          )
        }
        {
          !blockRemoval && (
            <button onClick={e => { e.preventDefault(); e.stopPropagation(); file.removeFile(file.index) }} type='button' className='delete-file'>
              <IoClose />
            </button>
          )
        }
      </a>
    )
  }


  return (
    <a
      href={file.file.link}
      onClick={e => {
        e.preventDefault();
        setIsLoading(true);
        api.get(file.file.link ?? `/api/user/professional/document/${file.file.id}`, { responseType: "blob" }).then(response => { FileSaver.saveAs(response.data, file.file.name) }).finally(() => { setIsLoading(false) })
      }}
      className="file-slim cursor-pointer" data-testid="file">

      {
        isLoading ? (
          <div className="h-8 w-8">
            <LoadingRing />
          </div>
        ) : (
          <>
            <IoDocument className="icon" />
            <p>{file.file.name}</p>
          </>
        )
      }
      {
        !blockRemoval && (
          <button onClick={e => { e.preventDefault(); e.stopPropagation(); file.removeAPIFile(file.file) }} type='button' className='delete-file'>
            <IoClose />
          </button>
        )
      }
    </a>
  )
}


export const FileInput = ({ label, onChange, files, removeFile, removeAPIFile, apiFiles = [], ...props }: FileInputProps) => {
  return (
    <div className="flex flex-wrap h-auto w-full gap-2">
      <div className="file-slim-input">
        {/* <IoAdd className="icon" /> */}
        <label htmlFor={label}>{label}</label>
        <input type="file" id={label} onClick={e => e.stopPropagation()} onChange={onChange} {...props} />
      </div>
      {
        apiFiles.map((file, index) => (
          removeAPIFile && <File file={file} removeAPIFile={removeAPIFile} key={file.name} />
        ))
      }
      {
        files !== undefined && (
          files.map((file, index) => (
            <File file={file} index={index} removeFile={removeFile} key={file.name} />
          ))
        )
      }
    </div>
  )
}
