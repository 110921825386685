import { Background } from "../../../components/Background"
import { PrimaryButton } from "../../../components/Button"
import { LoadingDots } from "../../../components/Loading"
import { useWarnings } from "../../../contexts/WarningContext"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import api from "../../../services/api"
import "./VerifyAccountWarning.sass"

export const VerifyAccountWarning = () => {
  const { t } = useTranslation()
  const { setWarning } = useWarnings()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const sendVerificationEmail = () => {
    setIsLoading(true)
    api.post('/api/user/verify').then(
      response => {
        setWarning({
          type: "success",
          warning: t('profile.cards.settings.personalSettings.sendVerificationEmail.success')
        })
      },
      error => {
        setWarning({
          type: "error",
          warning: t([`errors.${error.response.data.detail}`, 'errors.undefined']),
          duration: 15
        })
      }
    )
      .finally(() => setIsLoading(false))
  }

  return (
    <div className="verify-warning-container professional">
      <Background />
      <div className="content">
        <p>
          {t('verifyAccount.warning.checkEmail')} {t('verifyAccount.warning.warning')}
        </p>
        <PrimaryButton onClick={sendVerificationEmail} label={t('verifyAccount.warning.button')} />

        {isLoading && <div className="loading"><LoadingDots /></div>}
      </div>
    </div>
  )
}