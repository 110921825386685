import { Background } from "../../components/Background"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { LoadingDots } from "../../components/Loading"
import { useWarnings } from "../../contexts/WarningContext"
import api from "../../services/api"
import './VerifyAccount.sass'


export const VerifyAccount = () => {
  const [isVerified, setIsVerified] = useState<boolean>(false)
  const [hasError, setHasError] = useState<boolean>(false)
  const { setWarning } = useWarnings()
  const token = useParams().token

  const { t } = useTranslation()

  const navigate = useNavigate()

  useEffect(() => {
    api.put('/api/user/verify', {
      token: token
    }).then(
      response => {
        setIsVerified(true)
        setHasError(false)
        setWarning({
          type: "success",
          warning: t('verifyAccount.success')
        })
      },
      error => {
        setWarning({
          type: "error",
          warning: t([`errors.${error.response.data.detail}`, 'errors.undefined'])
        })
        setHasError(true)

      }
    )
    // eslint-disable-next-line
  }, [])

  return (
    <div className="verify-account parent">
      <Background />
      <div className="frame">
        {
          !(isVerified || hasError) && (
            <div className="loading">
              <LoadingDots />
            </div>
          )
        }

        {
          isVerified && (
            <div className="verified">
              <p>{t('verifyAccount.success')}</p>

              <button onClick={() => navigate('/login')}>
                {t('verifyAccount.button')}
              </button>
            </div>
          )
        }

        {
          hasError && (
            <div className="error">
              <p>{t('verifyAccount.error.errorOccurred')}</p>
              <p>{t('verifyAccount.error.tryAgain')}</p>

              <button onClick={() => navigate('/login')}>
                {t('verifyAccount.button')}
              </button>
            </div>
          )
        }
      </div>
    </div>
  )
}