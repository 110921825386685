import { PrimaryButton, SecondaryButton } from "../../components/Button"
import { useModal } from "../../contexts/ModalContext"
import { useAuth } from "../../contexts/UserContext"
import { useWarnings } from "../../contexts/WarningContext"
import FileSaver from "file-saver"
import { APIFile } from "../../pages/Professional/Resend"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Navigate } from "react-router-dom"
import api from "../../services/api"
import "./RemoveFile.sass"

export const RemoveFile = ({ file }: { file: APIFile }) => {
  const { setWarning } = useWarnings()
  const { closeModal } = useModal()
  const { user } = useAuth()
  const { t } = useTranslation()

  const link = file.link ?? `/api/user/professional/document/${file.id}`

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDownloadLoading, setIsDownloadLoading] = useState<boolean>(false)

  const deleteFile = () => {
    setIsLoading(true)
    if (user.type === "professional" || user.type === "admin") {
      api.delete(link).then(
        response => {
          setWarning({ warning: t("professional.removeFile.successfulDelete"), type: "success" })
          closeModal()
        },
        error => {
          setWarning({ warning: t("professional.removeFile.unsuccessfulDelete"), type: "error" })
        }
      ).finally(() => {
        setIsLoading(false)
      })
    }
  }

  if (user.type === "parent") {
    return (
      <Navigate to="/dashboard" />
    )
  }

  return (
    <div className={`remove-file ${user.type === "professional" ? "professional" : "parent"} ${isDownloadLoading ? "cursor-wait" : "cursor-default"}`}>
      <p className={`${isDownloadLoading ? "cursor-wait" : "cursor-default"}`}>{t("professional.removeFile.areYouSure")} <a href={link} onClick={e => {
        e.preventDefault(); setIsDownloadLoading(true); api.get(file.link ?? `/api/user/professional/document/${file.id}`, { responseType: "blob" }).then(response => { FileSaver.saveAs(response.data, file.name) }).finally(() => { setIsDownloadLoading(false) })
      }} >{file.name}</a>?</p>
      <div className="functions">
        <SecondaryButton type="button" onClick={e => { closeModal() }}>
          {t("professional.removeFile.cancel")}
        </SecondaryButton>
        <PrimaryButton type="button" isLoading={isLoading} onClick={e => { deleteFile() }}>
          {t("professional.removeFile.deleteFile")}
        </PrimaryButton>
      </div>
    </div>
  )
}