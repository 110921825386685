import { LoadingRing } from "../../components/Loading"
import { useModal } from "../../contexts/ModalContext"
import { GameUserProps, useAuth } from "../../contexts/UserContext"
import { useWarnings } from "../../contexts/WarningContext"
import { ProfessionalUserFunctionProps, ProfessionalUserFunctions } from "../../frames/UserCard/ProfessionalUserCard"
import { Dispatch, SetStateAction, useState } from "react"
import { useTranslation } from "react-i18next"
import { IoPerson } from "react-icons/io5"
import api from "../../services/api"
import "./InviteProfessional.sass"

export const InviteProfessional = ({ professional }: ProfessionalUserFunctionProps) => {
  const { t } = useTranslation()
  const { gameUsers, invites } = useAuth()
  const [blockInvite, setBlockInvite] = useState<boolean>(false)

  const invitedGameUsersNames = invites.map(invite => invite.professional_email === professional.email ? invite.child_name : undefined)

  const availableGameUsers = gameUsers.filter(gameUser => {
    const alreadyInvited = invitedGameUsersNames.find(invitedGameUserName => invitedGameUserName === gameUser.name) !== undefined
    const alreadyLinked = !!gameUser.professional ?? false

    return !(alreadyInvited || alreadyLinked)
  })

  const unavailableGameUsers = gameUsers.filter(gameUser => {
    const alreadyInvited = invitedGameUsersNames.find(invitedGameUserName => invitedGameUserName === gameUser.name) !== undefined
    const alreadyLinked = !!gameUser.professional ?? false

    return (alreadyInvited || alreadyLinked)
  })

  return (
    <div className="invite-professional">
      <h1>{t("query.selectUser")}</h1>
      <div className="content">
        {
          availableGameUsers.map(gameUser => {
            const alreadyInvited = invitedGameUsersNames.find(invitedGameUserName => invitedGameUserName === gameUser.name) !== undefined
            const alreadyLinked = !!gameUser.professional ?? false
            return (
              <UserCard key={gameUser.id} blockInvite={blockInvite} setBlockInvite={setBlockInvite} gameUser={gameUser} alreadyInvited={alreadyInvited} alreadyLinked={alreadyLinked} professional={professional} />
            )
          })
        }
        {
          unavailableGameUsers.map(gameUser => {
            const alreadyInvited = invitedGameUsersNames.find(invitedGameUserName => invitedGameUserName === gameUser.name) !== undefined
            const alreadyLinked = !!gameUser.professional ?? false
            return (
              <UserCard key={gameUser.id} blockInvite={blockInvite} setBlockInvite={setBlockInvite} gameUser={gameUser} alreadyInvited={alreadyInvited} alreadyLinked={alreadyLinked} professional={professional} />
            )
          })
        }
      </div>
    </div>
  )
}


function UserCard({ gameUser, alreadyInvited, alreadyLinked, professional, blockInvite, setBlockInvite }: { gameUser: GameUserProps, alreadyInvited: boolean, alreadyLinked: boolean, blockInvite: boolean, professional: ProfessionalUserFunctionProps["professional"], setBlockInvite: Dispatch<SetStateAction<boolean>> }) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { setWarning } = useWarnings()
  const { setModal } = useModal()
  const { getInvites } = useAuth()
  const { t } = useTranslation()

  const sendInvite = (gameUserID: string) => {
    return api.post("/api/user/invite", {
      "professional_id": professional.id,
      "child_id": gameUserID
    }).then(
      response => {
        setWarning({ warning: t("query.invite.success"), type: "success" })
      },
      error => {
        setWarning({ warning: t("query.invite.error"), type: "error" })
      }
    ).finally(() => { })
  }

  return (
    <div
      className={`small-card ${((alreadyInvited || alreadyLinked || blockInvite) && !isLoading) ? "opacity-60 cursor-not-allowed disabled" : "opacity-100 cursor-pointer"}`}
      onClick={() => {
        if (alreadyInvited || alreadyLinked || blockInvite) return;
        setIsLoading(true)
        setBlockInvite(true)
        sendInvite(gameUser.id).finally(() => {
          setModal({ element: <ProfessionalUserFunctions professional={professional} sent={true} /> })
          getInvites().finally(() => { setIsLoading(false) })
        }).finally(() => { setBlockInvite(false) })
      }}
      key={gameUser.id}
    >
      <div>
        <div className="img">
          <IoPerson className="icon-person" />
          <img id="game-user-profile-picture" src={`/api/user/game_user/${gameUser.id}/profile_picture`} alt="" className="profile-icon" />
        </div>
      </div>
      <div>
        <p>{gameUser.name}</p>
        {alreadyInvited && <p className="error">{t("query.invite.alreadyInvited")}</p>}
        {alreadyLinked && <p className="error">{t("query.invite.alreadyLinked")}</p>}
      </div>
      {
        isLoading && (
          <div className="absolute right-2 top-5 h-14 w-14 shrink-0">
            <LoadingRing />
          </div>
        )
      }
    </div>
  )
}