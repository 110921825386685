import { ReactNode } from "react"
import { IconType } from "react-icons"
import "./AnalyticCard.sass"

interface AnalyticProps {
  children: ReactNode;
  Icon: IconType,
  title: string
}

export const AnalyticCard = ({ children, Icon, title, ...props }: AnalyticProps) => {
  return (
    <div className="analytic-card" {...props}>
      <div className="flex flex-col p-1">
        <h2 className="text-sm font-semibold text-gray-400">{title}</h2>
        {children}
      </div>
      <Icon className="icon" />
    </div>
  )
}