import { useCallback, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useImmer } from "use-immer"
import { ThreeStepSwitch } from "../../../../../components/Button"
import { useAuth } from "../../../../../contexts/UserContext"
import api from "../../../../../services/api"

import _ from "lodash"

import "../GameSettings.sass"
import { BsFillLockFill, BsFillUnlockFill } from "react-icons/bs"

export interface GameAreaInfo {
  levelId: number
  rooms: {
    roomId: number
    enabled: boolean | null
  }[]
}

export const GameArea = () => {
  const { t } = useTranslation()

  const gameUserIndex = useParams().index
  const { gameUsers } = useAuth()

  const [areas, setAreas] = useImmer<GameAreaInfo[]>([])
  const [areasCurrent, setAreasCurrent] = useImmer<GameAreaInfo[]>([])



  const changeAllAreas = useMemo<Record<number, boolean | null>>(() => {
    const changeAllAreas: Record<number, boolean | null> = {}
    areas.forEach(area => {
      area.rooms.forEach(room => {
        if (changeAllAreas[area.levelId] === undefined) {
          changeAllAreas[area.levelId] = room.enabled
        } else if (changeAllAreas[area.levelId] !== room.enabled) {
          changeAllAreas[area.levelId] = null
        }
      })
    })
    return changeAllAreas
  }, [areas])


  useEffect(() => {
    if ((gameUsers.length > 0) && gameUserIndex) {
      api.get(`/api/user/game_user/${gameUsers[parseInt(gameUserIndex)].id}/areas`).then(
        response => {
          setAreas([...response.data])
          setAreasCurrent([...response.data])
        },
        error => {

        })
    }
  }, [gameUserIndex, gameUsers, setAreas, setAreasCurrent])


  const submitChanges = useCallback(() => {

    if (!(_.isEqual(areas, areasCurrent)) && gameUserIndex !== undefined) {
      api.post(`/api/user/game_user/${gameUsers[parseInt(gameUserIndex)].id}/areas`, areas).then(response => {
        api.get(`/api/user/game_user/${gameUsers[parseInt(gameUserIndex)].id}/areas`).then(
          response => {
            setAreasCurrent([...response.data])
          },
          error => {

          })
      })
    }
  }, [areas, areasCurrent, gameUserIndex, gameUsers, setAreasCurrent])


  useEffect(() => {
    let submit: null | (() => void) = submitChanges
    let timeout = setTimeout(() => {
      if (submit !== null) submit();
    }, 500)

    return () => {
      submit = null
      clearTimeout(timeout)
    }
  }, [areas, submitChanges])

  return (
    <div className="settings-section game-areas">
      <h2>
        {t('gameUserProfile.settings.gameSettings.gameAreas')}
      </h2>

      {
        areas.map((level, levelIndex) => (
          <ul key={`level${level.levelId}`} className="options">
            <div className="title">
              <h3>{t([`level.${level.levelId}.levelName`, 'level.levelNotFound'])}</h3>
              <ThreeStepSwitch
                state={changeAllAreas[level.levelId]}
                buttonFunctions={[
                  () => {
                    setAreas(areas => {
                      for (let i = 0; i < areas[level.levelId].rooms.length; i++) {
                        areas[level.levelId].rooms[i].enabled = false
                      }
                    })
                  },
                  null,
                  () => {
                    setAreas(areas => {
                      for (let i = 0; i < areas[level.levelId].rooms.length; i++) {
                        areas[level.levelId].rooms[i].enabled = true
                      }
                    })
                  }
                ]}
                label={[<BsFillLockFill />, t("level.custom"), <BsFillUnlockFill />]}

              />
            </div>
            {
              level.rooms.map((room, roomIndex) => (
                <li key={`room${room.roomId}`} className="option">
                  <p>{t([`level.${level.levelId}.room.${room.roomId}`, 'level.roomNotFound'])}</p>
                  <ThreeStepSwitch
                    buttonFunctions={[
                      () => {
                        setAreas(areas => {
                          areas[levelIndex].rooms[roomIndex].enabled = false
                        })
                      },
                      () => {
                        setAreas(areas => {
                          areas[levelIndex].rooms[roomIndex].enabled = null
                        })

                      },
                      () => {
                        setAreas(areas => {
                          areas[levelIndex].rooms[roomIndex].enabled = true
                        })
                      }
                    ]}
                    state={room.enabled}
                    label={[<BsFillLockFill />, t("level.auto"), <BsFillUnlockFill />]}

                  />
                </li>
              ))
            }
          </ul>
        ))
      }
    </div>
  )
}