import { useTranslation } from "react-i18next"
import { IoPersonAdd } from "react-icons/io5"
import { useNavigate } from "react-router"
import { useAuth } from "../../../contexts/UserContext"
import { NoUser } from "../../../frames/NoUser"
import { GameUserCard } from "../../../frames/UserCard"

export const Users = () => {
  const { gameUsers } = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()

  if (gameUsers.length === 0) {
    return (
      <div className="flex p-4 max-w-screen min-h-full">
        <NoUser />
      </div>
    )
  }

  return (
    <>
      <div className="flex w-full h-24 items-center justify-end">
        <button onClick={() => { navigate('register') }} className="flex items-center justify-evenly w-52 p-4 bg-white rounded-xl text-gray-700 font-semibold shadow-lg transition hover:shadow-xl">
          <IoPersonAdd />
          {t('users.addUser')}
        </button>
      </div>
      <div className="flex flex-col items-center sm:justify-center lg:justify-start lg:items-start sm:flex-row h-full max-w-full gap-4 overflow-y-auto sm:flex-wrap py-2 ">
        {
          gameUsers.map((user, index) => <GameUserCard onClick={() => { navigate(`/dashboard/game_user/${index}`) }} gameUser={user} key={user.id} />)
        }
      </div>
    </>
  )
}
