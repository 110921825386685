import { PrimaryButton } from "../../../../../components/Button"
import { GiftCodeInfo, GiftCodeProps } from "../../../../../components/GiftCodeInfo"
import { TextInput } from "../../../../../components/Input"
import { useModal } from "../../../../../contexts/ModalContext"
import { useAuth } from "../../../../../contexts/UserContext"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Navigate } from "react-router-dom"
import api from "../../../../../services/api"
import "./GiftCode.sass"
import { useWarnings } from "../../../../../contexts/WarningContext"


export const GiftCode = () => {
  const [value, setValue] = useState<string>("")
  const [isValid, setIsValid] = useState<boolean | null>(null)
  const [, setGiftCode] = useState<GiftCodeProps | null>(null)

  const { setModal } = useModal()
  const { setWarning } = useWarnings()
  const { GetUser } = useAuth()
  const { t } = useTranslation()

  const user = GetUser()

  useEffect(() => {
    setValue(value => value.toUpperCase())

    const numbersAmount = value.match(/[0-9]/g)
    const lettersAmount = value.match(/[A-Z]/g)

    if (value.length === 8) {
      if (numbersAmount?.length === 4 && lettersAmount?.length === 4) {
        setIsValid(true)
      } else {
        setIsValid(false)
      }
    } else {
      setIsValid(null)
    }

    if (value.length > 8) {
      setValue(value.slice(0, 8))
    }

  }, [value])


  if (user.type !== "parent" || user.isPremium) {
    return (
      <Navigate to="/dashboard/profile/settings" />
    )
  }


  const sendCode = () => {
    if (isValid) {
      api.get<GiftCodeProps>(`/api/user/gift_code/${value}`).then(
        response => {
          setGiftCode(response.data)
          setModal({ element: <GiftCodeInfo {...response.data} />, userType: user.type })
        }
      ).catch(error => {
        setWarning({
          warning: t([`errors.${error.response.data.detail}`, "errors.undefined"]),
          type: "error"
        })
      })
    }
  }

  return (
    <div className="gift-code-container">
      <h3>{t("menus.titles.gift_code")}</h3>

      <TextInput
        value={value}
        setValue={setValue}
        label={t("profile.cards.settings.giftCode.code")}
        errors={value.length < 8 ? undefined : isValid ? [] : [""]}
        after={isValid !== null ? (
          isValid === true ? (
            <>{t("profile.cards.settings.giftCode.valid")}</>
          ) : (
            <>{t("profile.cards.settings.giftCode.invalid")}</>
          )
        ) : (<></>)
        }
      />

      <PrimaryButton
        label={t("profile.cards.settings.giftCode.activate")}
        onClick={sendCode}
      />
    </div>
  )
}