import { FullLoadingDots } from '../../../../../components/Loading'
import { useModal } from '../../../../../contexts/ModalContext'
import { UserCardSmall } from '../../../../../frames/UserCard'
import { LinkedProfessionalUserFunctions } from '../../../../../frames/UserCard/ProfessionalUserCard'
import { useTranslation } from 'react-i18next'
import { Navigate, useParams } from 'react-router-dom'
import { GameUserProps, useAuth } from '../../../../../contexts/UserContext'
import './LinkedProfessionals.sass'


const Professional = ({ professional, gameUserID }: { professional: GameUserProps["professional"], gameUserID: string }) => {
  const { t } = useTranslation()
  const { setModal } = useModal()

  return professional === undefined || professional === null ? (
    <p className="font-bold">{t('gameUserProfile.settings.profileSettings.linkedProfessional.noProfessional')}</p>
  ) : (
    <UserCardSmall user={professional} onClick={e => { setModal({ element: <LinkedProfessionalUserFunctions gameUserID={gameUserID} professional={{ id: professional.responsible_id, ...professional }} /> }) }} />
  )
}

export const LinkedProfessionals = () => {
  const { gameUsers, isGameUsersLoading } = useAuth()
  const { t } = useTranslation()
  const params = useParams()
  const gameUserIndex = params.index !== undefined ? parseInt(params.index) : undefined

  if (gameUserIndex === undefined) {
    return <Navigate to="/dashboard/game_user/" />
  }

  if (isGameUsersLoading) {
    return <FullLoadingDots />
  }

  if (gameUserIndex >= gameUsers.length) {
    return <Navigate to="/dashboard/game_user/" />
  }

  return (
    <div className="personal-settings">
      <form className="data">
        <>
          <h3>{t('gameUserProfile.settings.profileSettings.linkedProfessional.title')}</h3>
          {
            <Professional gameUserID={gameUsers[gameUserIndex].id} professional={gameUsers[gameUserIndex].professional} />
          }
        </>
      </form>
    </div>
  )
}