import { PrimaryButton } from "../Button"
import { WhiteLoadingRing } from "../Loading"
import { useModal } from "../../contexts/ModalContext"
import { useWarnings } from "../../contexts/WarningContext"
import { ChangeEvent, FormEvent, useState } from "react"
import Cropper, { Area } from "react-easy-crop"
import { useTranslation } from "react-i18next"
import { IoCamera } from "react-icons/io5"
import api from "../../services/api"
import { getBase64, getCroppedImg } from "../../utils/ImageCrop"


export const ChangeProfilePicture = () => {
  const [file, setFile] = useState<string>()
  const [imageFile, setImageFile] = useState<Blob>()
  const [formData, setFormData] = useState<FormData>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)

  const { setWarning } = useWarnings()
  const { closeModal } = useModal()
  const { t } = useTranslation()

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files !== null) {
      if (files.length > 0) {
        getBase64(files[0]).then(
          result => {
            setFile(result)
          },
          error => {
            // i'll do something
          }
        ).finally(() => {
        })
      }
    }
  }

  const onCropComplete = async (_: Area, croppedAreaPixels: Area) => {
    if (file !== undefined) {
      const croppedImage = await getCroppedImg(file, croppedAreaPixels)
      const formData = new FormData()
      formData.append("image", croppedImage)
      setFormData(formData)
      setImageFile(croppedImage)
    }
  }

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsLoading(true)

    if (imageFile !== undefined) {
      api.put("/api/user/profile_picture", formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(
        response => {
          setWarning({ warning: "Success", type: "success" })
        },
        error => {

        }
      ).finally(() => {
        closeModal()
        setIsLoading(false)
        fetch("/api/user/profile_picture", { cache: 'reload', mode: 'no-cors' }).then(() => document.body.querySelectorAll<HTMLImageElement>("img[src='/api/user/profile_picture']").forEach((img) => {
          img.src = `/api/user/profile_picture?refresh=${new Date().getTime()}`
        }))

      })
    }
  }

  return (
    <form aria-label="form" onSubmit={onSubmit} className="flex flex-col w-full h-128 items-center gap-4 justify-center">
      <div className="inputBlock">
        <label className="font-bold mb-2" htmlFor="profile">Profile</label>
        <input id="profile" className="flex w-full h-12 px-4" type="file" accept="image/*" onChange={handleFileInputChange} />

      </div>
      {file !== undefined ? (
        <>
          <div className="flex h-4/5 w-full relative">
            <Cropper
              image={file}
              aspect={1}
              crop={crop}
              zoom={zoom}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </div>
          <PrimaryButton label={isLoading ? "" : t("profilePicture.send")} type="submit">
            {isLoading && (
              <div className="w-10 h-10">
                <WhiteLoadingRing />
              </div>
            )}
          </PrimaryButton>

        </>
      ) : (
        <div className="flex flex-col h-4/5 w-full items-center justify-center relative text-gray-300">
          <IoCamera size="30%" />
          <h1 className="w-56 text-center font-bold text-xl">{t('profilePicture.selectFile')}</h1>
          <h2 className="px-4 text-center text-lg">{t('profilePicture.sizeWarning')}</h2>

        </div>
      )}
    </form>
  )
}

export const ChangeGameUserProfilePicture = ({ id }: { id: string }) => {
  const [file, setFile] = useState<string>()
  const [imageFile, setImageFile] = useState<Blob>()
  const [formData, setFormData] = useState<FormData>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)

  const { setWarning } = useWarnings()
  const { closeModal } = useModal()
  const { t } = useTranslation()

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files !== null) {
      if (files.length > 0) {
        getBase64(files[0]).then(
          result => {
            setFile(result)
          },
          error => {
            // i'll do something
          }
        ).finally(() => {
        })
      }
    }
  }

  const onCropComplete = async (_: Area, croppedAreaPixels: Area) => {
    if (file !== undefined) {
      const croppedImage = await getCroppedImg(file, croppedAreaPixels)
      const formData = new FormData()
      formData.append("image", croppedImage)
      setFormData(formData)
      setImageFile(croppedImage)
    }
  }

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsLoading(true)

    if (imageFile !== undefined) {
      api.put(`/api/user/game_user/${id}/profile_picture`, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(
        response => {
          setWarning({ warning: "Success", type: "success" })
        },
        error => {

        }
      ).finally(() => {
        closeModal()
        setIsLoading(false)
      })
    }
  }

  return (
    <form aria-label="form" onSubmit={onSubmit} className="flex flex-col w-full h-128 items-center gap-4 justify-center">
      <div className="inputBlock">
        <label className="font-bold mb-2" htmlFor="profile">Profile</label>
        <input id="profile" className="flex w-full h-12 px-4" type="file" accept="image/*" onChange={handleFileInputChange} />

      </div>
      {file !== undefined ? (
        <>
          <div className="flex h-4/5 w-full relative">
            <Cropper
              image={file}
              aspect={1}
              crop={crop}
              zoom={zoom}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </div>
          <PrimaryButton label={isLoading ? "" : t("profilePicture.send")} type="submit">
            {isLoading && (
              <div className="w-10 h-10">
                <WhiteLoadingRing />
              </div>
            )}
          </PrimaryButton>

        </>
      ) : (
        <div className="flex flex-col h-4/5 w-full items-center justify-center relative text-gray-300">
          <IoCamera size="30%" />
          <h1 className="w-56 text-center font-bold text-xl">{t('profilePicture.selectFile')}</h1>
          <h2 className="px-4 text-center text-lg">{t('profilePicture.sizeWarning')}</h2>

        </div>
      )}
    </form>
  )
}