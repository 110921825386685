import { PrimaryButton } from "../../components/Button";
import { ColorDropdown } from "../../components/Dropdown";
import { FormEvent, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { RadioInput, TextArea, TextInput } from "../../components/Input";
import { useAuth } from "../../contexts/UserContext";
import api from "../../services/api";

export const ParentRegisterGameUserFrame = ({ children }: { children?: ReactNode }) => {
  const [name, setName] = useState('')
  const [sex, setSex] = useState<'male' | 'female'>('male')
  const [skinTone, setSkinTone] = useState<string | undefined>("#8d5524")
  const [observations, setObservations] = useState('')

  const [serverError, setServerError] = useState<string[]>([])

  const { GetUser, getGameUsers } = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const user = GetUser()

  const handleForm = (event: FormEvent) => {
    event.preventDefault();

    if (!name) {
      setServerError(["Missing Name"])
      return
    }

    if (!skinTone) {
      setServerError(["Missing Skin Color"])
      return
    }

    const data = {
      name: name,
      sex: sex,
      observations: observations,
      skin_tone: skinTone
    }
    api.post('/api/user/game_user', data).then(
      response => {
        getGameUsers()
        navigate('/dashboard/game_user/')
      },
      error => {
        setServerError([error.response.data.detail])
      })

  }

  if (user.type === "professional") {
    return (
      <div className="flex w-full h-full items-center justify-center">
        <div className="flex flex-col items-center bg-white shadow-lg rounded-lg p-10">
          <img className="w-20 h-20 mb-4" src="/logo.png" alt="Logo" />

          <p className=" py-2">{t('gameUserRegistration.professionalWarning')}</p>
          <button onClick={e => { e.preventDefault(); navigate('/dashboard') }}>
            {t('gameUserRegistration.backToDashboard')}
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className="flex w-full h-full items-center justify-center">
      <div className="flex flex-col items-center bg-white shadow-lg rounded-lg p-10">
        <img className="w-20 h-20 mb-4" src="/logo.png" alt="Logo" />
        <h1 className="text-lg font-bold text-gray-700">{t('gameUserRegistration.title')}</h1>
        <form onSubmit={handleForm} >
          <TextInput label={`${t('gameUserRegistration.fullName')}:`} value={name} setValue={setName} required />
          <p className="font-semibold">{t('gameUserRegistration.gender')}: </p>
          <div className="flex items-center justify-between w-full ">

            <RadioInput
              label={t('gameUserRegistration.male')}
              checked={sex === 'male'}
              onChange={() => setSex('male')}
            />

            <RadioInput
              label={t('gameUserRegistration.female')}
              checked={sex === 'female'}
              onChange={() => setSex('female')}
            />

          </div>
          <TextArea value={observations} setValue={setObservations} label={`${t('gameUserRegistration.observation')}:`} />
          <ColorDropdown
            label={t('dropdown.skinTone')}
            options={[
              { label: "#8d5524", value: "#8d5524" },
              { label: "#c68642", value: "#c68642" },
              { label: "#e0ac69", value: "#e0ac69" },
              { label: "#f1c27d", value: "#f1c27d" },
              { label: "#ffdbac", value: "#ffdbac" }
            ]}
            setOption={setSkinTone}
            selectedOption={skinTone}
          />
          {
            serverError.map(error => (
              <p className="flex font-semibold text-red-800">{error}</p>
            ))
          }

          <div className="flex flex-col items-center justify-evenly h-20 w-full">
            <PrimaryButton label={t('gameUserRegistration.register')} type='submit' />
          </div>
        </form>

        {children}
      </div>

    </div>
  )
}



export const ProfessionalRegisterGameUserFrame = () => {
  const { t } = useTranslation()
  const [userID, setUserID] = useState('')

  const handleForm = (event: FormEvent) => {
    event.preventDefault();
  }

  return (
    <div className="flex w-full h-full items-center justify-center">
      <div className="flex flex-col items-center bg-white shadow-lg rounded-lg p-10">
        <img className="w-20 h-20 mb-4" src="/logo.png" alt="Logo" />
        <form onSubmit={handleForm} >
          <TextInput label={t("gameUserRegistration.user")} placeholder={t("gameUserRegistration.user_id")} value={userID} setValue={setUserID} required />
          <div className="flex flex-col items-center justify-evenly h-20 w-full">
            <PrimaryButton label={t("gameUserRegistration.invite")} type='submit' />
          </div>
        </form>
      </div>
    </div>
  )
}