import { AxiosResponse } from "axios";
import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import api from "../services/api";
import * as Sentry from "@sentry/browser"

import { useImmer } from "use-immer";

interface CreateUserData {
  type: 'parent' | 'professional',
  first_name: string,
  last_name: string,
  email: string,
  password: string
}


interface UserProviderData {
  children: ReactNode;
}


export interface UserBasePropsServerResponse {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  is_verified: boolean;
}

interface ParentPropsServerResponse extends UserBasePropsServerResponse {
  type: "parent";
  is_premium: boolean;
  game_user_licenses: number;
}

interface ProfessionalPropsServerResponse extends UserBasePropsServerResponse {
  type: "professional"
  professional_status: "UNVERIFIED" | "ANALYSIS" | "RESEND" | "DENIED" | "ACCEPTED"
  is_public: boolean
}

export type UserPropsServerResponse = ParentPropsServerResponse | ProfessionalPropsServerResponse

interface UserPropsBase {
  firstName: string;
  lastName: string;
  email: string;
}

interface UserPropsAdmin extends UserPropsBase {
  type: 'admin';
}

export interface ParentUserPropsPlatform extends UserPropsBase {
  type: 'parent';
  isEmailVerified: boolean;
  isPremium: boolean;
  gameUserLicenses: number;
}

export interface ProfessionalUserPropsPlatform extends UserPropsBase {
  type: 'professional';
  isEmailVerified: boolean;
  isPublic: boolean;
  professionalStatus: "UNVERIFIED" | "ANALYSIS" | "RESEND" | "DENIED" | "ACCEPTED";
}

type UserPropsPlatform = ParentUserPropsPlatform | ProfessionalUserPropsPlatform
export type UserQueryProps = UserPropsPlatform & { id: string }

type UserProps = UserPropsAdmin | ParentUserPropsPlatform | ProfessionalUserPropsPlatform

export interface InviteProps {
  id: string
  parent_name: string
  parent_email: string
  professional_name: string
  professional_email: string
  child_name: string
  child_id: string
}

export interface GameUserData_item {
  day: string,
  value: number
}

export interface GameUserData_item_array {
  [key: string | number]: {
    day: string,
    value: number
  }[]
}

interface GameUserTransaction_income {
  type: 'activity'
  activity?: string
}


interface GameUserTransaction_outcome {
  type: 'game_store'
  game_store_obj?: string
}

interface TransactionIncome {
  type: 'income'
  description: string
  amount: number
  income: GameUserTransaction_income
  time: string
}

interface TransactionOutcome {
  type: 'outcome'
  description: string
  amount: number
  outcome: GameUserTransaction_outcome
  time: string
}
export type GameUserTransactions = TransactionIncome | TransactionOutcome

export interface GameUserData {
  "game_session_duration": GameUserData_item[]
  "activity_session_duration": GameUserData_item_array
  "user_precision": GameUserData_item_array
  "user_response": GameUserData_item_array
  "user_errors": GameUserData_item_array
  "transactions": GameUserTransactions[]
  "max_game_time": number | null
  "spent_time": number
}


export interface GameUserProps {
  id: string
  name: string,
  observations: string,
  skin_tone: string,
  gender: string,
  parent: {
    responsible_id: string,
    name: string,
    email: string
  }
  professional?: {
    responsible_id: string,
    name: string
    email: string
  },
  invites: {
    id: string,
    professional: {
      id: string
      name: string,
      email: string
    }
  }[],
  is_active: boolean,
  data: GameUserData
}

export interface AdminGameUserProps extends Omit<GameUserProps, "parent" | "professional"> {
  parent: ParentUserPropsPlatform & { responsible_id: string }
  professional: ProfessionalUserPropsPlatform & { responsible_id: string }

}

interface LoginProps {
  login: string
  password: string
}

interface TokenLoginProps {
  token: string
}

interface EmailLoginProps {
  email: string
}

export interface UserContextData {
  user: UserProps;
  users: UserQueryProps[];
  GetUser: () => UserPropsPlatform;
  invites: InviteProps[];
  UserCheckAuth: () => Promise<AxiosResponse<any, any> | undefined>;
  AdminCheckAuth: () => Promise<AxiosResponse<any, any> | undefined>;
  RequestLogin: (data: LoginProps) => Promise<string[] | null>;
  TokenRequestLogin: (data: TokenLoginProps) => Promise<string[] | null>;
  RequestPasswordRecover: (data: EmailLoginProps) => Promise<string[] | null>;
  Logout: () => Promise<void>
  CreateUser: (data: CreateUserData) => Promise<string[] | null>;
  getGameUsers: () => Promise<void>;
  getInvites: () => Promise<void>
  gameUsers: GameUserProps[];
  isGameUsersLoading: boolean
  // isGameUserDataLoading: boolean
  selectedGameUser: GameUserProps | undefined;
  setSelectedGameUser: Dispatch<SetStateAction<GameUserProps | undefined>>;
  AdminGetUsers: () => void;
}

export const UserContext = createContext({} as UserContextData)

export const UserProvider = ({ children, ...args }: UserProviderData) => {
  const [user, setUser] = useState<UserProps>({} as UserProps)
  const [users, setUsers] = useState<UserQueryProps[]>([])
  const [gameUsers, setGameUsers] = useImmer<GameUserProps[]>([])
  const [isGameUsersLoading, setIsGameUsersLoading] = useState(false)
  const [invites, setInvites] = useState<InviteProps[]>([])
  // const [isGameUserDataLoading, setIsGameUserDataLoading] = useState(false)
  const [selectedGameUser, setSelectedGameUser] = useState<GameUserProps>()


  const getGameUsers = useCallback(async () => {
    setIsGameUsersLoading(true)
    if (user.type !== "admin") {
      try {
        const response = await api.get<Omit<GameUserProps, "data">[]>('/api/user/game_user')
        const gameUsers: Omit<GameUserProps, "data">[] = response.data.map(data => ({ ...data }))
        const gameUserData: GameUserProps[] = await Promise.all(gameUsers.map(async (gameUser, index) => {
          const response = (await api.get<GameUserData>(`/api/user/game/${gameUser.id}`))
          return { ...gameUser, data: response.data }
        }))
        setGameUsers(gameUserData)
      }
      catch (error) {
        console.error(error)
      }
    } else {
      try {
        const response = await api.get("/api/admin/game_users")
        const gameUsers: GameUserProps[] = response.data
        setGameUsers(gameUsers)
      }
      catch (error) {
        console.error(error)
      }
    }
    setIsGameUsersLoading(false)
  }, [user, setGameUsers])

  const getInvites = async () => {
    try {
      const response = await api.get('/api/user/invites')
      setInvites([...response.data])
    }
    catch (error) {
      console.error(error)
    }
  }

  const UserCheckAuth = async () => {
    return api.get('/api/user').then(
      response => {
        if (response.status === 200) {
          setUser({
            firstName: response.data['first_name'],
            lastName: response.data['last_name'],
            email: response.data['email'],
            type: response.data['type'],
            isEmailVerified: response.data['is_email_verified'],
            isPremium: response.data['is_premium'],
            gameUserLicenses: response.data['game_user_licenses'],
            professionalStatus: response.data['professional_status'],
            isPublic: response.data["is_public"]
          })
          Sentry.setUser({
            email: response.data["email"],
            username: `${response.data["first_name"]} ${response.data["last_name"]}`
          })
        }
        return response
      },
      error => {
        if (error.response.status === 401) {
        }
        else {
          // console.info(error.response.data)
          // console.info(error.response.status)
        }
        return undefined
      })
  }

  const AdminCheckAuth = async () => {
    return api.get('/api/admin').then(
      response => {
        if (response.status === 200) {
          setUser({
            firstName: response.data['first_name'],
            lastName: response.data['last_name'],
            email: response.data['email'],
            type: "admin"
          })
        }
        return response
      },
      error => {
        if (error.response.status === 401) {
        }
        else {
          // console.info(error.response.data)
          // console.info(error.response.status)
        }
        return undefined
      })
  }

  const AdminGetUsers = () => {
    api.get("/api/admin/users").then(
      response => {
        const users: UserQueryProps[] = []

        response.data.forEach((userData: UserPropsServerResponse) => {
          if (userData["type"] === "parent") {
            const user = {
              firstName: userData["first_name"],
              lastName: userData["last_name"],
              email: userData["email"],
              id: userData["id"],
              type: userData["type"],
              isEmailVerified: userData["is_verified"],
              isPremium: userData["is_premium"],
              gameUserLicenses: userData["game_user_licenses"]
            } as UserQueryProps
            users.push(user)
          }

          if (userData["type"] === "professional") {
            const user = {
              firstName: userData["first_name"],
              lastName: userData["last_name"],
              email: userData["email"],
              id: userData["id"],
              type: userData["type"],
              isEmailVerified: userData["is_verified"],
              isPublic: userData["is_public"]
            } as UserQueryProps
            users.push(user)
          }
        })
        setUsers(users)
      },
      error => {

      }
    )
  }


  const Logout = async () => {
    try {
      await api.post('/api/user/logout')
      setUser({} as UserProps)
      Sentry.setUser(null)
      setUsers([] as UserQueryProps[])
      setGameUsers([])
    } catch (error) {
      console.error(error)
    }
  }

  const RequestLogin = ({ login, password }: LoginProps) => {
    return api.post('/api/user/login', { email: login, password: password }).then(
      response => {
        if (response.status === 200) {
          UserCheckAuth()
        }
        return null
      },
      error => {
        const errors: string[] = []
        if (error.response.status === 404) {
          // console.info(error.response.data.detail)
          errors.push(error.response.data.detail)
        }
        else {
          // console.info(error.response.data)
          // console.info(error.response.status)
          errors.push(error.response.data.detail)
        }
        return errors
      })
  }


  const RequestPasswordRecover = ({ email }: EmailLoginProps) => {
    return api.post(`/api/user/change_password/request`, { "email": email }).then(
      response => {
        return null
      },
      error => {
        const errors: string[] = []
        if (error.response.status === 404) {
          // console.info(error.response.data.detail)
          errors.push(error.response.data.detail)
        }
        else {
          // console.info(error.response.data)
          // console.info(error.response.status)
          errors.push(error.response.data.detail)
        }
        return errors
      })
  }


  const TokenRequestLogin = ({ token }: TokenLoginProps) => {
    return api.get(`/api/user/login/${token}`).then(
      response => {
        if (response.status === 204) {
          UserCheckAuth()
        }
        return null
      },
      error => {
        const errors: string[] = []
        if (error.response.status === 404) {
          // console.info(error.response.data.detail)
          errors.push(error.response.data.detail)
        }
        else {
          // console.info(error.response.data)
          // console.info(error.response.status)
          errors.push(error.response.data.detail)
        }
        return errors
      })
  }


  const CreateUser = async (data: CreateUserData) => {
    return api.post('/api/user', data).then(
      response => {
        if (response.status === 200) {
          UserCheckAuth()
        }
        return null
      },
      error => {
        const errors: string[] = []
        if (error.response.status === 406) {
          errors.push(error.response.data.detail)
        }
        else if (error.response.status === 409) {
          errors.push(error.response.data.detail)
        }
        else {
          errors.push('Internal Server Error')
        }

        return errors
      }
    )
  }

  const GetUser = () => {
    if (user.type === "admin") throw new Error("Wrong user type.")
    return user
  }


  useEffect(() => {
    if (user.type) {
      if (user.type === "admin") {
        return
      }
      getGameUsers()
      getInvites()
    }
  }, [user, getGameUsers])


  return (
    <UserContext.Provider value={{
      user: user,
      users: users,
      GetUser: GetUser,
      invites: invites,
      UserCheckAuth: UserCheckAuth,
      AdminCheckAuth: AdminCheckAuth,
      Logout: Logout,
      getInvites: getInvites,
      RequestLogin: RequestLogin,
      TokenRequestLogin: TokenRequestLogin,
      RequestPasswordRecover: RequestPasswordRecover,
      CreateUser: CreateUser,
      getGameUsers: getGameUsers,
      gameUsers: gameUsers,
      isGameUsersLoading: isGameUsersLoading,
      selectedGameUser: selectedGameUser,
      setSelectedGameUser: setSelectedGameUser,
      AdminGetUsers: AdminGetUsers
    }}>
      {children}
    </UserContext.Provider>
  )
}


export const useAuth = () => {
  return useContext(UserContext)
}