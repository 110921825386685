import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import { useAuth } from "../../contexts/UserContext"
import { RegisterFrame } from "../../frames/RegisterFrame"

import "./RegisterPage.sass"

export const RegisterPage = ({ ...args }) => {
  const auth = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  const [userType, setUserType] = useState<'parent' | 'professional'>('parent')

  const state = location.state as { from: Location }
  const from = state ? state.from.pathname : '/'

  useEffect(() => {
    if (auth.user.firstName) {
      navigate('/')
    } else (
      auth.UserCheckAuth()
        .then((response) => {
          if (response)
            navigate(from, { replace: true })
        })
    )
  }, [auth, navigate, from])

  return (
    <div className={`register-container ${userType}`}>
      <div>
        <RegisterFrame userType={userType} setUserType={setUserType} />
      </div>

      <div>
        <div style={{ backgroundImage: 'url("/background-register-details.svg")', backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "left" }} className={`flex items-center justify-center flex-col h-4/5 w-full ${userType === 'parent' ? 'bg-teal-300' : 'bg-purple-600'} rounded-bl-3xl`}>
          <img className="w-36 h-36 p-2 bg-white rounded-full shadow-2xl" src="/logo.png" alt="" />

        </div>
      </div>
    </div>
  )
}
