import { NavItem } from '../../components/NavItem'
import { IoGameController, IoStatsChart, IoPerson, IoLogOut, IoMedkit, IoMail, IoPeople, IoGift, IoCheckmark } from 'react-icons/io5'
import { Divider, VerticalDivider } from '../../components/Divider'
import { ParentUserPropsPlatform, ProfessionalUserPropsPlatform, useAuth } from '../../contexts/UserContext'
import { Outlet } from 'react-router'
import { NavButton } from '../../components/NavItem'
import { useState } from 'react'
import { Modal } from '../../components/Modal'
import { ParentInvites, ProfessionalInvites } from '../InviteFrame'
import { useTranslation } from 'react-i18next'

interface SidebarProps {
  NavItems: (props: { compact?: boolean }) => JSX.Element
}


export const AdminSidebar = ({ ...args }) => {
  return (
    <div className={`flex flex-col lg:flex-row max-w-screen h-screen lg:max-h-screen pb-20 bg-gray-lightest lg:p-5 gap-0 lg:gap-10 parent`}>

      <WebSideBar NavItems={AdminNavBarItems} />

      <div className="flex flex-col w-full h-full lg:max-h-screen lg:overflow-y-auto overflow-x-hidden p-0 lg:m-0 lg:p-2">
        <Outlet />
      </div>

      <MobileSideBar NavItems={AdminNavBarItems} />
    </div>
  )
}


export const Sidebar = ({ ...args }: {}) => {
  const { user } = useAuth()

  return (
    <div className={`flex flex-col lg:flex-row max-w-screen h-[100dvh] lg:max-h-screen pb-20 lg:p-0 bg-gray-lightest gap-0 lg:gap-10 ${user.type === 'professional' ? 'professional' : 'parent'}`}>

      <WebSideBar NavItems={NavBarItems} />

      <div className="flex flex-col w-full h-full lg:max-h-screen lg:overflow-y-auto overflow-y-hidden overflow-x-hidden p-0 lg:m-0 lg:p-2">
        <Outlet />
      </div>

      <MobileSideBar NavItems={NavBarItems} />
    </div>
  )
}


export function WebSideBar({ NavItems }: SidebarProps) {

  return (
    <div className="hidden lg:flex items-center justify-start p-4 bottom-0 inset-x-0 flex-col h-full w-60 ">
      <div className="flex items-center justify-center w-52 my-7">
        <img className="w-12 h-12 mr-5" src="/logo.png" alt="logo" />
        <p className="font-bold text-xl text-gray-700">TOYT</p>

      </div>
      <Divider />
      <div className="flex flex-col w-full h-full justify-between my-6 ">
        <NavItems />
      </div>
    </div>
  )
}


export function MobileSideBar({ NavItems }: SidebarProps) {

  return (
    <div className="flex items-center fixed justify-start left-0 bottom-0 inset-x-0 flex-row w-full lg:hidden p-3 h-20 bg-gray-100">
      <div className="flex flex-row w-full h-full justify-between mx-6  overflow-y-auto scrollbar-hidden">
        <NavItems compact />
      </div>
    </div>
  )
}


export function NavBarItems({ compact }: { compact?: boolean }) {
  const { user } = useAuth()

  if (user.type === "parent") {
    return (
      <ResponsibleUserItems user={user} compact={compact} />
    )
  }

  if (user.type === "professional") {
    return (
      <ProfessionalUserItems user={user} compact={compact} />
    )
  }

  return (
    <AdminNavBarItems compact={compact} />
  )
}

function ResponsibleUserItems({ user, compact }: { user: ParentUserPropsPlatform, compact?: boolean }) {
  const [showNotifications, setShowNotifications] = useState<boolean>(false)
  const { Logout, invites } = useAuth()
  const { t } = useTranslation()

  return (
    <>
      <div className="flex flex-row lg:flex-col lg:w-full">
        <NavItem compact={compact} path='/dashboard/' Icon={IoStatsChart}>{t('menus.titles.dashboard')}</NavItem>
        <NavItem compact={compact} path='/dashboard/game_user/' Icon={IoGameController}>{t('menus.titles.users')}</NavItem>
        <NavItem compact={compact} path='/dashboard/professionals/' Icon={IoMedkit}>{t('menus.titles.professionals')}</NavItem>
      </div>
      <div className="flex flex-row lg:flex-col lg:w-full">
        <p className="font-bold text-md text-gray-700 ml-2 hidden lg:visible uppercase">{t('menus.titles.account')}</p>
        {compact ? <VerticalDivider /> : <></>}
        <NavItem compact={compact} path='/dashboard/profile/' Icon={IoPerson}>{t('menus.titles.profile')}</NavItem>
        {invites.length > 0 && <NavButton compact={compact} Icon={IoMail} onClick={e => { e.stopPropagation(); setShowNotifications(true) }}>{t('menus.titles.parentInvites')}</NavButton>}
        <NavItem compact={compact} path='/login' Icon={IoLogOut} onClick={Logout}>{t('menus.titles.logout')}</NavItem>

      </div>

      <Modal show={showNotifications} onClose={() => setShowNotifications(false)}>
        <ParentInvites />
      </Modal>
    </>
  )
}

function ProfessionalUserItems({ user, compact }: { user: ProfessionalUserPropsPlatform, compact?: boolean }) {
  const [showNotifications, setShowNotifications] = useState<boolean>(false)
  const { Logout, invites } = useAuth()
  const { t } = useTranslation()

  return (
    <>
      {
        user.professionalStatus === "ACCEPTED" ? (
          <div className="flex flex-row lg:flex-col lg:w-full">
            <NavItem compact={compact} path='/dashboard/' Icon={IoStatsChart}>{t('menus.titles.dashboard')}</NavItem>
            <NavItem compact={compact} path='/dashboard/game_user/' Icon={IoGameController}>{t('menus.titles.users')}</NavItem>
          </div>
        ) : (
          <div className="flex flex-row lg:flex-col lg:w-full">
            <NavItem compact={compact} path='/dashboard/' Icon={IoCheckmark}>{t('menus.titles.validation')}</NavItem>
          </div>
        )

      }
      <div className="flex flex-row lg:flex-col lg:w-full">
        <p className="font-bold text-md text-gray-700 ml-2 hidden lg:visible uppercase">{t('menus.titles.account')}</p>
        {compact ? <VerticalDivider /> : <></>}
        <NavItem compact={compact} path='/dashboard/profile/' Icon={IoPerson}>{t('menus.titles.profile')}</NavItem>
        {invites.length > 0 && <NavButton compact={compact} Icon={IoMail} onClick={e => { e.stopPropagation(); setShowNotifications(true) }}>{t('menus.titles.invites')}</NavButton>}
        <NavItem compact={compact} path='/login' Icon={IoLogOut} onClick={Logout}>{t('menus.titles.logout')}</NavItem>
      </div>

      <Modal show={showNotifications} onClose={() => setShowNotifications(false)}>
        <ProfessionalInvites />
      </Modal>
    </>
  )
}


export function AdminNavBarItems({ compact }: { compact?: boolean }) {
  const { Logout } = useAuth()
  const [showNotifications, setShowNotifications] = useState(false)

  const { t } = useTranslation()

  return (
    <>
      <div className="flex flex-row lg:flex-col lg:w-full">
        <NavItem compact={compact} path='/admin/dashboard/' Icon={IoPeople}>{t('menus.titles.users')}</NavItem>
        <NavItem compact={compact} path='/admin/dashboard/gift_code' Icon={IoGift}>{t('menus.titles.gift_code')}</NavItem>
        <NavItem compact={compact} path='/admin/dashboard/validation' Icon={IoCheckmark}>{t('menus.titles.validation')}</NavItem>
      </div>
      <div className="flex flex-row lg:flex-col lg:w-full">
        <p className="font-bold text-md text-gray-700 ml-2 hidden lg:visible uppercase">{t('menus.titles.account')}</p>
        {compact ? <VerticalDivider /> : <></>}
        <NavItem compact={compact} path='/admin/dashboard/profile/' Icon={IoPerson}>{t('menus.titles.profile')}</NavItem>
        <NavItem compact={compact} path='/admin/login' Icon={IoLogOut} onClick={Logout}>{t('menus.titles.logout')}</NavItem>

      </div>

      <Modal show={showNotifications} onClose={() => setShowNotifications(false)}>
        <div className="flex items-center justify-center h-full font-bold">Soon...</div>
      </Modal>

    </>)
}