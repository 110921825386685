import { FormEvent, useState } from "react"
import { useTranslation } from "react-i18next"
import { PasswordInput } from "../../../../../components/Input"
import { WhiteLoadingRing } from "../../../../../components/Loading"
import { useWarnings } from "../../../../../contexts/WarningContext"
import api from "../../../../../services/api"
import "./ChangePassword.sass"

export const ChangePassword = () => {
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [isVisible, setIsVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { t } = useTranslation()
  const { setWarning } = useWarnings()

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (newPassword !== confirmPassword) {
      setWarning({ warning: 'As senhas não coincidem', type: 'warning', duration: 5 })
      return
    }

    setIsLoading(true)

    api.post("/api/user/change_password", { current_password: password, new_password: newPassword })
      .then(
        response => {
          setWarning({ warning: 'Senha alterada', type: 'success', duration: 5 })
          setPassword('')
          setNewPassword('')
          setConfirmPassword('')
        },
        error => {
          error.response.status === 403 && setWarning({ warning: 'Esta não é sua senha atual', type: 'error', duration: 5 })
          error.response.status === 500 && setWarning({ warning: 'Algo deu errado, tente novamente.', type: 'error', duration: 5 })
        })
      .finally(() => { setIsLoading(false) })

  }

  return (
    <div className="settings-account">
      <form onSubmit={handleSubmit} className="auth">
        <h3>{t('profile.cards.settings.passwordChange.passwordChange')}</h3>
        <PasswordInput label={t('profile.cards.settings.passwordChange.currentPassword')} value={password} setValue={setPassword} isPasswordVisible={isVisible} setIsPasswordVisible={setIsVisible} />
        <PasswordInput label={t('profile.cards.settings.passwordChange.newPassword')} value={newPassword} setValue={setNewPassword} isPasswordVisible={isVisible} setIsPasswordVisible={setIsVisible} />
        <PasswordInput label={t('profile.cards.settings.passwordChange.confirmPassword')} value={confirmPassword} setValue={setConfirmPassword} isPasswordVisible={isVisible} setIsPasswordVisible={setIsVisible} />
        <button>
          {!isLoading && t('profile.cards.settings.passwordChange.passwordChange')}
          {isLoading && (<div className="div"><WhiteLoadingRing /></div>)}
        </button>
      </form>
    </div>
  )
}