import { ThreeStepSwitch } from "../../../../../components/Button"
import { useAuth } from "../../../../../contexts/UserContext"
import { useCallback, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import api from "../../../../../services/api"
import { useImmer } from "use-immer"
import "../GameSettings.sass"
import { BsFillLockFill, BsFillUnlockFill } from "react-icons/bs";
import _ from "lodash"

export interface GameActivityInfo {
  activityId: number
  enabled: boolean | null
}



export const GameActivity = () => {
  const { t } = useTranslation()

  const [activitiesCurrent, setActivitiesCurrent] = useImmer<GameActivityInfo[]>([])
  const [activities, setActivities] = useImmer<GameActivityInfo[]>([])
  const gameUserIndex = useParams().index
  const { gameUsers } = useAuth()


  const changeAllActivities = useMemo<boolean | null>(() => {
    if (activities.length === 0) return null
    let result: boolean | null = activities[0].enabled
    activities.forEach(activity => {
      if (result !== activity.enabled) {
        result = null
      }
    })
    return result
  }, [activities])


  useEffect(() => {
    if ((gameUsers.length > 0) && gameUserIndex) {
      api.get(`/api/user/game_user/${gameUsers[parseInt(gameUserIndex)].id}/activities`).then(
        response => {
          setActivities([...response.data])
          setActivitiesCurrent([...response.data])
        },
        error => {

        })
    }
  }, [gameUserIndex, gameUsers, setActivities, setActivitiesCurrent])

  const submitChanges = useCallback(() => {
    if ((gameUsers.length > 0) && gameUserIndex) {
      if (!(_.isEqual(activities, activitiesCurrent))) {
        api.post(`/api/user/game_user/${gameUsers[parseInt(gameUserIndex)].id}/activities`, activities).then(
          response => { }
        ).catch(error => { })
      }
    }
  }, [activities, activitiesCurrent, gameUserIndex, gameUsers])

  useEffect(() => {
    let submit: null | (() => void) = submitChanges
    let timeout = setTimeout(() => {
      if (submit !== null) submit();
    }, 500)

    return () => {
      submit = null
      clearTimeout(timeout)
    }
  }, [activities, submitChanges])

  return (
    <div className="settings-section game-activities">
      <div className="title">
        <h2>
          {t('gameUserProfile.settings.gameSettings.gameActivities')}
        </h2>

        <ThreeStepSwitch
          state={changeAllActivities}
          buttonFunctions={[
            () => {
              setActivities(activities => {
                for (let i = 0; i < activities.length; i++) {
                  activities[i].enabled = false
                }
              })
            },
            null,
            () => {
              setActivities(activities => {
                for (let i = 0; i < activities.length; i++) {
                  activities[i].enabled = true
                }
              })
            }
          ]}
          label={[<BsFillLockFill />, t("level.custom"), <BsFillUnlockFill />]}

        />

      </div>

      <ul className="options">
        {activities.map((activity, index) => (
          <li key={activity.activityId} className="option">
            <p>{t([`activity.${activity.activityId}`, 'activity.notFound'])}</p>
            <ThreeStepSwitch
              buttonFunctions={[
                () => {
                  setActivities(activities => {
                    activities[index].enabled = false
                  })
                },
                () => {
                  setActivities(activities => {
                    activities[index].enabled = null
                  })
                },
                () => {
                  setActivities(activities => {
                    activities[index].enabled = true
                  })
                }
              ]}
              state={activity.enabled}
              label={[<BsFillLockFill />, t("level.auto"), <BsFillUnlockFill />]}

            />
          </li>
        ))}
      </ul>
    </div>
  )
}
