import { File } from "../../../components/Input"
import { FullLoadingDots } from "../../../components/Loading"
import { RemoveFile } from "../../../components/RemoveFile"
import { useModal } from "../../../contexts/ModalContext"
import { UserQueryProps } from "../../../contexts/UserContext"
import { AdminUserProfileHeader } from "../../../frames/ProfileHeader"
import { APIFile } from "../../Professional/Resend"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import api from "../../../services/api"
import "./Validation.sass"

export const Validation = () => {
  const location = useLocation()

  const { t } = useTranslation()
  const { setModal, setOnModalClose } = useModal()
  const navigate = useNavigate()
  const params = useParams()
  const state = location.state as { from: Location }
  const from = state ? state.from.pathname : '/admin/dashboard/'

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [user, setUser] = useState<UserQueryProps>({} as UserQueryProps)
  const [documents, setDocuments] = useState<APIFile[]>()

  const checkServerFiles = useCallback(
    (update?: boolean) => {
      if (!params.id) return;
      if (documents !== undefined && !update) return;
      setIsLoading(true)

      api.get(`/api/admin/user/${params.id}`).then(
        response => {
          if (response.data['type'] === "professional") {
            setUser({
              id: response.data['id'],
              email: response.data['email'],
              firstName: response.data['first_name'],
              lastName: response.data['last_name'],
              isEmailVerified: response.data['is_verified'],
              type: response.data['type'],
              professionalStatus: response.data['professional_status'],
            } as UserQueryProps)

          } else {
            setUser({
              id: response.data['id'],
              email: response.data['email'],
              firstName: response.data['first_name'],
              lastName: response.data['last_name'],
              isEmailVerified: response.data['is_verified'],
              type: response.data['type'],
              isPremium: response.data['is_premium'],
              gameUserLicenses: response.data['game_user_licenses'],

            } as UserQueryProps)
          }

          api.get<APIFile[]>(`/api/admin/user/${params.id}/documents`).then(
            response => {
              setDocuments([...response.data])
            },
            errors => {

            }
          ).finally(() => { setIsLoading(false) })
        },
        error => {
          navigate("/admin/dashboard")
        }
      )
    },
    [params, navigate, documents, setDocuments],
  )


  useEffect(() => {
    checkServerFiles()
  }, [checkServerFiles])


  if (isLoading) {
    return <FullLoadingDots />
  }

  if (!user && !isLoading) {
    // console.log('redirecting')
    return <Navigate to={from} />
  }

  function removeFile(file: APIFile) {
    setOnModalClose(() => (() => { checkServerFiles(true) }))
    setModal({ element: <RemoveFile file={file} />, userType: "professional" })
  }


  return (
    <div className="validation-container">
      <AdminUserProfileHeader user={{ ...user, gameUsers: [] }} callback={() => { checkServerFiles(true) }} />
      <div className="title">
        <h1>{t("professional.verify.documents")}</h1>
      </div>
      <div className="content">
        {
          documents !== undefined && documents.length > 0 ? (
            documents.map((file) => (
              <File file={file} removeAPIFile={removeFile} key={file.id} />
            ))) : (
            <p>{t("professional.noFileFound")}</p>
          )
        }
      </div>

      {isLoading && <FullLoadingDots />}
    </div>
  )
}