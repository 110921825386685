import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { IoClose, IoInformation } from "react-icons/io5"
import { Navigate, useNavigate } from "react-router-dom"
import { Updater, useImmer } from "use-immer"
import { PrimaryButton } from "../../../components/Button"
import { FileInput } from "../../../components/Input"
import { RemoveFile } from "../../../components/RemoveFile"
import { useModal } from "../../../contexts/ModalContext"
import { useAuth } from "../../../contexts/UserContext"
import { useWarnings } from "../../../contexts/WarningContext"
import { APIValidation, ValidationInfo } from "../../../pages/Admin/Validation/ValidationFunctions"
import api from "../../../services/api"
import { useWindowDimensions } from "../../../utils/WindowDimensions"
import "../Professional.sass"

export interface APIFile {
  id: string
  name: string
  content_type: string
  link: string
}

interface ResendInterface {
  files: File[]
  setFiles: Updater<File[]>
  apiFiles: APIFile[]
  setApiFiles: Updater<APIFile[]>
  isLoading: boolean
  removeFile: (index: number) => void
  removeFileFromAPI: (file: APIFile) => void
  splitFiles: (files: FileList) => void
  submitFiles: () => void
  validations: APIValidation[]
}

export function WebResend({ files, setFiles, removeFile, removeFileFromAPI, splitFiles, apiFiles, submitFiles, isLoading, validations }: ResendInterface) {
  const { t } = useTranslation()

  return (
    <div className="verify-container">
      <div className="left-container">
        <div className="title">
          <h1>
            {t('professional.resend.title')}
          </h1>

          <h2>
            {t('professional.resend.subtitle')}
          </h2>
        </div>

        <form className="content">
          <FileInput
            files={files}
            apiFiles={apiFiles}
            multiple={true}
            label={t('professional.verify.items')}
            removeFile={removeFile}
            removeAPIFile={removeFileFromAPI}
            onChange={e => {
              e.target.files !== null && splitFiles(e.target.files)
            }}
          />

          <PrimaryButton
            label={t('professional.verify.title')}
            onClick={submitFiles}
            isLoading={isLoading}
          />

        </form>
      </div>
      <div className="right-container">
        <div className="content">
          {
            validations.length > 0 && (
              <ValidationInfo validation={validations[0]} />

            )
          }
        </div>
      </div>
    </div>
  )
}
export function MobileResend({ files, setFiles, removeFile, removeFileFromAPI, splitFiles, apiFiles, submitFiles, isLoading, validations }: ResendInterface) {
  const { t } = useTranslation()

  const [isVisible, setIsVisible] = useState(true)

  return (
    <div className="verify-container mobile relative">
      <button onClick={e => { e.preventDefault(); e.stopPropagation(); setIsVisible(true) }} className="information" type="button">
        <IoInformation />
      </button>
      <div className="title">
        <h1>
          {t('professional.resend.title')}
        </h1>

        <h2>
          {t('professional.resend.subtitle')}
        </h2>
      </div>

      <form className="content">
        <FileInput
          files={files}
          apiFiles={apiFiles}
          multiple={true}
          label={t('professional.verify.items')}
          removeFile={removeFile}
          removeAPIFile={removeFileFromAPI}
          onChange={e => {
            e.target.files !== null && splitFiles(e.target.files)
          }}
        />

        <PrimaryButton
          label={t('professional.verify.title')}
          onClick={submitFiles}
          isLoading={isLoading}
        />

      </form>
      {
        isVisible && (
          <div onClick={e => { e.preventDefault(); e.stopPropagation(); setIsVisible(false) }} className="z-10 w-screen h-screen flex justify-end absolute top-0 left-0 animate-smooth-in bg-gray-700 bg-opacity-80 cursor-pointer">
            <button onClick={e => { e.preventDefault(); e.stopPropagation(); setIsVisible(false) }} className="z-10 absolute flex items-center justify-center top-4 right-4 w-6 h-6 text-gray-700" type="button">
              <IoClose className="text-gray-700" />
            </button>
            <div onClick={e => { e.preventDefault(); e.stopPropagation() }} className="flex justify-center h-full w-full max-w-lg animate-right-slide-in bg-white px-4 cursor-default">
              {validations.length > 0 && <ValidationInfo validation={validations[0]} />}
            </div>
          </div>
        )
      }
    </div>
  )
}


export function Resend() {
  const { width } = useWindowDimensions()
  const { t } = useTranslation()
  const { user, UserCheckAuth } = useAuth()
  const [apiFiles, setApiFiles] = useImmer<APIFile[]>([])
  const [files, setFiles] = useImmer<File[]>([])
  const { setModal, setOnModalClose } = useModal()
  const { setWarning } = useWarnings()

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [validations, setValidations] = useState<APIValidation[]>([])

  function splitFiles(files: FileList) {
    const splittedFiles: File[] = []

    for (let i = 0; i < files.length; i++) {
      const file: File | null = files.item(i)
      if (file !== null) splittedFiles.push(file)
    }
    setFiles(files => [...files, ...splittedFiles])
  }

  function removeFile(index: number) {
    setFiles(files => { files.splice(index, 1) })
  }

  const submitFiles = () => {
    if (files.length <= 0) {
      setWarning({ warning: t("professional.resend.noFile"), type: "warning" })
      return
    }

    setIsLoading(true)
    const formData = new FormData()
    files.forEach(file => formData.append("documents", file))

    api.post("/api/user/professional/documents", formData).then(
      response => {
        UserCheckAuth().then(() => {
          navigate("/dashboard")
        }).finally(() => {
          setIsLoading(false)
        })
      },
      error => { setIsLoading(false); setWarning({ warning: t([`errors.${error.data.details}`, "errors.undefined"]), type: "error" }) }
    )
  }

  const checkAPIFiles = useCallback(() => {
    api.get<APIFile[]>("/api/user/professional/documents").then(
      response => {
        setApiFiles([...response.data])
      },
      error => {

      }
    )
  }, [setApiFiles])

  const removeFileFromAPI = (file: APIFile) => {
    setOnModalClose(() => (() => { checkAPIFiles() }))
    setModal({ element: <RemoveFile file={file} />, userType: "professional" })

  }

  useEffect(() => {
    checkAPIFiles()
    api.get<APIValidation[]>("/api/user/professional/validation").then(
      response => {
        setValidations([...response.data])
      },
      error => {

      }
    )
  }, [checkAPIFiles])


  if (user.type === "parent" || (user.type === "professional" && user.professionalStatus !== "RESEND")) {
    return (
      <Navigate to="/dashboard" />
    )
  }

  return (
    <>
      {
        width >= 1024 ? (
          <WebResend
            files={files}
            setFiles={setFiles}
            apiFiles={apiFiles}
            setApiFiles={setApiFiles}
            isLoading={isLoading}
            removeFile={removeFile}
            removeFileFromAPI={removeFileFromAPI}
            splitFiles={splitFiles}
            submitFiles={submitFiles}
            validations={validations}

          />
        ) : (
          <MobileResend
            files={files}
            setFiles={setFiles}
            apiFiles={apiFiles}
            setApiFiles={setApiFiles}
            isLoading={isLoading}
            removeFile={removeFile}
            removeFileFromAPI={removeFileFromAPI}
            splitFiles={splitFiles}
            submitFiles={submitFiles}
            validations={validations}

          />
        )
      }
    </>
  )
}
