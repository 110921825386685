import { useWarnings } from "../../contexts/WarningContext"
import { Dispatch, FormEvent, SetStateAction, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"
import { TextInput } from "../../components/Input"
import { LoadingDots } from "../../components/Loading"
import { useAuth } from "../../contexts/UserContext"

import "./RegisterFrame.sass"

export const RegisterFrame = ({ userType, setUserType }: { userType: 'parent' | 'professional', setUserType: Dispatch<SetStateAction<'parent' | 'professional'>> }) => {
  const { setWarning } = useWarnings()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const { CreateUser } = useAuth()

  const [firstNameErrors, setFirstNameErrors] = useState<string[]>([])
  const [lastNameErrors, setLastNameErrors] = useState<string[]>([])
  const [emailErrors, setEmailErrors] = useState<string[]>([])
  const [passwordErrors, setPasswordErrors] = useState<string[]>([])
  const [confirmPasswordErrors, setConfirmPasswordErrors] = useState<string[]>([])
  const [serverError, setServerError] = useState<string[]>([])

  const [isLoading, setIsLoading] = useState(false)

  const { t } = useTranslation()

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()

    if (password !== confirmPassword) {
      setWarning({
        "warning": t('login.errors.passwords_mismatch'),
        "type": "warning"
      })
      return
    }


    if (password.length < 6) {
      setWarning({
        "warning": t('login.errors.short_password'),
        "type": "warning"
      })
      return
    }


    const data = { type: userType, first_name: firstName, last_name: lastName, email: email, password: password }

    setIsLoading(true)
    CreateUser(data).then(error => {
      if (error !== null) {
        setServerError(error)
      }
      setIsLoading(false)
    })
  }

  useEffect(() => {
    const errors = []
    if (firstName.length < 2) {
      errors.push("Invalid Name")
    }

    setFirstNameErrors(errors)
  }, [firstName])

  useEffect(() => {
    const errors = []
    if (lastName.length < 2) {
      errors.push("Invalid Name")
    }

    setLastNameErrors(errors)

  }, [lastName])

  useEffect(() => {
    const errors = []
    const mail_format = /^[^\s@]+@[^\s@]+(\.[^\s@]+)+$/;
    if (!email.match(mail_format)) {
      errors.push('Invalid Email')
    }

    setEmailErrors(errors)

  }, [email])

  useEffect(() => {
    const errors = []
    if (password.length < 6) {
      errors.push("Short Password")
    }
    setPasswordErrors(errors)

  }, [password])

  useEffect(() => {
    const errors = []
    if (confirmPassword !== password) {
      errors.push("Short Password")
    }
    setConfirmPasswordErrors(errors)

  }, [confirmPassword, password])


  return (
    <div className={`register-frame ${userType}`} >
      <div className="logo">
        <img src="/logo.png" alt="Logo" />
      </div>
      <div className="switch">
        <button onClick={() => setUserType('parent')} className={`${userType === 'parent' && 'active'}`}>{t('login.parent')}</button>
        <button onClick={() => setUserType('professional')} className={`${userType === 'professional' && 'active'}`}>{t('login.professional')}</button>

      </div>
      <form onSubmit={handleSubmit}>
        <TextInput label={t('login.firstName')} value={firstName} setValue={setFirstName} placeholder={t('login.placeholders.firstName')} errors={firstNameErrors} required />
        <TextInput label={t('login.lastName')} value={lastName} setValue={setLastName} placeholder={t('login.placeholders.lastName')} errors={lastNameErrors} required />

        <TextInput label={t('login.email')} value={email} setValue={setEmail} placeholder={t('login.placeholders.email')} errors={emailErrors} required />
        <TextInput label={t('login.password')} value={password} setValue={setPassword} placeholder={t('login.placeholders.password')} errors={passwordErrors} type="password" required />
        <TextInput label={t('login.confirmPassword')} value={confirmPassword} setValue={setConfirmPassword} placeholder={t('login.placeholders.password')} errors={confirmPasswordErrors} type="password" required />

        {
          serverError.map(error => (
            <p className="flex font-semibold text-red-800">{error}</p>
          ))
        }

        <div>
          <button type='submit'>{t('login.register')}</button>
          <p>{t('login.hasAccount')} <NavLink to="/login"><b className="text-teal-300 filter hover:text-teal-400 transition">{t('login.login')}</b></NavLink></p>

        </div>
      </form>

      {
        isLoading &&
        <div className="flex items-center justify-center absolute w-full h-full bg-gray-800 opacity-25 ">
          <LoadingDots />
        </div>
      }
    </div>
  )
}