import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react'
import { IconType } from 'react-icons/lib'
import { NavLink } from "react-router-dom"
import './NavItem.sass'

interface NavItemsArgs {
  path: string
  compact?: boolean
  children?: string
  Icon: IconType
  onClick?: () => void;
}

interface NavButtonArgs extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  compact?: boolean
  children?: string
  Icon: IconType
}

export const NavItem = ({ path, children, compact, Icon, ...args }: NavItemsArgs) => {

  return (
    <NavLink className={({ isActive }) => ['NavLink', compact ? '' : 'w-full', 'py-3', 'px-1', isActive ? 'bg-white active text-gray-700 shadow-xl' : 'text-gray-400 filter drop-shadow-xl', 'rounded-xl', 'hover:text-gray-700'].join(" ")} to={`${path}`} {...args} >
      <div className="flex items-center h-full w-full p-1 transition">
        {<Icon className="icon transition" />}
        {compact ? <></> : <p className="font-bold">{children}</p>}
      </div>
    </NavLink>
  )
}


export const NavButton = ({ children, compact, Icon, ...args }: NavButtonArgs) => {
  return (
    <button className={['NavLink', compact ? '' : 'w-52', 'py-3', 'px-1', 'text-gray-400 filter drop-shadow-xl', 'rounded-xl', 'hover:text-gray-700'].join(" ")} {...args} >
      <div className="flex items-center h-full w-full p-1 transition">
        {<Icon className="icon transition" />}
        {compact ? <></> : <p className="font-bold">{children}</p>}
      </div>
    </button>
  )
}
