import _ from "lodash"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Navigate, useLocation, useParams } from "react-router-dom"
import { useImmer } from "use-immer"
import { ThreeStepSwitch } from "../../../../../components/Button"
import { VerticalDivider } from "../../../../../components/Divider"
import { useAuth } from "../../../../../contexts/UserContext"
import { useWarnings } from "../../../../../contexts/WarningContext"
import api from "../../../../../services/api"

import "../GameSettings.sass"
import "./GameClothes.sass"
import { Clothes } from "./Clothes"

export interface ClothesInterface {
  id: number
  enabled: boolean
  clothes_class: string[]
  clothes_weather: string[]
  clothes_gender: string[]
}

export const GameClothes = () => {
  const { setWarning } = useWarnings()
  const { t } = useTranslation(["clothes", "translation"])
  const location = useLocation()

  const [clothes, setClothes] = useImmer<ClothesInterface[]>([])
  const [serverClothes, setServerClothes] = useImmer<ClothesInterface[]>([])

  const [hasChanged, setHasChanged] = useState<boolean>(false)

  const [classes,] = useState<string[]>([])
  const [weather,] = useState<string[]>([])
  const [genders,] = useState<string[]>([])

  const changeAllClasses = useMemo<Record<string, boolean | null>>(() => {
    const changeAllClasses: Record<string, boolean | null> = {}
    clothes.forEach(clothes => {
      clothes.clothes_class.forEach(classes => {
        if (changeAllClasses[classes] === undefined) {
          changeAllClasses[classes] = clothes.enabled
        } else if (changeAllClasses[classes] !== clothes.enabled) {
          changeAllClasses[classes] = null
        }
      })
    })
    return changeAllClasses
  }, [clothes])

  const changeAllWeather = useMemo<Record<string, boolean | null>>(() => {
    const changeAllWeather: Record<string, boolean | null> = {}
    clothes.forEach(clothes => {
      clothes.clothes_weather.forEach(weather => {
        if (changeAllWeather[weather] === undefined) {
          changeAllWeather[weather] = clothes.enabled
        } else if (changeAllWeather[weather] !== clothes.enabled) {
          changeAllWeather[weather] = null
        }
      })
    })
    return changeAllWeather

  }, [clothes])

  const changeAllGenders = useMemo<Record<string, boolean | null>>(() => {
    const changeAllGenders: Record<string, boolean | null> = {}
    clothes.forEach(clothes => {
      clothes.clothes_gender.forEach(gender => {
        if (changeAllGenders[gender] === undefined) {
          changeAllGenders[gender] = clothes.enabled
        } else if (changeAllGenders[gender] !== clothes.enabled) {
          changeAllGenders[gender] = null
        }
      })
    })
    return changeAllGenders
  }, [clothes])

  const [organizeBy, setOrganizeBy] = useState<'class' | 'weather' | 'gender'>("class")

  const state = location.state as { from: Location }
  const from = state ? state.from.pathname : '/dashboard/'

  const gameUserIndex = useParams().index
  const { gameUsers } = useAuth()

  const getServerData = useCallback((changeCurrentClothes: boolean = true) => {
    if (gameUserIndex === undefined) {
      return
    }
    if (parseInt(gameUserIndex) >= gameUsers.length) {
      return
    }

    const id = gameUsers[parseInt(gameUserIndex)].id


    api.get<ClothesInterface[]>(`/api/user/game_user/${id}/clothes`)
      .then(
        response => {
          if (changeCurrentClothes) {
            setClothes([...response.data])
          }

          setServerClothes([...response.data])
        }
      )
      .catch(error => {
        setWarning({
          warning: t([`errors.${error.response.data.detail}`, 'errors.undefined'], { ns: "translation" }),
          type: 'error'
        })
      })
  }, [gameUserIndex, gameUsers, setClothes, setServerClothes, setWarning, t])

  useEffect(() => {
    getServerData()
  }, [getServerData])

  useEffect(() => {
    if (!_.isEqual(clothes, serverClothes)) {
      setHasChanged(true)
    } else {
      setHasChanged(false)
    }

    clothes.forEach(clothes => {
      clothes.clothes_class.forEach(clothes_classes => {
        if (classes.indexOf(clothes_classes) < 0) {
          classes.push(clothes_classes)
        }
      })

      clothes.clothes_weather.forEach(clothes_weather => {
        if (weather.indexOf(clothes_weather) < 0) {
          weather.push(clothes_weather)
        }
      })

      clothes.clothes_gender.forEach(clothes_genders => {
        if (genders.indexOf(clothes_genders) < 0) {
          genders.push(clothes_genders)
        }
      })
    })

  }, [clothes, serverClothes, classes, weather, genders, hasChanged])



  const submitChanges = useCallback(() => {
    if (gameUserIndex === undefined) return;
    if (_.isEqual(clothes, serverClothes)) return;

    const id = gameUsers[parseInt(gameUserIndex)].id

    api.post(`/api/user/game_user/${id}/clothes/block_clothes`, clothes).then(
      response => {
        getServerData(false)
        // setWarning({ warning: t("gameUserProfile.settings.gameClothes.success", { ns: "translation" }), type: "success" })
      },
      error => {
        setWarning({ warning: t([`errors.${error.data}`, 'errors.undefined']), type: "error" })
      }
    )
  }, [clothes, serverClothes, gameUserIndex, gameUsers, getServerData, setWarning, t])


  useEffect(() => {
    let submit: null | (() => void) = submitChanges
    let timeout = setTimeout(() => {
      if (submit !== null) submit();
    }, 500)

    return () => {
      submit = null
      clearTimeout(timeout)
    }
  }, [clothes, submitChanges])


  const GenderClothes = useCallback(({ clothes, genders, changeAllGenders }: { clothes: ClothesInterface[], genders: string[], changeAllGenders: Record<string, boolean | null> }) => {
    return (
      <>
        {genders.map(clothes_gender => {
          return (
            <ul key={`clothes-gender-${clothes_gender}`} className="options">
              <div className="title">
                <h2>{t(`clothes.gender.${clothes_gender}`, { ns: "clothes" })}</h2>
                <ThreeStepSwitch
                  state={changeAllGenders[clothes_gender]}
                  buttonFunctions={[
                    () => {
                      setClothes(stateClothes => {
                        for (let i = 0; i < stateClothes.length; i++) {
                          if (stateClothes[i]['clothes_gender'].indexOf(clothes_gender) !== -1) {
                            stateClothes[i].enabled = false
                          }
                        }
                      })
                      changeAllGenders[clothes_gender] = false
                    },
                    null,
                    () => {
                      setClothes(stateClothes => {
                        for (let i = 0; i < stateClothes.length; i++) {
                          if (stateClothes[i]['clothes_gender'].indexOf(clothes_gender) !== -1) {
                            stateClothes[i].enabled = true
                          }
                        }
                      })
                      changeAllGenders[clothes_gender] = true
                    }
                  ]}

                />
              </div>
              <div className="clothes-options">

                {
                  clothes.filter((clothes) => clothes.clothes_gender.indexOf(clothes_gender) >= 0).map(currentClothes => {
                    return (
                      <Clothes
                        key={currentClothes.id}
                        clothes={currentClothes}
                        setClothes={setClothes}

                      />
                    )
                  })
                }
              </div>
            </ul>
          )
        })}
      </>
    )
  }, [setClothes, t])

  const ClassClothes = useCallback(({ clothes, classes, changeAllClasses }: { clothes: ClothesInterface[], classes: string[], changeAllClasses: Record<string, boolean | null> }) => {
    return (
      <>
        {classes.map(clothes_class => {
          return (
            <ul key={`clothes-class-${clothes_class}`} className="options">
              <div className="title">
                <h2>{clothes_class}</h2>
                <ThreeStepSwitch
                  state={changeAllClasses[clothes_class]}
                  buttonFunctions={[
                    () => {
                      setClothes(stateClothes => {
                        for (let i = 0; i < stateClothes.length; i++) {
                          if (stateClothes[i]['clothes_class'].indexOf(clothes_class) !== -1) {
                            stateClothes[i].enabled = false
                          }
                        }
                      })
                      changeAllClasses[clothes_class] = false
                    },
                    null,
                    () => {
                      setClothes(stateClothes => {
                        for (let i = 0; i < stateClothes.length; i++) {
                          if (stateClothes[i]['clothes_class'].indexOf(clothes_class) !== -1) {
                            stateClothes[i].enabled = true
                          }
                        }
                      })
                      changeAllClasses[clothes_class] = true
                    }

                  ]}
                />
              </div>
              <div className="clothes-options">
                {
                  clothes.filter((clothes) => clothes.clothes_class.indexOf(clothes_class) >= 0).map(currentClothes => {
                    return (
                      <Clothes
                        key={currentClothes.id}
                        clothes={currentClothes}
                        setClothes={setClothes}

                      />
                    )
                  })
                }
              </div>
            </ul>
          )
        })}
      </>
    )
  }, [setClothes])

  const WeatherClothes = useCallback(({ clothes, weather, changeAllWeather }: { clothes: ClothesInterface[], weather: string[], changeAllWeather: Record<string, boolean | null> }) => {
    return (
      <>
        {weather.map(clothes_weather => {
          return (
            <ul key={`clothes-weather-${clothes_weather}`} className="options">
              <div className="title">
                <h2>{clothes_weather}</h2>
                <ThreeStepSwitch
                  state={changeAllWeather[clothes_weather]}
                  buttonFunctions={[
                    () => {
                      setClothes(stateClothes => {
                        for (let i = 0; i < stateClothes.length; i++) {
                          if (stateClothes[i]['clothes_weather'].indexOf(clothes_weather) !== -1) {
                            stateClothes[i].enabled = false
                          }
                        }
                      })
                      changeAllWeather[clothes_weather] = false
                    },
                    null,
                    () => {
                      setClothes(stateClothes => {
                        for (let i = 0; i < stateClothes.length; i++) {
                          if (stateClothes[i]['clothes_weather'].indexOf(clothes_weather) !== -1) {
                            stateClothes[i].enabled = true
                          }
                        }
                      })
                      changeAllWeather[clothes_weather] = true
                    }
                  ]}
                />
              </div>
              <div className="clothes-options">

                {
                  clothes.filter((clothes) => clothes.clothes_weather.indexOf(clothes_weather) >= 0).map(currentClothes => {
                    return (
                      <Clothes
                        key={currentClothes.id}
                        clothes={currentClothes}
                        setClothes={setClothes}
                      />
                    )
                  })
                }
              </div>
            </ul>
          )
        })}
      </>
    )
  }, [setClothes])

  if (clothes.length === 0) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        {t("gameUserProfile.settings.gameClothes.noClothes")}
      </div>
    )
  }

  if (gameUserIndex === undefined) {
    return <Navigate to={from} />
  }

  if (parseInt(gameUserIndex) > gameUsers.length) {
    return <Navigate to={from} />
  }

  return (
    <div className="settings-section">
      <div className="header">
        <h2>
          {t('gameUserProfile.settings.gameClothes.title', { ns: 'translation' })}
        </h2>

        <div className="organize">
          <button
            className={organizeBy === "class" ? "active" : ""}
            type="button"
            onClick={e => {
              e.preventDefault()
              setOrganizeBy("class")
            }}>
            Class
          </button>
          <VerticalDivider />
          <button
            className={organizeBy === "weather" ? "active" : ""}
            type="button"
            onClick={e => {
              e.preventDefault()
              setOrganizeBy("weather")
            }}>
            Weather
          </button>

          <button
            className={organizeBy === "gender" ? "active" : ""}
            type="button"
            onClick={e => {
              e.preventDefault()
              setOrganizeBy("gender")
            }}>
            Gender
          </button>
        </div>
      </div>

      {organizeBy === "class" && <ClassClothes clothes={clothes} classes={classes} changeAllClasses={changeAllClasses} />}
      {organizeBy === "weather" && <WeatherClothes clothes={clothes} weather={weather} changeAllWeather={changeAllWeather} />}
      {organizeBy === "gender" && <GenderClothes clothes={clothes} genders={genders} changeAllGenders={changeAllGenders} />}

    </div>
  )
}