import { Background } from "../../components/Background"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import { Outlet } from "react-router-dom"
import { useAuth } from "../../contexts/UserContext"

import './LoginPage.sass'

export const LoginPage = ({ ...args }) => {
  const [navigating, setNavigating] = useState(false)
  const [lastUsername, setLastUsername] = useState<undefined | null | string>(null)

  const auth = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  const state = location.state as { from: Location }
  const from = state ? state.from.pathname + state.from.search : '/dashboard/'


  useEffect(() => {
    if (navigating) {
      // console.log("Auto login check cancelled: Already navigating")
      return
    }
    if (auth.user.firstName === lastUsername) {
      // console.log("Auto login check cancelled: Same username")
      return
    }
    setLastUsername(auth.user.firstName)

    if (auth.user.firstName) {
      // console.log('User was already set locally')
      // console.log('Auto nagivating to: ', from)
      setNavigating(true)
      navigate(from);
    }
    else {
      // console.log('User is not set locally. Checking with the server')
      setNavigating(true)
      auth.UserCheckAuth()
        .then((response) => {
          if (response) {
            // console.log(response)
            // console.log('User is already authorized to the server')
            // console.log('Auto nagivating to: ', from)
            navigate(from)
          }
          else {
            // console.log('User is not authorized to the server')
            setNavigating(false)
          }
        })
    }
  }, [auth, navigate, from, navigating, lastUsername])

  return (
    <div className="parent flex flex-row items-center justify-center w-screen h-screen bg-white">
      <Background />
      <div className="flex items-center justify-center h-5/6 md:h-4/6 w-full md:max-w-md">
        <Outlet />
      </div>
    </div>
  )
}
