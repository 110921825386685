import { WhiteLoadingRing } from "../../../../components/Loading"
import { InviteProps, useAuth } from "../../../../contexts/UserContext"
import { useWarnings } from "../../../../contexts/WarningContext"
import { UserFunctionsProps } from "../../../../frames/UserCard/CardFunctions"
import { FormEvent, useState } from "react"
import { useTranslation } from "react-i18next"
import { IoCheckmark, IoClose, IoMail, IoSearch, IoTime } from "react-icons/io5"
import api from "../../../../services/api"

export const InviteProfessional = ({ gameUserIndex, ...props }: UserFunctionsProps) => {
  const { getGameUsers, getInvites, invites, gameUsers } = useAuth()

  const [showEmail, setShowEmail] = useState<boolean>(false)
  const [inviteStatus, setInviteStatus] = useState<'start' | 'finished' | 'loading' | 'error'>('start')

  const [email, setEmail] = useState('')

  const selectedGameUser = gameUsers[gameUserIndex]

  const user_invites = invites.filter(invite => invite.child_name === selectedGameUser?.name)

  const { t } = useTranslation()

  const handleInvite = (e: FormEvent) => {
    e.preventDefault()
    if (selectedGameUser === undefined || inviteStatus === 'loading' || !email) return;
    if (inviteStatus === 'finished') {
      props.onClose !== undefined && props.onClose()
      setInviteStatus('start')
      setEmail('')
      setShowEmail(false)
      return
    };

    setInviteStatus('loading')
    api.post('/api/user/invite', {
      professional_email: email,
      child_id: selectedGameUser?.id
    }).then(
      response => {
        setInviteStatus('finished')
        getGameUsers()
        getInvites()

      },
      error => {
        setInviteStatus('error')
      }
    )
  }

  if (selectedGameUser === undefined) return <>Loading...</>;

  return (
    <div onClick={e => e.stopPropagation()} className="flex flex-col items-center w-96 min-h-96 overflow-y-auto">

      {
        user_invites.length > 0 && (
          <div className="flex items-center flex-col w-full h-auto px-8">
            <h1 className="font-bold text-lg p-4">{t('inviteModal.invites')} </h1>
            {
              user_invites.map(invite => (
                <InviteCard key={invite.id} invite={invite} />
              ))
            }
          </div>
        )
      }

      <h1 className="font-bold text-lg p-4">{t('userActionModal.title')} </h1>

      <a href='/dashboard/professionals/' className="flex items-center justify-between shadow-lg hover:shadow-2xl rounded-lg w-5/6 h-16 bg-white p-4 my-2 group transition-shadow flex-grow">
        <p className="font-semibold text-gray-700">{t('userActionModal.searchProfessional')} </p>
        <IoSearch className="text-teal-300 border-teal-300 h-10 w-10 p-2 rounded-xl group-hover:bg-teal-300 group-hover:border-white group-hover:text-white transition" />
      </a>
      {/* Professional invite by email button */}

      <button onClick={() => { setShowEmail(!showEmail) }} className="flex items-center justify-between shadow-lg hover:shadow-2xl rounded-lg w-5/6 h-16 bg-white p-4 my-2 group transition-shadow flex-grow">
        {
          showEmail ? (
            <form className="flex items-center w-full h-full gap-2" onClick={e => e.stopPropagation()} onSubmit={handleInvite}>
              <div className="flex flex-col w-full h-full py-1">
                <input
                  className={`h-full bg-gray-lightest border-gray-700 p-1 rounded-xl shadow-inner ${inviteStatus === 'loading' && 'cursor-wait'}`}
                  placeholder="email@email.com"
                  name="email"
                  type="email"
                  value={email}
                  onChange={e => { inviteStatus !== 'loading' && setEmail(e.target.value) }}
                  disabled={inviteStatus !== 'start'}

                />
              </div>
              <button className="flex items-center justify-center h-10 w-10 shrink-0" type="submit" onClick={e => e.stopPropagation()}>
                {
                  inviteStatus === "loading" ? (
                    <div className="flex w-full h-full p-2 bg-teal-300 rounded-xl">
                      <WhiteLoadingRing />
                    </div>
                  ) : inviteStatus === 'finished' ? (
                    <IoCheckmark className="text-teal-300 border-teal-300 h-full w-full p-2 rounded-xl hover:bg-teal-300 hover:border-white hover:text-white transition" />
                  ) :
                    (
                      <IoMail className="text-teal-300 border-teal-300 h-full w-full p-2 rounded-xl hover:bg-teal-300 hover:border-white hover:text-white transition" />
                    )
                }
              </button>
            </form>
          ) : (
            <>
              <p className="font-semibold text-gray-700">{t('userActionModal.emailInvite')} </p>
              <IoMail className="text-teal-300 border-teal-300 h-10 w-10 p-2 rounded-xl group-hover:bg-teal-300 group-hover:border-white group-hover:text-white transition" />
            </>
          )
        }
      </button>

    </div >
  )
}


const InviteCard = ({ invite }: { invite: InviteProps }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { user, getInvites } = useAuth()
  const { t } = useTranslation()
  const { setWarning } = useWarnings()


  const handleCancelInvite = (invite_id: string) => {
    setIsLoading(true)
    api.delete(`/api/user/invite/${invite_id}`).then(
      response => {
        setWarning({ warning: t('inviteModal.inviteDeleted'), type: "success" })
      },
      error => {
        setWarning({ warning: t('inviteModal.failedToDelete'), type: "error" })
      }
    ).finally(() => {
      getInvites().finally(() => { setIsLoading(false) })
    })
  }

  return (
    <div key={invite.id} className="flex w-full min-h-24 items-center bg-white my-2 p-2 rounded-xl shadow-md hover:shadow-lg transition-all">
      <div className="flex justify-center flex-col h-full w-3/5">
        <p className="font-semibold ">
          {user.type === 'parent' ? (
            invite.professional_name.length > 0 ? (invite.professional_name) : (invite.professional_email)
          ) : (
            invite.parent_name
          )}
        </p>

        <p className=" text-sm text-gray-500">
          {user.type === 'parent' ? (
            invite.professional_email
          ) : (
            invite.parent_email
          )}
        </p>
        <p className=" text-sm text-gray-500">
          <b>{t('inviteModal.user')}: </b> {invite.child_name}
        </p>
      </div>
      <div className="flex items-center justify-end h-full w-2/5">
        {
          isLoading ? (
            <>
              <div className="text-teal-300 border-teal-300 h-10 w-10 rounded-xl bg-teal-300 hover:border-white hover:text-white transition group relative">
                <WhiteLoadingRing />
              </div>
            </>
          ) : (
            <button onClick={() => { handleCancelInvite(invite.id) }} className="text-teal-300 border-teal-300 h-10 w-10 rounded-xl bg-teal-300 hover:border-white hover:text-white transition group relative">
              <IoTime className="top-0 left-0 absolute opacity-100 group-hover:pointer-events-none group-hover:opacity-0  text-white border-white h-full w-full p-2 rounded-xl group-hover:bg-teal-300 group-hover:border-teal-300 group-hover:text-teal-300s transition" />
              <IoClose className="top-0 left-0 absolute opacity-0 pointer-events-none group-hover:pointer-events-auto group-hover:opacity-100  text-teal-300 border-teal-300 h-full w-full p-2 rounded-xl group-hover:bg-red-600 group-hover:border-white group-hover:text-white transition" />
            </button>
          )
        }
      </div>
    </div>
  )

}
