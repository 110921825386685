import api from "axios"
import { PrimaryButton } from "components/Button"
import { DropdownInput } from "components/Dropdown"
import { TextArea, TextInput } from "components/Input"
import { useModal } from "contexts/ModalContext"
import { useWarnings } from "contexts/WarningContext"
import { ProfessionalUserData } from "pages/Dashboard/Profile"
import { ReactNode, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { IoBan, IoCheckmark, IoRefresh } from "react-icons/io5"

export interface APIValidation {
  status: "RESEND" | "DENIED" | "ACCEPTED"
  message: string
  admin: {
    id: string
    name: string
  }
}


interface ValidationFunctionInterface {
  user: ProfessionalUserData
  initialStatus?: "RESEND" | "DENIED" | "ACCEPTED"
  callback?: () => void
}

export function ValidationFunctions({ user, initialStatus, callback = () => { } }: ValidationFunctionInterface) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState("")
  const [status, setStatus] = useState(initialStatus)
  const { setWarning } = useWarnings()


  const handleUpdate = () => {
    setIsLoading(true)
    api.post(`/api/admin/user/${user.id}/status`, { status, message })
      .then(
        response => {
          setWarning({ warning: t(`profile.header.functions.changeUserStatus.${status}`), type: "success" })
        },
        error => {
          setWarning({ warning: t([`errors.${error.response.data.detail}`, 'errors.undefined']), type: "error" })

        }
      ).finally(() => {
        setIsLoading(false)
        callback()
      })
  }
  return (
    <form onSubmit={e => { e.preventDefault(); handleUpdate() }} className="w-96 py-4 px-4 text-gray-700">
      <h1 className="font-bold text-lg mb-2">{t("profile.header.functions.submitValidation.title")}</h1>
      <DropdownInput<"ACCEPTED" | "RESEND" | "DENIED">
        options={[
          { label: t("profile.header.functions.submitValidation.ACCEPTED"), value: "ACCEPTED", icon: <IoCheckmark className="w-full h-full text-teal-300" /> },
          { label: t("profile.header.functions.submitValidation.RESEND"), value: "RESEND", icon: <IoRefresh className="w-full h-full text-orange-500" /> },
          { label: t("profile.header.functions.submitValidation.DENIED"), value: "DENIED", icon: <IoBan className="w-full h-full text-red-600" /> }
        ]}
        selectedOption={status}
        setOption={setStatus}
        label={t("profile.header.functions.submitValidation.action")}
      />

      <TextArea label={t("profile.header.functions.submitValidation.message")} value={message} setValue={setMessage} maxLength={500} />
      <PrimaryButton label={t("profile.header.functions.submitValidation.submit")} type="submit" isLoading={isLoading} />
    </form>
  )

}


export function ValidationInfo({ validation }: { validation: APIValidation }) {
  const { t } = useTranslation()

  return (
    <div className="flex items-center flex-col w-96 text-gray-700 p-4">
      <h1 className="font-bold text-lg">{t(`professional.resend.validationInfo`)}</h1>
      <DropdownInput<"ACCEPTED" | "RESEND" | "DENIED">
        label={t("profile.header.functions.submitValidation.status")}
        options={[
          { label: t("profile.header.functions.submitValidation.ACCEPTED"), value: "ACCEPTED", icon: <IoCheckmark className="w-full h-full text-teal-300" /> },
          { label: t("profile.header.functions.submitValidation.RESEND"), value: "RESEND", icon: <IoRefresh className="w-full h-full text-orange-500" /> },
          { label: t("profile.header.functions.submitValidation.DENIED"), value: "DENIED", icon: <IoBan className="w-full h-full text-red-600" /> }
        ]}
        selectedOption={validation.status}
        setOption={() => { }}
        disabled
      />
      <TextArea
        value={validation.message}
        label={t("profile.header.functions.submitValidation.message")}
        placeholder={t("profile.header.functions.submitValidation.noMessage")}
        setValue={() => { }}
        disabled
      />
      <TextInput
        value={validation.admin.name}
        label={t("profile.header.functions.submitValidation.admin")}
        setValue={() => { }}
        disabled
      />
    </div>
  )
}


export function ValidationCard({ validation }: { validation: APIValidation }) {
  const { t } = useTranslation()
  const { setModal } = useModal()

  const icons: Record<"ACCEPTED" | "DENIED" | "RESEND", ReactNode> = useMemo(() => ({
    "ACCEPTED": <IoCheckmark className="w-full h-full text-teal-300" />,
    "DENIED": <IoBan className="w-full h-full text-red-600" />,
    "RESEND": <IoRefresh className="w-full h-full text-orange-500" />
  }), [])

  return (
    <div className="flex h-14 items-center text-gray-700 border-solid border-2 border-gray-300 rounded-xl cursor-pointer" onClick={e => { e.preventDefault(); setModal({ element: <ValidationInfo validation={validation} /> }) }}>
      <div className="w-14 h-14 p-4">
        {
          icons[validation.status]
        }
      </div>
      <div className="w-full">
        <p>{t(`profile.header.functions.changeUserStatus.${validation.status}`)}</p>
      </div>
    </div>
  )
}