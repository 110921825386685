import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCheckmark, IoClose, IoTime } from 'react-icons/io5'
import { WhiteLoadingRing } from '../../components/Loading'
import { InviteProps, useAuth } from '../../contexts/UserContext'
import api from '../../services/api'
import './InviteCard.sass'


interface ComponentProps {
  invite: InviteProps
}

export const ParentInvite = ({ invite, ...props }: ComponentProps) => {
  const { user, getInvites } = useAuth()
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)


  const handleCancelInvite = (invite_id: string) => {
    setIsLoading(true)
    api.delete(`/api/user/invite/${invite_id}`).then(
      response => {
        getInvites()
      },
      error => { }
    )
      .finally(
        () => {
          setIsLoading(false)
        }
      )
  }

  return (
    <div className="invite-container" {...props} >
      <div>
        <p className="font-semibold text-gray-700 ">
          {user.type === 'parent' ? (
            invite.professional_name.length > 0 ? (invite.professional_name) : (invite.professional_email)
          ) : (
            invite.parent_name
          )}
        </p>

        <p className=" text-sm text-gray-500">
          {user.type === 'parent' ? (
            invite.professional_email
          ) : (
            invite.parent_email
          )}
        </p>
        <p className=" text-sm text-gray-500">
          <b>{t('inviteModal.user')}: </b> {invite.child_name}
        </p>
      </div>
      <div>
        {
          isLoading ? (
            <button disabled>
              <WhiteLoadingRing />
            </button>
          ) : (
            <button onClick={() => { handleCancelInvite(invite.id) }} >
              <IoTime className="icon-time" />
              <IoClose className="icon-close" />
            </button>
          )
        }

      </div>

    </div>
  )
}

export const ProfessionalInvite = ({ invite }: ComponentProps) => {
  const { user, getInvites, getGameUsers } = useAuth()
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)

  const handleAcceptInvite = (invite_id: string) => {
    setIsLoading(true)
    api.put(`/api/user/invite/${invite_id}`).then(
      response => {
        getInvites()
        getGameUsers()
      },
      error => {

      }
    )
      .finally(
        () => {
          setIsLoading(false)
        }
      )
  }

  return (
    <div key={invite.id} className="invite-container">
      <div>
        <p className="font-semibold text-gray-700 ">
          {invite.child_name}
        </p>

        <p className="text-sm text-gray-500">
          {user.type === 'parent' ? (
            invite.professional_email
          ) : (
            invite.parent_email
          )}
        </p>
        <p className="text-sm text-gray-500">
          <b>{t('inviteModal.parent')}: </b> {invite.parent_name}
        </p>
      </div>
      <div>
        <button onClick={() => { handleAcceptInvite(invite.id) }} className="hover:ring-1 hover:ring-purple-600">
          {
            isLoading ? (
              <WhiteLoadingRing />
            ) : (
              <IoCheckmark className="icon-check" />
            )

          }
        </button>
      </div>
    </div>
  )
}