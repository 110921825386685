import _ from "lodash"

import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useParams } from 'react-router-dom'
import { useImmer } from 'use-immer'
import { TextArea, TextInput } from '../../../../components/Input'
import { WhiteLoadingRing } from '../../../../components/Loading'
import { GameUserProps, useAuth } from '../../../../contexts/UserContext'
import api from '../../../../services/api'
import './GameProfileSettings.sass'

export const GameProfileSettings = () => {
  const { gameUsers, getGameUsers } = useAuth()
  const gameUserIndex = parseInt(useParams().index ?? "0")
  const { t } = useTranslation()

  const baseGameUser = useMemo(() => {
    return gameUserIndex in gameUsers ? gameUsers[gameUserIndex] : (undefined)
  }, [gameUserIndex, gameUsers])
  const [currentGameUser, setCurrentGameUser] = useImmer<GameUserProps>(baseGameUser ? { ...baseGameUser } : {} as GameUserProps)
  const hasChanged = useMemo(() => !_.isEqual(currentGameUser, baseGameUser), [currentGameUser, baseGameUser])

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const resetData = () => {
    if (baseGameUser) {
      setCurrentGameUser(baseGameUser)
    }
  }

  const handleSubmit = () => {
    setIsLoading(true)
    api.put(`/api/user/game_user/${gameUsers[gameUserIndex].id}`, {
      "name": currentGameUser.name,
      "sex": currentGameUser.gender,
      "observations": currentGameUser.observations,
      "skin_tone": currentGameUser.skin_tone
    }).then(
      response => {
        setIsLoading(true)
        getGameUsers().finally(() => setIsLoading(false))

      },
      error => {
        setIsLoading(false)
      }
    )
  }

  if (baseGameUser === undefined) {
    return <Navigate to="/dashboard/game_user" />
  }

  return (
    <div className="personal-settings">
      <div className="data">
        <h3>{t('gameUserProfile.settings.profileSettings.profile.title')}</h3>
        <TextInput
          label={t('gameUserProfile.settings.profileSettings.profile.name')}
          value={currentGameUser.name}
          setValue={(value) => { setCurrentGameUser(gameUser => { gameUser.name = value }) }}
        />
        <TextArea value={currentGameUser.observations} setValue={(value) => { setCurrentGameUser(gameUser => { gameUser.observations = value }) }} label={`${t('gameUserRegistration.observation')}:`} />

        <div className={`buttons transition-all opacity-0 ${hasChanged ? 'animate-slide-in opacity-100' : 'animate-slide-out'}`}>
          <button type="button" onClick={resetData}>
            {t('profile.cards.settings.personalSettings.changes.discard')}
          </button>
          <button type="button" onClick={handleSubmit}>
            {isLoading ? (
              <div className="h-10 w-10">
                <WhiteLoadingRing />
              </div>
            ) : t('profile.cards.settings.personalSettings.changes.save')}
          </button>
        </div>
      </div>
    </div>
  )
}