import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { SwitchButton } from "../../../../../components/Button"
import { useAuth } from "../../../../../contexts/UserContext"
import { useWarnings } from "../../../../../contexts/WarningContext"
import api from "../../../../../services/api"
import "./GameTimeSettings.sass"

export const GameTimeSettings = () => {
  const { t } = useTranslation()
  const { setWarning } = useWarnings()

  const gameUserIndex = (useParams().index)
  const { gameUsers, getGameUsers } = useAuth()
  const [time, setTime] = useState<string | null>(null)

  const getCurrentGameTime = useCallback(() => {
    if (gameUserIndex && (parseInt(gameUserIndex) < gameUsers.length)) {
      const gameUser = gameUsers[parseInt(gameUserIndex)]
      return gameUser.data.max_game_time
    }
    return null;
  }, [gameUserIndex, gameUsers])

  const hasChanged: boolean = useMemo(() => {
    if (gameUserIndex === undefined || time === undefined) {
      return false;
    } else {
      const index = parseInt(gameUserIndex)
      if (index >= gameUsers.length) return false
      const data = gameUsers[index].data.max_game_time
      return time !== getClockFormatted(data)
    }


  }, [gameUserIndex, time, gameUsers])


  useEffect(() => {
    const currentGameTime = getCurrentGameTime()
    if (currentGameTime === null) {
      setTime(null)
      return
    }

    setTime(getClockFormatted(currentGameTime))
  }, [getCurrentGameTime])


  function getClockFormatted(minutes: number | null) {
    if (minutes === null) return null

    const hour = Math.floor(minutes / 60)
    const minute = minutes % 60

    if (isNaN(hour) || isNaN(minute)) {
      return null
    }

    return `${`${hour}`.padStart(2, '0')}:${`${minute}`.padStart(2, '0')}`
  }

  const submitChanges = useCallback(() => {
    if (!hasChanged) return;
    if ((time === null) && gameUserIndex) {
      api.post(`/api/user/game_user/${gameUsers[parseInt(gameUserIndex)].id}/game_time`, { game_time: null }).then(
        response => {

        },
        error => {
          setWarning({ type: "error", warning: t("errors.undefined") })
        }).finally(() => { getGameUsers() })
      return;
    }


    if (time === undefined || time === "--:--") {
      setWarning({ warning: t('errors.NO_TIME'), type: "warning" })
      return;
    }

    const minutes = time !== null ? (parseInt(time.slice(0, 2)) * 60 + parseInt(time.slice(3))) : null

    if (gameUserIndex && (parseInt(gameUserIndex) < gameUsers.length)) {

      api.post(`/api/user/game_user/${gameUsers[parseInt(gameUserIndex)].id}/game_time`, { game_time: minutes }).then(
        response => { },
        error => {
          setWarning({ type: "error", warning: t("errors.undefined") })
        }).finally(() => { getGameUsers() })

    }
  }, [gameUserIndex, getGameUsers, gameUsers, setWarning, t, time, hasChanged])

  useEffect(() => {
    let submit: null | (() => void) = submitChanges
    let timeout = setTimeout(() => {
      if (submit !== null) submit();
    }, 500)

    return () => {
      submit = null
      clearTimeout(timeout)
    }
  }, [time, submitChanges])


  return (
    <div className="game-settings time-settings">
      <h1>{t('gameUserProfile.settings.gameTime.title')}</h1>

      <div className="settings-section game-areas">
        <ul className="options">
          <li className="option">
            <p>{t('gameUserProfile.settings.gameTime.timeLimit')}</p>
            <SwitchButton state={time !== null} onClick={e => { e.stopPropagation(); time !== null ? (setTime(null)) : (setTime(getClockFormatted(getCurrentGameTime() ?? 0))) }} />
          </li>
          <li className="option">
            {
              time !== null && (
                <>
                  <input
                    type="text"

                    value={time?.slice(0, 2)}
                    inputMode="numeric"
                    onFocus={e => e.target.select()}
                    onChange={
                      e => {
                        if (parseInt(e.target.value) < 24) {
                          setTime(time => {
                            const lastTwoNumbers = time ? time.slice(3, 5) : "00"
                            const inputValue = e.target.value.length >= 3 ? e.target.value.slice(1, 3) : e.target.value.replaceAll("0", "")
                            return `${inputValue.padStart(2, "0")}:${lastTwoNumbers}`
                          })
                        }
                      }}
                  />
                  :
                  <input
                    type="text"
                    value={time?.slice(3)}
                    inputMode="numeric"
                    onFocus={e => e.target.select()}
                    onChange={
                      e => {
                        if (parseInt(e.target.value) < 60) {
                          setTime(time => {
                            const firstTwoNumbers = time ? time.slice(0, 2) : "00"
                            const inputValue = e.target.value.length >= 3 ? e.target.value.slice(1, 3) : e.target.value.replaceAll("0", "")
                            return `${firstTwoNumbers}:${inputValue.padStart(2, "0")}`
                          })
                        }
                      }}
                  />
                </>
              )
            }
          </li>
        </ul>
      </div>
    </div>
  )
}
