import "./VerifyGiftCode.sass"
import { Background } from "../../components/Background"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import api from "../../services/api"
import { useWarnings } from "../../contexts/WarningContext"
import { LoadingDots } from "../../components/Loading"
import { IoCheckmarkOutline, IoClose } from "react-icons/io5"
import { useTranslation } from "react-i18next"
import { useAuth } from "../../contexts/UserContext"

export const VerifyGiftCode = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { UserCheckAuth } = useAuth()
  const { t } = useTranslation()
  const { setWarning } = useWarnings()
  const [isLoading, setIsLoading] = useState(true)
  const [wasSuccessful, setWasSuccessful] = useState(false)

  useEffect(() => {
    api.put(`/api/user/gift_code/${params.token}`).then(
      response => {
        setWarning({
          warning: t('premium.warning.0'),
          type: "success"
        })
        setWasSuccessful(true)
        setTimeout(() => {
          navigate("/dashboard")
        }, 5000)
      },
      error => {
        setWarning({
          warning: t([`errors.${error.response.data.detail}`, 'errors.undefined']),
          type: "error"
        })
        setWasSuccessful(false)
        setTimeout(() => {
          navigate("/dashboard")
        }, 5000)
      }
    )
      .finally(() => {
        UserCheckAuth()
        setIsLoading(false)
      })
    // eslint-disable-next-line
  }, [params.token])


  return (
    <div className="gift-code">
      <Background />
      <div className="content">
        {
          isLoading && (
            <div className="loading">
              <LoadingDots />
            </div>
          )
        }
        {
          !isLoading && (
            wasSuccessful ? (
              <div className="success">
                <IoCheckmarkOutline className="icon" />
                <p>{t('premium.success')}</p>
                <p>{t('premium.redirect.0')}<Link to="/dashboard">{t('premium.redirect.1')}</Link></p>
              </div>
            ) : (
              <div className="failed">
                <IoClose className="icon" />
                <p>{t('premium.error')}.</p>
                <p>{t('premium.redirect.0')}<Link to="/dashboard">{t('premium.redirect.1')}</Link></p>
              </div>
            )
          )
        }
      </div>
    </div>
  )
}